// State to manage create/edit deals and events

import { decorate, observable, toJS } from 'mobx';
import { addDays, subDays, compareAsc, startOfWeek } from 'date-fns';
import { ID } from '../api/tokens';
import { CREATE_ITEM } from '../api/api';

class CreateState {
  showProgress = false;
  progress = 0;

  loading = false;
  _edit = '';
  edits = [];

  locationSelection = '-1';
  setLocation = async (loc) => {
    this.locationSelection = loc;
  }

  mainObject = {
    auth: '',
    which: '',
    area: '',
    placeID: '',
    placeName: '',
    day: '',
    month: '',
    year: '',
    layout: '',
    type: '',
    title: '',
    hours: '',
    h: '',
    h1: '',
    h2: '',
    details: '',
    details1: '',
    details2: '',
    link: '0',
    start: {
      hour: 0,
      min: 0
    },
    end: {
      hour: 0,
      min: 0
    },
    back: '',
    featured: 0,
    recurring: 1,
    recur: '',
    lat: 0,
    long: 0,
    barcode: '',
    url: '0',
    dealsDate: new Date('2019', '07', '28'),
    barcodes: []
  }

  setupNew = async (which, user) => {
    //console.log(which);
    if (this.locationSelection === '-1') {
      this.mainObject.area = user.locations;
    } else {
      this.mainObject.area = user.locations[this.locationSelection].area;
      this.mainObject.lat = user.locations[this.locationSelection].latitude;
      this.mainObject.long = user.locations[this.locationSelection].longitude;
      this.mainObject.barcodes = user.locations[this.locationSelection].barcodes;
    }
    this.mainObject.auth = await ID();
    this.mainObject.which = which;
    this.mainObject.placeID = user.placeID;
    this.mainObject.placeName = user[which];
    this.mainObject.day = '';
    this.mainObject.month = '';
    this.mainObject.year = '';
    this.mainObject.layout = '';
    this.mainObject.type = '';
    this.mainObject.title = '';
    this.mainObject.hours = '';
    this.mainObject.h = '';
    this.mainObject.h1 = '';
    this.mainObject.h2 = '';
    this.mainObject.details = '';
    this.mainObject.details1 = '';
    this.mainObject.details2 = '';
    this.mainObject.link = '0';
    this.mainObject.start.hour = 0;
    this.mainObject.start.min = 0;
    this.mainObject.end.hour = 0;
    this.mainObject.end.min = 0;
    this.mainObject.back = '';
    this.mainObject.featured = 0;
    this.mainObject.recurring = 1;
    this.mainObject.recur = '';
    this.mainObject.barcode = '';
    this.mainObject.url = '0';
    this.setScreen('back');
  }

  setupEdit = async (obj, user, num, flag) => {
    // Flag is a boolean, true means this is called to edit, false means it is called to delete
    this.mainObject.auth = await ID();
    this.mainObject.which = obj.which;
    this.mainObject.area = obj.area;
    this.mainObject.placeID = obj.placeID;
    this.mainObject.placeName = obj.placeName;
    this.mainObject.day = obj.day;
    this.mainObject.month = obj.month;
    this.mainObject.year = obj.year;
    this.mainObject.layout = obj.layout;
    this.mainObject.type = obj.type;
    this.mainObject.title = obj.title;
    this.mainObject.link = obj.link;
    this.mainObject.back = obj.back;
    this.mainObject.featured = obj.featured;
    this.mainObject.lat = obj.lat;
    this.mainObject.long = obj.long;
    this.mainObject.recurring = obj.recurring;

    if (obj.hasOwnProperty('recur')) {
      this.mainObject.recur = obj.recur;
    }

    switch (obj.layout) {
      case 'single': {
        this.mainObject.h = obj.h;
        this.mainObject.details = obj.details;
        break;
      }
      case 'double': {
        this.mainObject.h1 = obj.h1;
        this.mainObject.h2 = obj.h2;
        this.mainObject.details1 = obj.details1;
        this.mainObject.details2 = obj.details2;
        break;
      }
      case 'barcode': {
        this.mainObject.h = obj.h;
        this.mainObject.details = obj.details;
        this.mainObject.barcode = obj.barcode;
        this.mainObject.url = obj.url;
        break;
      }
    }

    if (obj.hasOwnProperty('start')) {
      this.mainObject.hours = obj.hours;
      this.mainObject.start.hour = obj.start.hour;
      this.mainObject.start.min = obj.start.min;
      this.mainObject.end.hour = obj.end.hour;
      this.mainObject.end.min = obj.end.min;
    } else {
      this.mainObject.hours = '';
      this.mainObject.start.hour = '';
      this.mainObject.start.min = '';
      this.mainObject.end.hour = '';
      this.mainObject.end.min = '';
    }

    if (flag) {
      this.setScreen('edit');
      this._edit = num;
    }
  }

  setAttribute = async (attr, val) => {
    if (attr === 'start') {
      this.mainObject.start.hour = val.hour;
      this.mainObject.start.min = val.min;
    } else if (attr === 'end') {
      this.mainObject.end.hour = val.hour;
      this.mainObject.end.min = val.min;
    } else {
      this.mainObject[attr] = val;
    }
  }

  setRecurring = async (action) => {
    this.progress = 0;
    this.loading = true;
    var _start = new Date(this.mainObject.year, this.mainObject.month - 1, this.mainObject.day);
    switch (this.mainObject.recur) {
      case 'Once A Week': {
        //console.log('once');
        switch (action) {
          case 'create': {
            await this.createItem(0, 1);
            break;
          }
          case 'edit': {
            await this.editItem(1);
            break;
          }
          case 'delete': {
            await this.deleteItem(1);
            break;
          }
        }
        break;
      }
      case 'Mon - Fri': {
        //console.log('monfri');
        this.showProgress = true;
        let days = [];
        let weekStart = startOfWeek(_start, {weekStartsOn: 1});
        for (let i = 0; i < 5; i++) {
          let _day = weekStart.getDate();
          let _month = weekStart.getMonth() + 1;
          let _year = weekStart.getFullYear();
          let temp = {
            day: _day.toString(),
            month: _month.toString(),
            year: _year.toString()
          }
          days.push(temp);

          weekStart = addDays(weekStart, 1);
        }

        // send create function for EACH in days HERE
        switch (action) {
          case 'create': {
            for (let j = 0; j < days.length; j++) {
              this.progress = Math.floor((j / 5) * 100);
              this.setAttribute('day', days[j].day);
              this.setAttribute('month', days[j].month);
              this.setAttribute('year', days[j].year);
              await this.createItem((j/5), ((j+1)/5));
            }
            break;
          }
          case 'edit': {
            for (let j = 0; j < days.length; j++) {
              this.progress = Math.floor((j / 5) * 100);
              this.setAttribute('day', days[j].day);
              this.setAttribute('month', days[j].month);
              this.setAttribute('year', days[j].year);
              await this.editItem(1);
            }
            break;
          }
          case 'delete': {
            for (let j = 0; j < days.length; j++) {
              this.progress = Math.floor((j / 5) * 100);
              this.setAttribute('day', days[j].day);
              this.setAttribute('month', days[j].month);
              this.setAttribute('year', days[j].year);
              await this.deleteItem(1);
            }
            break;
          }
        }
        break;
      }
      case 'Mon - Thurs': {
        //console.log('monthurs');
        this.showProgress = true;
        let days = [];
        let weekStart = startOfWeek(_start, {weekStartsOn: 1});
        for (let i = 0; i < 4; i++) {
          let _day = weekStart.getDate();
          let _month = weekStart.getMonth() + 1;
          let _year = weekStart.getFullYear();
          let temp = {
            day: _day.toString(),
            month: _month.toString(),
            year: _year.toString()
          }
          days.push(temp);

          weekStart = addDays(weekStart, 1);
        }

        // send create function for EACH in days HERE
        switch (action) {
          case 'create': {
            for (let j = 0; j < days.length; j++) {
              this.progress = Math.floor((j / 4) * 100);
              this.setAttribute('day', days[j].day);
              this.setAttribute('month', days[j].month);
              this.setAttribute('year', days[j].year);
              await this.createItem((j/4), ((j+1)/4));
            }
            break;
          }
          case 'edit': {
            for (let j = 0; j < days.length; j++) {
              this.progress = Math.floor((j / 4) * 100);
              this.setAttribute('day', days[j].day);
              this.setAttribute('month', days[j].month);
              this.setAttribute('year', days[j].year);
              await this.editItem(1);
            }
            break;
          }
          case 'delete': {
            for (let j = 0; j < days.length; j++) {
              this.progress = Math.floor((j / 4) * 100);
              this.setAttribute('day', days[j].day);
              this.setAttribute('month', days[j].month);
              this.setAttribute('year', days[j].year);
              await this.deleteItem(1);
            }
            break;
          }
        }
        break;
      }
      case 'Fri & Sat': {
        //console.log('frisat');
        this.showProgress = true;
        if (_start.getDay() === 5) {
          var otherDate = addDays(_start, 1);
        } else if (_start.getDay() === 6) {
          var otherDate = subDays(_start, 1);
        } else {
          // error must be on fri or sat --------
          //console.log('error');
          break;
        }

        let otherDay = otherDate.getDate().toString();
        let otherMonth = (otherDate.getMonth() + 1).toString();
        let otherYear = otherDate.getFullYear().toString();

        // send create function for current and otherDate HERE
        switch (action) {
          case 'create': {
            await this.createItem(0, 50);
            this.progress = 50;
            this.setAttribute('day', otherDay);
            this.setAttribute('month', otherMonth);
            this.setAttribute('year', otherYear);
            await this.createItem(.5, 1);
            this.progress = 100;
            break;
          }
          case 'edit': {
            this.progress = 50;
            await this.editItem(1);
            this.setAttribute('day', otherDay);
            this.setAttribute('month', otherMonth);
            this.setAttribute('year', otherYear);
            this.progress = 100;
            await this.editItem(1);
            break;
          }
          case 'delete': {
            this.progress = 50;
            await this.deleteItem(1);
            this.setAttribute('day', otherDay);
            this.setAttribute('month', otherMonth);
            this.setAttribute('year', otherYear);
            this.progress = 100;
            await this.deleteItem(1);
            break;
          }
        }
        break;
      }
      case 'Sat & Sun': {
        //console.log('satsun');
        this.showProgress = true;
        if (_start.getDay() === 6) {
          var otherDate = addDays(_start, 1);
        } else if (_start.getDay() === 0) {
          var otherDate = subDays(_start, 1);
        } else {
          // error must be on sat or sun --------
          //console.log('error');
          break;
        }

        let otherDay = otherDate.getDate().toString();
        let otherMonth = (otherDate.getMonth() + 1).toString();
        let otherYear = otherDate.getFullYear().toString();

        // send create function for current and otherDate HERE
        switch (action) {
          case 'create': {
            await this.createItem(0, 50);
            this.progress = 50;
            this.setAttribute('day', otherDay);
            this.setAttribute('month', otherMonth);
            this.setAttribute('year', otherYear);
            await this.createItem(.5, 1);
            this.progress = 100;
            break;
          }
          case 'edit': {
            this.progress = 50;
            await this.editItem(1);
            this.setAttribute('day', otherDay);
            this.setAttribute('month', otherMonth);
            this.setAttribute('year', otherYear);
            this.progress = 100;
            await this.editItem(1);
            break;
          }
          case 'delete': {
            this.progress = 50;
            await this.deleteItem(1);
            this.setAttribute('day', otherDay);
            this.setAttribute('month', otherMonth);
            this.setAttribute('year', otherYear);
            this.progress = 100;
            await this.deleteItem(1);
            break;
          }
        }
        break;
      }
      case 'Every Day': {
        //console.log('every');
        this.showProgress = true;
        let days = [];
        let weekStart = startOfWeek(_start);
        for (let i = 0; i < 7; i++) {
          let _day = weekStart.getDate();
          let _month = weekStart.getMonth() + 1;
          let _year = weekStart.getFullYear();
          let temp = {
            day: _day.toString(),
            month: _month.toString(),
            year: _year.toString()
          }
          days.push(temp);

          weekStart = addDays(weekStart, 1);
        }

        // send create function for EACH in days HERE
        switch (action) {
          case 'create': {
            for (let j = 0; j < days.length; j++) {
              this.progress = Math.floor((j / 7) * 100);
              this.setAttribute('day', days[j].day);
              this.setAttribute('month', days[j].month);
              this.setAttribute('year', days[j].year);
              await this.createItem((j/7), ((j+1)/7));
            }
            break;
          }
          case 'edit': {
            for (let j = 0; j < days.length; j++) {
              this.progress = Math.floor((j / 7) * 100);
              this.setAttribute('day', days[j].day);
              this.setAttribute('month', days[j].month);
              this.setAttribute('year', days[j].year);
              await this.editItem(1);
            }
            break;
          }
          case 'delete': {
            for (let j = 0; j < days.length; j++) {
              this.progress = Math.floor((j / 7) * 100);
              this.setAttribute('day', days[j].day);
              this.setAttribute('month', days[j].month);
              this.setAttribute('year', days[j].year);
              await this.deleteItem(1);
            }
            break;
          }
        }
        break;
      }
      default: {
        //console.log('not found');
        break;
      }
    }
    this.showProgress = false;
    this.loading = false;
  }

  compare = (start, end) => {
    return (compareAsc(start, end) !== 1);
  }

  setEdits = (_edits) => {
    this.edits = _edits;
  }

  deleteItem = async (flag) => {
    var init
    if (flag) {
      init = {
        headers: {
          'Authorization': this.mainObject.auth
        },
        body: {
          "action": "delete",
          "area": this.mainObject.area,
          "month": this.mainObject.month,
          "day": this.mainObject.day,
          "year": this.mainObject.year,
          "which": this.mainObject.which,
          "placeID": this.mainObject.placeID,
          "type": this.mainObject.type.slice(),
          "title": this.mainObject.title,
          "layout": this.mainObject.layout,
          "recurring": this.mainObject.recurring,
          "featured": this.mainObject.featured
        }
      }
    } else {
      init = {
        headers: {
          'Authorization': this.mainObject.auth
        },
        body: {
          "action": "delete",
          "area": this.mainObject.area,
          "month": this.mainObject.month,
          "day": this.mainObject.day,
          "year": this.mainObject.year,
          "which": this.mainObject.which,
          "placeID": this.mainObject.placeID,
          "type": this.mainObject.type.slice(),
          "title": this.mainObject.title,
          "layout": this.mainObject.layout,
          "recurring": 1,
          "featured": this.mainObject.featured
        }
      }
    }
    await CREATE_ITEM(init);
  }

  editItem = async (flag) => {
    var init;
    if (flag) {
      init = {
        headers: {
          'Authorization': this.mainObject.auth
        },
        body: {
          "action": "edit",
          "area": this.mainObject.area,
          "month": this.mainObject.month,
          "day": this.mainObject.day,
          "year": this.mainObject.year,
          "which": this.mainObject.which,
          "placeID": this.mainObject.placeID,
          "type": this.mainObject.type.slice(),
          "title": this.mainObject.title,
          "layout": this.mainObject.layout,
          "recurring": this.mainObject.recurring,
          "featured": this.mainObject.featured,
          "edit": this.edits
        }
      }
    } else {
      init = {
        headers: {
          'Authorization': this.mainObject.auth
        },
        body: {
          "action": "edit",
          "area": this.mainObject.area,
          "month": this.mainObject.month,
          "day": this.mainObject.day,
          "year": this.mainObject.year,
          "which": this.mainObject.which,
          "placeID": this.mainObject.placeID,
          "type": this.mainObject.type.slice(),
          "title": this.mainObject.title,
          "layout": this.mainObject.layout,
          "recurring": 1,
          "featured": this.mainObject.featured,
          "edit": this.edits
        }
      }
    }
    await CREATE_ITEM(init);
  }

  createItem = async (progStart, progEnd) => {
    switch (this.mainObject.layout) {
      case 'single': {
        if (typeof(this.mainObject.area) === 'object') {
          var diff = Math.floor(((progEnd - progStart) / this.mainObject.area.length) * 100);
          for (var i = 0; i < this.mainObject.area.length; i++) {
            var init = {
              headers: {
                'Authorization': this.mainObject.auth
              },
              body: {
                "action": "create",
                "area": this.mainObject.area[i].area,
                "month": this.mainObject.month,
                "day": this.mainObject.day,
                "year": this.mainObject.year,
                "which": this.mainObject.which,
                "placeName": this.mainObject.placeName,
                "placeID": this.mainObject.placeID,
                "type": this.mainObject.type,
                "title": this.mainObject.title,
                "hours": this.mainObject.hours,
                "h": this.mainObject.h,
                "details": this.mainObject.details,
                "layout": this.mainObject.layout,
                "back": this.mainObject.back,
                "recurring": this.mainObject.recurring,
                "recur": this.mainObject.recur,
                "featured": this.mainObject.featured,
                "link": this.mainObject.link,
                "start": {
                  "hour": toJS(this.mainObject.start).hour,
                  "min": toJS(this.mainObject.start).min
                },
                "end": {
                  "hour": toJS(this.mainObject.end).hour,
                  "min": toJS(this.mainObject.end).min
                },
                "lat": this.mainObject.area[i].latitude,
                "long": this.mainObject.area[i].longitude
              }
            }
            //console.log(init);
            await CREATE_ITEM(init);
            this.progress += diff;
          }
        } else {
          var init = {
            headers: {
              'Authorization': this.mainObject.auth
            },
            body: {
              "action": "create",
              "area": this.mainObject.area,
              "month": this.mainObject.month,
              "day": this.mainObject.day,
              "year": this.mainObject.year,
              "which": this.mainObject.which,
              "placeName": this.mainObject.placeName,
              "placeID": this.mainObject.placeID,
              "type": this.mainObject.type,
              "title": this.mainObject.title,
              "hours": this.mainObject.hours,
              "h": this.mainObject.h,
              "details": this.mainObject.details,
              "layout": this.mainObject.layout,
              "back": this.mainObject.back,
              "recurring": this.mainObject.recurring,
              "recur": this.mainObject.recur,
              "featured": this.mainObject.featured,
              "link": this.mainObject.link,
              "start": {
                "hour": toJS(this.mainObject.start).hour,
                "min": toJS(this.mainObject.start).min
              },
              "end": {
                "hour": toJS(this.mainObject.end).hour,
                "min": toJS(this.mainObject.end).min
              },
              "lat": this.mainObject.lat,
              "long": this.mainObject.long
            }
          }
          //console.log(init);
          await CREATE_ITEM(init);
        }
        break;
      }
      case 'double': {
        if (typeof(this.mainObject.area) === 'object') {
          var diff = Math.floor(((progEnd - progStart) / this.mainObject.area.length) * 100);
          for (var i = 0; i < this.mainObject.area.length; i++) {
            var init = {
              headers: {
                'Authorization': this.mainObject.auth
              },
              body: {
                "action": "create",
                "area": this.mainObject.area[i].area,
                "month": this.mainObject.month,
                "day": this.mainObject.day,
                "year": this.mainObject.year,
                "which": this.mainObject.which,
                "placeName": this.mainObject.placeName,
                "placeID": this.mainObject.placeID,
                "type": this.mainObject.type,
                "title": this.mainObject.title,
                "hours": this.mainObject.hours,
                "h1": this.mainObject.h1,
                "h2": this.mainObject.h2,
                "details1": this.mainObject.details1,
                "details2": this.mainObject.details2,
                "layout": this.mainObject.layout,
                "back": this.mainObject.back,
                "recurring": this.mainObject.recurring,
                "recur": this.mainObject.recur,
                "featured": this.mainObject.featured,
                "link": this.mainObject.link,
                "start": {
                  "hour": toJS(this.mainObject.start).hour,
                  "min": toJS(this.mainObject.start).min
                },
                "end": {
                  "hour": toJS(this.mainObject.end).hour,
                  "min": toJS(this.mainObject.end).min
                },
                "lat": this.mainObject.area[i].latitude,
                "long": this.mainObject.area[i].longitude
              }
            }
            //console.log(init);
            await CREATE_ITEM(init);
            this.progress += diff;
          }
        } else {
          var init = {
            headers: {
              'Authorization': this.mainObject.auth
            },
            body: {
              "action": "create",
              "area": this.mainObject.area,
              "month": this.mainObject.month,
              "day": this.mainObject.day,
              "year": this.mainObject.year,
              "which": this.mainObject.which,
              "placeName": this.mainObject.placeName,
              "placeID": this.mainObject.placeID,
              "type": this.mainObject.type,
              "title": this.mainObject.title,
              "hours": this.mainObject.hours,
              "h1": this.mainObject.h1,
              "h2": this.mainObject.h2,
              "details1": this.mainObject.details1,
              "details2": this.mainObject.details2,
              "layout": this.mainObject.layout,
              "back": this.mainObject.back,
              "recurring": this.mainObject.recurring,
              "recur": this.mainObject.recur,
              "featured": this.mainObject.featured,
              "link": this.mainObject.link,
              "start": {
                "hour": toJS(this.mainObject.start).hour,
                "min": toJS(this.mainObject.start).min
              },
              "end": {
                "hour": toJS(this.mainObject.end).hour,
                "min": toJS(this.mainObject.end).min
              },
              "lat": this.mainObject.lat,
              "long": this.mainObject.long
            }
          }
          //console.log(init);
          await CREATE_ITEM(init);
        }
        break;
      }
      case 'barcode': {
        if (typeof(this.mainObject.area) === 'object') {
          var diff = Math.floor(((progEnd - progStart) / this.mainObject.area.length) * 100);
          for (var i = 0; i < this.mainObject.area.length; i++) {
            var init = {
              headers: {
                'Authorization': this.mainObject.auth
              },
              body: {
                "action": "create",
                "area": this.mainObject.area[i].area,
                "month": this.mainObject.month,
                "day": this.mainObject.day,
                "year": this.mainObject.year,
                "which": this.mainObject.which,
                "placeName": this.mainObject.placeName,
                "placeID": this.mainObject.placeID,
                "type": this.mainObject.type,
                "title": this.mainObject.title,
                "hours": this.mainObject.hours,
                "h": this.mainObject.h,
                "details": this.mainObject.details,
                "layout": this.mainObject.layout,
                "back": this.mainObject.back,
                "recurring": this.mainObject.recurring,
                "recur": this.mainObject.recur,
                "featured": this.mainObject.featured,
                "link": this.mainObject.link,
                "start": {
                  "hour": toJS(this.mainObject.start).hour,
                  "min": toJS(this.mainObject.start).min
                },
                "end": {
                  "hour": toJS(this.mainObject.end).hour,
                  "min": toJS(this.mainObject.end).min
                },
                "lat": this.mainObject.area[i].latitude,
                "long": this.mainObject.area[i].longitude,
                "barcode": this.mainObject.barcode,
                "url": this.mainObject.url
              }
            }
            //console.log(init);
            await CREATE_ITEM(init);
            this.progress += diff;
          }
        } else {
          var init = {
            headers: {
              'Authorization': this.mainObject.auth
            },
            body: {
              "action": "create",
              "area": this.mainObject.area,
              "month": this.mainObject.month,
              "day": this.mainObject.day,
              "year": this.mainObject.year,
              "which": this.mainObject.which,
              "placeName": this.mainObject.placeName,
              "placeID": this.mainObject.placeID,
              "type": this.mainObject.type,
              "title": this.mainObject.title,
              "hours": this.mainObject.hours,
              "h": this.mainObject.h,
              "details": this.mainObject.details,
              "layout": this.mainObject.layout,
              "back": this.mainObject.back,
              "recurring": this.mainObject.recurring,
              "recur": this.mainObject.recur,
              "featured": this.mainObject.featured,
              "link": this.mainObject.link,
              "start": {
                "hour": toJS(this.mainObject.start).hour,
                "min": toJS(this.mainObject.start).min
              },
              "end": {
                "hour": toJS(this.mainObject.end).hour,
                "min": toJS(this.mainObject.end).min
              },
              "lat": this.mainObject.lat,
              "long": this.mainObject.long,
              "barcode": this.mainObject.barcode,
              "url": this.mainObject.url
            }
          }
          //console.log(init);
          await CREATE_ITEM(init);
        }
        break;
      }
    }

  }

  navScreen = 'back';

  screens = {
    layout: 'Select A Layout',
    back: 'Select A Background',
    info: 'Input Details',
    edit: 'Edit Details'
  }

  setScreen = (_screen) => {
    this.navScreen = _screen;
  }
}

decorate(CreateState, {
  mainObject: observable,
  navScreen: observable,
  screens: observable,
  editing: observable,
  _edit: observable,
  edits: observable,
  loading: observable,
  progress: observable,
  showProgress: observable
});

const createState = new CreateState();
export default createState;
