import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import './DoubleFront.css';

// Constants

class DoubleFront extends Component {
  constructor(props) {
    super();

  }

  render() {
    return (
      <div className={'dfContainer'}>
        <div className={'dfEmptyTop'}><p>{''}</p></div>

        <div className={'dfHighlight'}>
          <h5>
            {
              this.props.which === 'event' ?
              this.props.info.name.toUpperCase() :
              this.props.info.title.toUpperCase()
            }
          </h5>
        </div>

        <div className={'dfBuffer1'}><p>{''}</p></div>

        <div className={'dfHours'}>
          <h5>{this.props.info.hours}</h5>
        </div>

        <div className={'dfBuffer2'}><p>{''}</p></div>

        <div className={'dfMainText'}>
          <h1>
            {
              this.props.which === 'event' ?
              (
                <Fragment>
                  {this.props.info.title1.toUpperCase()}
                  <br/>
                  {this.props.info.title2.toUpperCase()}
                </Fragment>
              ) :
              this.props.info.name2.length === 0 ?
              this.props.info.name1.toUpperCase() :
              (
                <Fragment>
                  {this.props.info.name1.toUpperCase()}
                  <br/>
                  {this.props.info.name2.toUpperCase()}
                </Fragment>
              )
            }
          </h1>
        </div>

        <div className={'dfH1'}>
          <h4>{this.props.info.h1}</h4>
        </div>

        <div className={'dfH2'}>
          <h4>{this.props.info.h2}</h4>
        </div>

        <div className={'dfBuffer3'}><p>{''}</p></div>
      </div>
    );
  }
}

DoubleFront.propTypes = {
  which: PropTypes.oneOf(['deal', 'event']).isRequired,
  info: PropTypes.any.isRequired
}

export default DoubleFront;
