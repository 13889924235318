// Functions to get different date formats.
//
// All functions take in a Date() object and return the date
//    in the desired format.

// Arrays to return month or day strings
const MONTHS = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
export const DAYS = ['Sun','Mon','Tues','Wed','Thurs','Fri','Sat'];

// Format of date used for DynamoDB encoding
export const FORMAT1 = (date) => {
  var mo = date.getMonth() + 1;
  var day = date.getDate();
  var yr = date.getFullYear() - 2000;

  return mo.toString() + day.toString() + yr.toString();
}

// Format of date used to display in date select button (on Deals page)
export const FORMAT2 = (date) => {
  var temp = [];
  var day = date.getDate();
  var mo = MONTHS[date.getMonth()];

  temp.push(day);
  temp.push(mo);

  return temp;
}

// Format of date used on Event card front
export const FORMAT3 = (date) => {
  var mo = date.getMonth() + 1;
  var day = date.getDate();

  return mo + '/' + day;
}

// Used to get the day of the week string that displays on Event card front
export const GET_DAY = (date) => {
  var day = date.getDay();
  return DAYS[day];
}
