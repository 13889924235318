// Function to get authentication tokens to make API calls
import { Auth } from 'aws-amplify';

export const ID = async() => {
  var session = await Auth.currentSession();
  return session.getIdToken().getJwtToken();
}

export const ACCESS = async() => {
  var session = await Auth.currentSession();
  return session.getAccessToken().getJwtToken();
}

export const STRIPE_KEY = 'pk_test_j58SXdYyVsE3LvuGQouuGHfR00e7Nr60R6';
//export const STRIPE_KEY = 'pk_live_XBwC7Pkcg1sJUYEsMtsCLK5f00nndX5eQW';

// Production cloudfront dist E8RJVY91W1N5K
// Dev cloudfront dis EV4NACK5EH4H
