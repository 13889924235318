// Utilities for managing the different areas of Dallas for use
// within the app.

// General list of all areas
export const LIST = [
	'addison','arts','bishop','deep','design','downtown','kh','green','ne','nw',
	'las','lawn','park','preston','trinity','uptown','victory','white'
];

export const LOCS = [
	{
		area: 'addison',
		lat: 32.932420,
		long: -96.827050
	},
	{
		area: 'arts',
		lat: 32.789794,
		long: -96.797893
	},
	{
		area: 'bishop',
		lat: 32.748427,
		long: -96.828679
	},
	{
		area: 'deep',
		lat: 32.783632,
		long: -96.779039
	},
	{
		area: 'design',
		lat: 32.795800,
		long: -96.819200
	},
	{
		area: 'downtown',
		lat: 32.781235,
		long: -96.798542
	},
	{
		area: 'kh',
		lat: 32.816273,
		long: -96.782623
	},
	{
		area: 'green',
		lat: 32.821308,
		long: -96.770347
	},
	{
		area: 'ne',
		lat: 32.844989,
		long: -96.754243
	},
	{
		area: 'nw',
		lat: 32.886260,
		long: -96.870169
	},
	{
		area: 'las',
		lat: 32.872505,
		long: -96.945061
	},
	{
		area: 'lawn',
		lat: 32.816411,
		long: -96.809171
	},
	{
		area: 'park',
		lat: 32.842265,
		long: -96.794872
	},
	{
		area: 'preston',
		lat: 32.880124,
		long: -96.809233
	},
	{
		area: 'trinity',
		lat: 32.777305,
		long: -96.834149
	},
	{
		area: 'uptown',
		lat: 32.800173,
		long: -96.800116
	},
	{
		area: 'victory',
		lat: 32.790279,
		long: -96.810236
	},
	{
		area: 'white',
		lat: 32.828364,
		long: -96.732719
	}
];

// Names that display in AreaBtn
export const NAMES = {
	addison: "Addison",
	arts: "Arts District",
	bishop: "Bishop Arts",
	deep: "Deep Ellum",
	design: "Design District",
	downtown: "Downtown",
	kh: "Knox-Henderson",
	green: "Lower Greenville",
	ne: "Northeast",
	nw: "Northwest",
	las: "Las Colinas",
	lawn: "Oak Lawn",
	park: "Park Cities",
	preston: "Preston Hollow",
	trinity: "Trinity Groves",
	uptown: "Uptown",
	victory: "Victory Park",
	white: "White Rock"
};

export const MAP = [
	{
		id: 'addison',
		name: 'Addison'
	},
	{
		id: 'arts',
		name: 'Arts District'
	},
	{
		id: 'bishop',
		name: 'Bishop Arts'
	},
	{
		id: 'deep',
		name: 'Deep Ellum'
	},
	{
		id: 'design',
		name: 'Design District'
	},
	{
		id: 'downtown',
		name: 'Downtown'
	},
	{
		id: 'kh',
		name: 'Knox-Henderson'
	},
	{
		id: 'green',
		name: 'Lower Greenville'
	},
	{
		id: 'ne',
		name: 'Northeast'
	},
	{
		id: 'nw',
		name: 'Northwest'
	},
	{
		id: 'las',
		name: 'Las Colinas'
	},
	{
		id: 'lawn',
		name: 'Oak Lawn'
	},
	{
		id: 'park',
		name: 'Park Cities'
	},
	{
		id: 'preston',
		name: 'Preston Hollow'
	},
	{
		id: 'trinity',
		name: 'Trinity Groves'
	},
	{
		id: 'uptown',
		name: 'Uptown'
	},
	{
		id: 'victory',
		name: 'Victory Park'
	},
	{
		id: 'white',
		name: 'White Rock'
	}
];
