import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { toast } from 'react-toastify';
import { compareAsc } from 'date-fns';
import Form from 'react-bootstrap/Form';
import { fill } from '../../assets/globalStyles';
import { NAMES } from '../../assets/areas';
import Calendar from '../../assets/components/Calendar/Calendar';
import 'react-toastify/dist/ReactToastify.min.css';
import './BusinessHomePage.css';

class BusinessHomePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      subNotice: this.props.mainState.user.subLength === 'free' ? true : false,
      pushNotice: false,
      createNotice: false
    };
  }

  componentDidMount(){
    this.setState({
      subNotice: this.props.mainState.user.subLength === 'free' ? true : false
    });
  }

  render() {
    return (
      <div style={fill}>
        <div className='accountMenu'>
          <h1 className='businessName'>{this.props.mainState.user.placeName}</h1>
          <div className='menuBtnContainer'>
            <Form.Group controlId={'location'}>
              <Form.Label className={'signupLabel'}>
                Location
              </Form.Label>
              <Form.Control as={'select'} ref={ obj => this.location = obj } value={this.props.mainState.locationSelection} onChange={(e) => this.changeLocation(e)}>
                <option value={'-1'}>All</option>
                {
                  this.props.mainState.user.locations.map((loc, i) => {
                    return(
                      <option value={i}>{NAMES[loc.area]}</option>
                    )
                  })
                }
              </Form.Control>
            </Form.Group>
            <button className='all-btn homeBtn' type='button' onClick={() => this.create('deal')}>Create Deal</button>
            <button className='all-btn homeBtn' type='button' onClick={() => this.create('event')}>Create Event</button>
            <button className='all-btn homeBtn' type='button' onClick={() => this.goToPage('push')}>{'Push\nNotifications'}</button>
            <button className='all-btn homeBtn' type='button' onClick={() => this.goToPage('account')}>Manage Account</button>
            <button className='all-btn homeBtn' type='button' onClick={() => this.goToPage('analytics')}>Analytics</button>
            <h4 className={'helpTxt homeVideo'} onClick={() => this.props.playVideo('full')}>Watch Tutorial</h4>
          </div>
        </div>

        <div className='calendar-contain'>
          <Calendar data={this.props.mainState.days} edit={this.edit} delete={this.delete} locations={this.props.mainState.user.locations}/>
        </div>

        {
          this.state.subNotice &&
          <div className='subNotice'>
            <h4>{'You may use our free trial to manage deals and events happening before'}</h4>
            <h3>{'August 31st, 2019'}</h3>
            <h4>{'You must subscribe to add items happening beyond that.'}</h4>
            <div className='subscribeNowBtns'>
              <button className='subscribeNowBtn' type='button' onClick={() => this.goToPage('sub')}>Subscribe</button>
              <button className='subscribeNowBtn' type='button' onClick={() => this.closeNotice('sub')}>Close</button>
            </div>
          </div>
        }

        {
          this.state.pushNotice &&
          <div className={'pushNotice'}>
            <h3 className={'lineSpace'}>{'We are working hard to bring you this exciting and'}</h3>
            <h3 className={'lineSpace'}>{'powerful new feature SOON!'}</h3>
            <h3 className={'lineSpace'}>{'We will email you when it is ready to use.'}</h3>
            <h4 className={'lineSpace'}>{'Please contact ryan@mydallasapp.com with any questions!'}</h4>
            <button className='subscribeNowBtn pushCloseBtn' type='button' onClick={() => this.closeNotice('push')}>Close</button>
          </div>
        }

        {
          this.state.createNotice &&
          <div className={'createNotice'}>
            <h3 className={'lineSpace'}>{'Oops!'}</h3>
            <h3 className={'lineSpace'}>{'Your subscription has lapsed.'}</h3>
            <h3 className={'lineSpace'}>{'Please subscribe to continue use.'}</h3>
              <div className={'createNoticeBtns'}>
                <button className='subscribeNowBtn' type='button' onClick={() => this.closeNotice('create')}>Close</button>
                <button className='subscribeNowBtn' type='button' onClick={() => this.goToPage('sub')}>Subscribe</button>
              </div>
          </div>
        }
      </div>
    );
  }

  changeLocation = async (event) => {
    this.props.createState.setLocation(event.target.value);
    await this.props.mainState.setLocation(event.target.value);
    this.forceUpdate();
  }

  create = async (which) => {
    if (compareAsc(new Date(), this.props.mainState.user.subDate) === 1) {
      this.setState({
        createNotice: true
      });
    } else {
      if (ReactDOM.findDOMNode(this.location).value === 'All') {
        var conf = window.confirm('ATTENTION\nYou are about to create an item that will be\ninput for all of your locations.\nPlease select OK to continue or Cancel to go back.');
        if (conf) {
          await this.props.createState.setupNew(which, this.props.mainState.user);
          this.props.mainState.setPrev('home');
          this.props.mainState.selectPage('create');
        }
      } else {
        await this.props.createState.setupNew(which, this.props.mainState.user);
        this.props.mainState.setPrev('home');
        this.props.mainState.selectPage('create');
      }
    }
  }

  edit = async (obj, num) => {
    await this.props.createState.setupEdit(obj, this.props.mainState.user, num, 1);
    this.props.mainState.setPrev('home');
    this.props.mainState.selectPage('create');
  }

  delete = async (obj, num) => {
    await this.props.createState.setupEdit(obj, this.props.mainState.user, 0, 0);
    if (num === 'one') {
      await this.props.createState.deleteItem(0);
      await this.props.mainState.resetSchedule();
      this.forceUpdate();
    } else {
      await this.props.createState.setRecurring('delete');
      await this.props.mainState.resetSchedule();
      this.forceUpdate();
      // delete all instances, need setRecurring
    }
  }

  goToPage = (page) => {
    if ((page === 'push') || (page === 'analytics')) {
      this.setState({
        pushNotice: true
      });
    } else {
      this.props.mainState.setPrev('home');
      this.props.mainState.selectPage(page);
    }
  }

  closeNotice = (notice) => {
    switch (notice) {
      case 'sub': {
        this.setState({
          subNotice: false
        });
        break;
      }
      case 'push': {
        this.setState({
          pushNotice: false
        });
        break;
      }
      case 'create': {
        this.setState({
          createNotice: false
        });
        break;
      }
    }
  }

  displayInvalid = () => {
    toast.error('Invalid Access Code', {
      autoClose: 2000,
      className: 'invalid'
    });
  }
}

export default BusinessHomePage;
