import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { Auth } from 'aws-amplify';

import { GET_USER, GET_ID } from '../../api/api';
import { fill } from '../../assets/globalStyles';

import './Login.css';

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      valid: false
    }
  }

  render() {
    return (
      <div style={fill}>
        <div className="login">
          <Form onSubmit={this.handleSubmit}>
            <Form.Group controlId="email" bssize="large">
              <Form.Label className={'loginLabel'}>Email</Form.Label>
              <Form.Control
                type="text"
                onChange={this.handleChange}
              />
            </Form.Group>

            <Form.Group controlId="password" bssize="large">
              <Form.Label className={'loginLabel'}>Password</Form.Label>
              <Form.Control
                onChange={this.handleChange}
                type="password"
              />
            </Form.Group>

            <Button
              className={'loginBtn'}
              block
              variant='outline-primary'
              bssize="large"
              disabled={!this.state.valid}
              type="submit"
            >
              Login
            </Button>
          </Form>
        </div>

        <button className='all-btn signupBtn' type='button' onClick={this.gotoSignup}>Sign Up Here!</button>

      <h4 className={'helpTxt loginVideo'} onClick={() => this.props.playVideo('full')}>Watch Preview</h4>
      <h4 className={'copyright'}>{'\u00A9RewardCoin Inc. 2019'}</h4>
      </div>
    );
  }

  validateForm = () => {
    return ((this.state.email.length > 0) && (this.state.password.length > 0));
  }

  // playVideo = () => {
  //   this.props.mainState.selectPage('video');
  // }

  handleChange = (event) => {
    this.setState({
      [event.target.id]: event.target.value,
      valid: this.validateForm()
    });
  }

  handleSubmit = async (event) => {
    event.preventDefault();
    await this.props.authenticating(true);
    try {
      await Auth.signIn(this.state.email, this.state.password);
      var init = {
        body: {
          "city": "dallas",
          "email": this.state.email
        }
      };
      var id = await GET_ID(init);
      try {
        var result = await GET_USER(id);

        //this.props.mainState.setDays();
        this.props.mainState.setUser(result);
        await this.props.mainState.resetSchedule();
      } catch (e) {
        alert(e.message);
        await this.props.authenticating(false);
      }
      this.props.mainState.selectPage('home');
      await this.props.authenticating(false);
      this.props.authenticated(true);
    } catch (e) {
      alert(e.message);
      await this.props.authenticating(false);
    }
  }

  gotoSignup = async () => {
    this.props.mainState.selectPage('signup');
  }

  displayInvalid = () => {
    toast.error('Invalid Access Code', {
      autoClose: 2000,
      className: 'invalid'
    });
  }
}

Login.propTypes = {
  mainState: PropTypes.any.isRequired, // Mobx main global state
  authenticated: PropTypes.func.isRequired // Function to handle login/logout
}

export default Login;
