import React, { Component } from 'react';
//import full from '../../assets/tutorials/full.mp4';
//import layout from '../../assets/tutorials/layout.mp4';
//import details from '../../assets/tutorials/details.mp4';
import './VideoPage.css';

const VIDEOS = {
  full: require('../../assets/tutorials/full.mp4'),
  layout: require('../../assets/tutorials/layout.mp4'),
  details: require('../../assets/tutorials/details.mp4'),
}

class VideoPage extends Component {

  render() {
    return (
      <div style={{ height: '100%', width: '100%'}}>
        <video className='video' autoplay={false} controls='true'>
          <source type='video/mp4' src={VIDEOS[this.props.video]}/>
        </video>
      </div>
    );
  }
}

export default VideoPage;
