export const DEALS = {
  GROUP1: [
    'beer1',
    'beeronpatio',
    'beer2',
    'drinkscheers'
  ],
  GROUP2: [
    'cocktail1',
    'cocktail2',
    'cocktail3',
    'frozendrink'
  ],
  GROUP3: [
    'fundrinks',
    'margarita',
    'sangria',
    'wineandcheese'
  ],
  GROUP4: [
    'latte',
    'icedmocha',
    'espressopour',
    'brunch'
  ],
  GROUP5: [
    'breakfast1',
    'breakfast2',
    'donuts',
    'pancakes'
  ],
  GROUP6: [
    'macaroons',
    'avocadotoast',
    'burger',
    'burgerandfries'
  ],
  GROUP7: [
    'chipsandsalsa',
    'dumplings',
    'ramen',
    'sushi'
  ],
  GROUP8: [
    'pizza1',
    'pizza2',
    'tacos1',
    'tacos2'
  ],
  GROUP9: [
    'cupcake1',
    'cupcake2',
    'icecream1',
    'icecream2',
    'icecream3'
  ]
};

export const EVENTS = {
  GROUP1: [
    'coffee',
    'beeronpatio',
    'drinks',
    'drinksbypool'
  ],
  GROUP2: [
    'pool',
    'poolbar',
    'lakeview',
    'danceworkout'
  ],
  GROUP3: [
    'fitness',
    'spinclass',
    'workout',
    'yoga1'
  ],
  GROUP4: [
    'yoga2',
    'crossfit',
    'sparkler',
    'fireworks'
  ],
  GROUP5: [
    'acoustic',
    'electricguitar',
    'concert1',
    'concert2'
  ],
  GROUP6: [
    'mic',
    'soundboard',
    'party1',
    'party2'
  ],
  GROUP7: [
    'notes',
    'graffiti',
    'balloons'
  ]
};
