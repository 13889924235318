// DEV
export default {
  s3: {
    REGION: "us-east-1",
    BUCKET: "mycityapp-images"
  },
  apiGateway: {
    REGION: "us-east-1",
    URL: "https://178dncrff4.execute-api.us-east-1.amazonaws.com/dev"
  },
  cognito: {
    REGION: "us-east-1",
    USER_POOL_ID: "us-east-1_ihLYHqYc0",
    APP_CLIENT_ID: "9psuijt6rnjaoh0m49jidqbh",
    //IDENTITY_POOL_ID: "YOUR_IDENTITY_POOL_ID"
  }
};

// PROD
// export default {
//   s3: {
//     REGION: "us-east-1",
//     BUCKET: "mycityapp-images"
//   },
//   apiGateway: {
//     REGION: "us-east-1",
//     URL: "https://178dncrff4.execute-api.us-east-1.amazonaws.com/prod"
//   },
//   cognito: {
//     REGION: "us-east-1",
//     USER_POOL_ID: "us-east-1_RRnE12JEz",
//     APP_CLIENT_ID: "5rf6qbhka3ki2pmavod1egl7sj",
//     //IDENTITY_POOL_ID: "YOUR_IDENTITY_POOL_ID"
//   }
// };
