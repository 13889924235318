import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import { CardElement, injectStripe } from 'react-stripe-elements';
import { ID } from '../../../api/tokens';

import { CREATE_PAYMENT } from '../../../api/api';

import { fill } from '../../../assets/globalStyles';

import './BillingForm.css';

class BillingForm extends Component {
  constructor(props) {
    super();
    this.state = {
      name: '',
      isProcessing: false,
      cardComplete: false
    }
  }

  render() {
    return (
      <div className={'billing'}>
        <h2>Payment Information</h2>
        <Form style={fill} onSubmit={this.handleSubmit}>
          <Form.Row>
            <Form.Group as={Col} controlId={'name'}>
              <Form.Label className={'billingLabel'}>
                Cardholder Name
              </Form.Label>
              <Form.Control
                type={'text'}
                onChange={this.handleFieldChange}
                placeholder="Name on card"
              />
            </Form.Group>

            <Col></Col>
          </Form.Row>

          <CardElement
            className={'cardField'}
            onChange={this.handleCardFieldChange}
            style={{
              base: { fontSize: "18px", fontFamily: '"Open Sans", sans-serif' }
            }}
          />

          <Form.Row>
            <Col></Col>
            <Col>
              <Button
                className={'signupSubmit'}
                variant='outline-primary'
                bssize="large"
                disabled={!this.validateForm()}
                type="submit"
              >
                Submit
              </Button>
            </Col>
            <Col></Col>
          </Form.Row>
        </Form>

      </div>
    );
  }

  validateForm = () => {
    return (
      this.state.name !== '' &&
      this.state.cardComplete
    );
  }

  handleFieldChange = (event) => {
    this.setState({
      [event.target.id]: event.target.value
    });
  }

  handleCardFieldChange = (event) => {
    this.setState({
      cardComplete: event.complete
    });
  }

  handleSubmit = async (event) => {
    event.preventDefault();
    if (this.props.selection === 'none') {
      alert('Please select a subscription above.');
    } else if (!this.props.lapsed && (this.props.selection === this.props.mainState.user.subLength)) {
      alert('Please select a new subscription length.');
    } else if (this.props.retry) {
      let name = this.state.name;
      this.props.loading(true);

      try {
        let token = await this.props.stripe.createToken({ name });

        let auth = await ID();

        var init;
        if (this.props.mainState.user.stripeID === '0') {
          init = {
            headers: {
              'Authorization': auth
            },
            body: {
              "action": "new",
              "accountID": this.props.mainState.user.accountID,
              "email": this.props.mainState.user.email,
              "payment": token.token.id,
              "type": "sub",
              "plan": this.props.selection
            }
          }
        } else {
          init = {
            headers: {
              'Authorization': auth
            },
            body: {
              "action": "retry",
              "accountID": this.props.mainState.user.accountID,
              "customerID": this.props.mainState.user.stripeID,
              "payment": token.token.id,
              "type": "sub",
              "plan": this.props.selection
            }
          }
        }

        let res = await CREATE_PAYMENT(init);

        this.props.handleResponse(res);
      } catch (e) {
        alert(e.message);
      }

      this.props.loading(false);
    } else {
      let name = this.state.name;
      this.props.loading(true);

      try {
        let token = await this.props.stripe.createToken({ name });
        let auth = await ID();

        var init;
        if (this.props.mainState.user.stripeID === '0') {
          init = {
            headers: {
              'Authorization': auth
            },
            body: {
              "action": "new",
              "accountID": this.props.mainState.user.accountID,
              "email": this.props.mainState.user.email,
              "payment": token.token.id,
              "type": "sub",
              "plan": this.props.selection
            }
          }
        } else {
          init = {
            headers: {
              'Authorization': auth
            },
            body: {
              "action": "existing",
              "accountID": this.props.mainState.user.accountID,
              "customerID": this.props.mainState.user.stripeID,
              "source": "new",
              "payment": token.token.id,
              "type": "sub",
              "plan": this.props.selection
            }
          }
        }


        let res = await CREATE_PAYMENT(init);
        this.props.handleResponse(res);
        // if (res) {
        //   this.props.mainState.updateUser(res);
        //   this.props.displayToast(1);
        // } else {
        //   this.props.displayToast(0);
        // }
      } catch (e) {
        alert(e.message);
      }

      this.props.loading(false);
    }
  }
}

BillingForm.propTypes = {
  mainState: PropTypes.any.isRequired // Mobx main global state
}

export default injectStripe(BillingForm);
