import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { compareAsc } from 'date-fns';

import { NAMES } from '../../assets/areas';

import { fill } from '../../assets/globalStyles';

import './Account.css';

// Constants
const TEXT = {
  free: 'Free',
  monthly: 'Monthly',
  quarterly: 'Quarterly',
  yearly: 'Yearly',
  cancel: 'Cancelled'
}

class Account extends Component {
  constructor(props) {
    super(props);
    this.state = {
      locations: this.props.mainState.user.locations,
      lapsed: (compareAsc(new Date(), this.props.mainState.user.subDate) === 1) ? true : false
    }
  }

  componentDidMount(){
    this.setState({
      locations: this.props.mainState.user.locations,
      lapsed: (compareAsc(new Date(), this.props.mainState.user.subDate) === 1) ? true : false
    });
  }

  render() {
    return (
      <div style={fill}>
        <div className={'accountText'}>
          <h3>{'To change the account Administrator or to add another location to this account, please email'}</h3>
          <h3>{'ryan@mydallasapp.com'}</h3>
          <h3>{'and in the subject line, include your ID found below.'}</h3>
        </div>

        <div className={'accountContainer'}>
          <div className={'contactContainer'}>

            <div className={'contactTitle'}>
              <h2>{'Admin Information'}</h2>
            </div>


            <div className={'contactBody'}>
              <h3>{'Business: ' + this.props.mainState.user.placeName}</h3>
              <h3>{'ID: ' + this.props.mainState.user.accountID}</h3>
              <h3>{'Name: ' + this.props.mainState.user.first + ' ' + this.props.mainState.user.last}</h3>
              <h3>{'Position: ' + this.props.mainState.user.position}</h3>
              <h3>{'Email: ' + this.props.mainState.user.email}</h3>
              <h3>{'Phone: ' + this.props.mainState.user.phone}</h3>
              <hr></hr>
              <h3 className={'termsTxt'} onClick={this.props.terms}>Terms Of Service</h3>
            </div>
          </div>

          <div className={'subContainer'}>
            <div className={'subTitle'}>
              <h2>{'Subscription Information'}</h2>
            </div>

            <div className={'subBody'}>
              {
                this.state.lapsed ?
                <Fragment>
                  <h3>{'Type: LAPSED'}</h3>
                  <h3>{'Ended On: ' + this.buildDate(this.props.mainState.user.subDate)}</h3>
                </Fragment> :
                (this.props.mainState.user.subLength === 'free') ?
                <Fragment>
                  <h3>{'Current Subscription: ' + TEXT[this.props.mainState.user.subLength]}</h3>
                  <h3>{'Ending On: ' + this.buildDate(this.props.mainState.user.subDate)}</h3>
                </Fragment> :
                <Fragment>
                  <h3>{'Current Subscription: ' + TEXT[this.props.mainState.user.subLength]}</h3>
                  <h3>{'Renews On: ' + this.buildDate(this.props.mainState.user.subDate)}</h3>
                </Fragment>
              }

              {
                this.props.mainState.user.subLength === 'cancel' ?
                <button className='all-btn manageSubBtn' type='button' onClick={this.click} disabled>
                  Manage Subscription
                </button> :
                <button className='all-btn manageSubBtn' type='button' onClick={this.click}>
                  Manage Subscription
                </button>
              }
            </div>
          </div>

          <div className={'locationContainer'}>
            <div className={'locationTitle'}>
              <h2>{'Locations'}</h2>
            </div>

            <div className={'locationBody'}>
              {
                this.state.locations.map(loc => {
                  return(
                    <Fragment>
                      <div className={'location'}>
                        <h3>{NAMES[loc.area]}</h3>
                        <h3>{loc.street}</h3>
                        <h3>{loc.city + ', TX ' + loc.zip}</h3>
                      </div>
                      <hr></hr>
                    </Fragment>
                  )
                })
              }
            </div>
          </div>
        </div>
      </div>
    );
  }

  click = () => {
    this.props.mainState.selectPage('sub');
  }

  buildDate = (date) => {
    let day = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();
    return month + '/' + day + '/' + year;
  }
}

Account.propTypes = {
  mainState: PropTypes.any.isRequired // Mobx main global state
}

export default Account;
