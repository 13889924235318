import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { fill } from '../../../assets/globalStyles';

import './SelectLayout.css';

// Constants
const IMGS = {
  event: {
    singlefront: require('../../../assets/images/events/examples/singlefront.PNG'),
    singleback: require('../../../assets/images/events/examples/singleback.PNG'),
    barcodefront: require('../../../assets/images/events/examples/barcodefront.PNG'),
    barcodeback: require('../../../assets/images/events/examples/barcodeback.PNG')
  },
  deal: {
    singlefront: require('../../../assets/images/deals/examples/singlefront.PNG'),
    singleback: require('../../../assets/images/deals/examples/singleback.PNG'),
    doublefront: require('../../../assets/images/deals/examples/doublefront.PNG'),
    doubleback: require('../../../assets/images/deals/examples/doubleback.PNG'),
    barcodefront: require('../../../assets/images/deals/examples/barcodefront.PNG'),
    barcodeback: require('../../../assets/images/deals/examples/barcodeback.PNG')
  }
}

class SelectLayout extends Component {
  constructor(props) {
    super();
    this.state = {
      selected: 'none'
    }
  }

  render() {
    return (
      <div style={fill}>
        <h4 className={'helpTxt layoutVideo'} onClick={() => this.props.playVideo('layout')}>Layout Tutorial</h4>

        <div className={'layouts'}>
          <div className={'layout'}>
            <h2 className={'layoutTxt'}>{'Front'}</h2>

            <div className={'layoutImg'}>
              <img
                className={'img-fluid'}
                src={IMGS[this.props.createState.mainObject.which].singlefront}
                alt={'Single Front'}
              />
            </div>

            <h2 className={'layoutTxt'}>{'Back'}</h2>

            <div className={'layoutImg'}>
              <img
                className={'img-fluid'}
                src={IMGS[this.props.createState.mainObject.which].singleback}
                alt={'Single Back'}
              />
            </div>

            <button
              className='all-btn layoutBtn'
              type='button'
              onClick={this.selectSingle}>
              Single
            </button>
          </div>

          {
            this.props.createState.mainObject.which === 'deal' &&
            <div className={'layout'}>
              <h2 className={'layoutTxt'}>{'Front'}</h2>

              <div className={'layoutImg'}>
                <img
                  className={'img-fluid'}
                  src={IMGS[this.props.createState.mainObject.which].doublefront}
                  alt={'Double Front'}
                />
              </div>

              <h2 className={'layoutTxt'}>{'Back'}</h2>

              <div className={'layoutImg'}>
                <img
                  className={'img-fluid'}
                  src={IMGS[this.props.createState.mainObject.which].doubleback}
                  alt={'Double Back'}
                />
              </div>

              <button
                className='all-btn layoutBtn'
                type='button'
                onClick={this.selectDouble}>
                Double
              </button>
            </div>
          }

          <div className={'layout'}>
            <h2 className={'layoutTxt'}>{'Front'}</h2>

            <div className={'layoutImg'}>
              <img
                className={'img-fluid'}
                src={IMGS[this.props.createState.mainObject.which].barcodefront}
                alt={'Barcode Front'}
              />
            </div>

            <h2 className={'layoutTxt'}>{'Back'}</h2>

            <div className={'layoutImg'}>
              <img
                className={'img-fluid'}
                src={IMGS[this.props.createState.mainObject.which].barcodeback}
                alt={'Barcode Back'}
              />
            </div>
            <button
              className='all-btn layoutBtn'
              type='button'
              onClick={this.selectBarcode}>
              Barcode
            </button>
          </div>
        </div>
      </div>
    );
  }

  selectSingle = () => {
    this.props.createState.setAttribute('layout', 'single');
    this.props.createState.setScreen('info');
  }

  selectDouble = () => {
    if (this.props.createState.mainObject.which === 'events') {
      alert('Double layout is not available for events');
    } else {
      this.props.createState.setAttribute('layout', 'double');
      this.props.createState.setScreen('info');
    }
  }

  selectBarcode = () => {
    alert('Make sure you have registered a barcode before creating a barcode item.\nFor more info contact ryan@mydallasapp.com');
    this.props.createState.setAttribute('layout', 'barcode');
    this.props.createState.setScreen('info');
  }
}

SelectLayout.propTypes = {
  createState: PropTypes.any.isRequired // State to manage item creation
}

export default SelectLayout;
