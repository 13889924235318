import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import { Auth } from 'aws-amplify';
import { PLACES } from '../../assets/businesses';

import { CREATE_USER, GET_USER } from '../../api/api';
import { MAP } from '../../assets/areas';
import {
  validateName,
  validateEmail,
  validatePhone,
  validateZip,
  validatePassword
} from '../../assets/validate';

import { fill } from '../../assets/globalStyles';

import './Signup.css';

class Signup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      locationHelp: false,
      addLocation: false,
      locationAdded: false,
      email: '',
      password: '',
      confirmPassword: '',
      placeID: '',
      placeName: '',
      first: '',
      last: '',
      phone: '',
      position: '',
      area: '',
      street: '',
      city: '',
      zip: '',
      latitude: '',
      longitude: '',
      valid: false
    }
  }

  render() {
    return (
      <div style={fill}>
        <div className={'signupForm'}>
          <Form onSubmit={e => this.handleSubmit(e)}>
            <Form.Row>
              <Form.Group as={Col} controlId="email">
                <Form.Label className={'signupLabel'}>
                  Email
                </Form.Label>
                <Form.Control
                  onChange={this.handleChange}
                  type={'email'}
                />
              </Form.Group>

              <Form.Group as={Col} controlId={'password'}>
                <Form.Label className={'signupLabel'}>
                  Password
                </Form.Label>
                <Form.Control
                  onChange={this.handleChange}
                  type={'password'}
                />
                <Form.Text className={'signupLabel'}>Must be at least 8 characters & contain uppercase, lowercase, and numbers</Form.Text>
              </Form.Group>

              <Form.Group as={Col} controlId={'confirmPassword'}>
                <Form.Label className={'signupLabel'}>
                  Confirm Password
                </Form.Label>
                <Form.Control
                  onChange={this.handleChange}
                  type={'password'}
                />
              </Form.Group>
            </Form.Row>

            <Form.Row>
              <Form.Group as={Col} controlId={'first'}>
                <Form.Label className={'signupLabel'}>
                  First Name
                </Form.Label>
                <Form.Control
                  onChange={this.handleChange}
                  type={'text'}
                />
              </Form.Group>

              <Form.Group as={Col} controlId={'last'}>
                <Form.Label className={'signupLabel'}>
                  Last Name
                </Form.Label>
                <Form.Control
                  onChange={this.handleChange}
                  type={'text'}
                />
              </Form.Group>

              <Form.Group as={Col} controlId={'position'}>
                <Form.Label className={'signupLabel'}>
                  Position
                </Form.Label>
                <Form.Control
                  onChange={this.handleChange}
                  type={'text'}
                />
                <Form.Text className={'signupLabel'}>(Owner, Manager, etc)</Form.Text>
              </Form.Group>

              <Form.Group as={Col} controlId={'phone'}>
                <Form.Label className={'signupLabel'}>
                  Contact Phone
                </Form.Label>
                <Form.Control
                  onChange={this.handleChange}
                  type={'text'}
                />
              </Form.Group>
            </Form.Row>

            <Form.Row>
              <Form.Group as={Col} controlId={'business'}>
                <Form.Label className={'signupLabel'}>
                  Business <span className={'helpTxt'} onClick={this.toggleAddLoc}>Not In The List?</span>
                </Form.Label>
                <Form.Control as={'select'} ref={ obj => this.business = obj } onChange={this.setFields}>
                  <option>Select Business Name ...</option>
                  {
                    PLACES.map((place, i) => {
                      return(
                        <option>{place.name}</option>
                      )
                    })
                  }
                  <option>{this.state.placeName}</option>
                </Form.Control>
              </Form.Group>

              <Form.Group as={Col} controlId={'area'}>
                <Form.Label className={'signupLabel'}>
                  Dallas Area
                </Form.Label>
                <Form.Control as={'select'} ref={ obj => this.area = obj } onChange={this.validateForm}>
                  <option>Select Area ...</option>
                  {
                    MAP.map(area => {
                      return(
                        <option>{area.name}</option>
                      )
                    })
                  }
                </Form.Control>
                <Form.Text className={'signupLabel'}>Select the area of Dallas that best describes this location</Form.Text>
              </Form.Group>
            </Form.Row>

            <Form.Group controlId={'street'}>
              <Form.Label className={'signupLabel'}>
                Business Street Address
              </Form.Label>
              <Form.Control
                onChange={this.handleChange}
                type={'text'}
              />
            <Form.Text className={'signupLabel'}>You will be able to add more locations after you create an account</Form.Text>
            </Form.Group>

            <Form.Row>
              <Form.Group as={Col} controlId={'city'}>
                <Form.Label className={'signupLabel'}>
                  City
                </Form.Label>
                <Form.Control
                  onChange={this.handleChange}
                  type={'text'}
                />
              </Form.Group>

              <Form.Group as={Col} controlId={'zip'}>
                <Form.Label className={'signupLabel'}>
                  Zip Code
                </Form.Label>
                <Form.Control
                  onChange={this.handleChange}
                  type={'text'}
                />
              </Form.Group>
            </Form.Row>

            <Form.Row>
              <Form.Group as={Col} controlId={'latitude'}>
                <Form.Label className={'signupLabel'}>
                  Latitude <span className={'helpTxt'} onClick={this.toggleInstructions}>Need Help?</span>
                </Form.Label>
                <Form.Control
                  value={this.state.latitude}
                  onChange={this.handleChange}
                  onPaste={this.handleChange}
                  type={'text'}
                />
              <Form.Text className={'signupLabel'}>Enter 0 if you do not want customers to be able to get directions to your business.</Form.Text>
              </Form.Group>

              <Form.Group as={Col} controlId={'longitude'}>
                <Form.Label className={'signupLabel'}>
                  Longitude <span className={'helpTxt'} onClick={this.toggleInstructions}>Need Help?</span>
                </Form.Label>
                <Form.Control
                  value={this.state.longitude}
                  onChange={this.handleChange}
                  onPaste={this.handleChange}
                  type={'text'}
                />
              <Form.Text className={'signupLabel'}>Enter 0 if you do not want customers to be able to get directions to your business.</Form.Text>
              </Form.Group>
            </Form.Row>

            <Form.Check inline label={'I am authorized to manage the account for the above selected business'} type={'checkbox'} ref={ref => this.authorized = ref} className={'signupAuth'}/>

            <Form.Row>
              <Col></Col>
              <Col>
                <Button
                  className={'signupSubmit'}
                  variant='outline-primary'
                  bssize="large"
                  type="submit"
                >
                  Sign Up
                </Button>
              </Col>
              <Col></Col>
            </Form.Row>
          </Form>
          <h4 className={'helpTxt signupTerms'} onClick={this.props.terms}>Terms Of Service</h4>
        </div>

        {
          this.state.locationHelp &&
          <div className={'locationHelp'}>
            <div className={'locationTitle'}>
              <h3>{'How To Get GPS Coordinates:'}</h3>
            </div>

            <div>
              <ul>
                <li className={'listTxt'}>Go to <a target="_blank" rel="noopener noreferrer" href="https://www.google.com/maps">Google Maps</a>.</li>
                <li className={'listTxt'}>Search for your business location.</li>
                <li className={'listTxt'}>Click next to the pin for your business (next to, not on the pin).</li>
                <li className={'listTxt'}>The box that appears will have two 8 digit decimal numbers, separated by a comma.</li>
                <li className={'listTxt'}>The first number is latitude, the second is longitude. Be sure to copy ALL digits!</li>
                <li className={'listTxt'}>Enter these so users can get directions to your business right from our app!</li>
              </ul>
            </div>

            <button className={'subContinueBtn'} type='button' onClick={this.toggleInstructions}>Done</button>
          </div>
        }

        {
          this.state.addLocation &&
          <div className={'addLocation'}>
            <div className={'locationTitle'}>
              <h3>{'Add Your Business'}</h3>
            </div>

            <div className={'addForm'}>
              <Form>
                <Form.Group controlId={'placeName'}>
                  <Form.Label className={'signupLabel'}>
                    Business Name
                  </Form.Label>
                  <Form.Control
                    onChange={this.handleChange}
                    type={'text'}
                  />
                </Form.Group>

                <Form.Group controlId={'placeID'}>
                  <Form.Label className={'signupLabel'}>
                    Business ID
                  </Form.Label>
                  <Form.Control
                    onChange={this.handleChange}
                    type={'text'}
                  />
                <Form.Text className={'signupLabel'}>Enter a single, unique word to identify your business. No spaces or special characters.</Form.Text>
                </Form.Group>

                <Form.Row>
                  <Col></Col>
                  <Col>
                    <button className={'signupHelpBtn leftBtn'} type='button' onClick={this.toggleAddLoc}>Cancel</button>
                  </Col>
                  <Col>
                    <button className={'signupHelpBtn rightBtn'} type='button' onClick={this.addLocation}>Submit</button>
                  </Col>
                  <Col></Col>
                </Form.Row>
              </Form>
            </div>
          </div>
        }
      </div>
    );
  }

  toggleInstructions = () => {
    let current = this.state.locationHelp;
    this.setState({
      locationHelp: !current
    });
  }

  toggleAddLoc = () => {
    let current = this.state.addLocation;
    this.setState({
      addLocation: !current
    });
  }

  addLocation = () => {
    let temp = this.state.placeID.replace(/\s/g, "").toLowerCase();
    // Check with backend to see if pid is unique
    ReactDOM.findDOMNode(this.business).value = this.state.placeName;
    this.setState({
      placeID: temp,
      locationAdded: true
    });
    this.toggleAddLoc();
  }

  validateForm = () => {
    var valid = true;
    if (this.state.email.length === 0) { valid = false; }
    if (this.state.password.length === 0) { valid = false; }
    if (this.state.confirmPassword.length === 0) { valid = false; }
    if (this.state.first.length === 0) { valid = false; }
    if (this.state.last.length === 0) { valid = false; }
    if (this.state.position.length === 0) { valid = false; }
    if (this.state.phone.length === 0) { valid = false; }
    if (ReactDOM.findDOMNode(this.business).value === 'Select Business Name ...') { valid = false; }
    if (ReactDOM.findDOMNode(this.area).value === 'Select Area ...') { valid = false; }
    if (this.state.street.length === 0) { valid = false; }
    if (this.state.city.length === 0) { valid = false; }
    if (this.state.zip.length === 0) { valid = false; }
    if (this.state.latitude.length === 0) { valid = false; }
    if (this.state.longitude.length === 0) { valid = false; }
    if (!ReactDOM.findDOMNode(this.authorized).checked) { valid = false; }

    return valid;
  }

  setFields = () => {
    for (var i = 0; i < PLACES.length; i++) {
      if (PLACES[i].name === ReactDOM.findDOMNode(this.business).value) {
        //console.log('found');
        this.setState({
          placeID: PLACES[i].id,
          placeName: PLACES[i].name,
          latitude: PLACES[i].latitude,
          longitude: PLACES[i].longitude
        });
      }
    }
  }

  getFields = async () => {
    var _placeID;
    var _placeName;
    var _area;

    for (var j = 0; j < MAP.length; j++) {
      if (MAP[j].name === ReactDOM.findDOMNode(this.area).value) {
        _area = MAP[j].id;
      }
    }

    if (this.state.placeName !== ReactDOM.findDOMNode(this.business).value) {
      for (var i = 0; i < PLACES.length; i++) {
        if (PLACES[i].name === ReactDOM.findDOMNode(this.business).value) {
          //console.log('found');
          this.setState({
            placeID: PLACES[i].id,
            placeName: PLACES[i].name,
            latitude: PLACES[i].latitude,
            longitude: PLACES[i].longitude,
            area: _area
          });
        }
      }
    }  else {
      this.setState({
        placeID: this.state.placeID,
        placeName: this.state.placeName,
        area: _area
      });
    }




  }

  handleChange = (event) => {
    this.setState({
      [event.target.id]: event.target.value,
      valid: this.validateForm()
    });
  }

  handleSubmit = async (event) => {
    event.preventDefault();
    var _first = validateName(this.state.first);
    var _last = validateName(this.state.last);
    var _email = validateEmail(this.state.email);
    var _phone = validatePhone(this.state.phone);
    var _zip = validateZip(this.state.zip);
    var _pass = validatePassword(this.state.password, this.state.confirmPassword);

    if (_pass === 1) {
      if (_email === 1) {
        if (_first === 1) {
          if (_last === 1) {
            if (_phone === 1) {
              if (_zip === 1) {
                if (this.validateForm()) {
                  this.props.loading(true);
                  await this.getFields();

                  try {
                    var init = {
                      body: {
                        "city": "dallas",
                        "which": "createUser",
                        "placeID": this.state.placeID,
                        "placeName": this.state.placeName,
                        "area": this.state.area,
                        "street": this.state.street,
                        "locCity": this.state.city,
                        "zip": this.state.zip,
                        "latitude": parseFloat(this.state.latitude),
                        "longitude": parseFloat(this.state.longitude),
                        "first": this.state.first,
                        "last": this.state.last,
                        "position": this.state.position,
                        "phone": this.state.phone,
                        "email": this.state.email
                      }
                    };
                    let result = await CREATE_USER(init);
                    //console.log(result);
                    if (result !== 0) {
                      try {
                        await Auth.signUp({
                          username: this.state.email,
                          password: this.state.password
                        });
                        this.props.authenticating(true);
                        await Auth.signIn(this.state.email, this.state.password);
                        let res = await GET_USER(this.state.placeID + this.state.area);
                        //console.log('res = ' + JSON.stringify(res));
                        this.props.mainState.setDays();
                        this.props.mainState.setUser(res);
                        await this.props.mainState.resetSchedule();
                        this.props.mainState.selectPage('home');
                        this.props.authenticating(false);
                        this.props.authenticated(true);
                        this.props.loading(false);
                      } catch (e) {
                        alert(e.message);
                      }
                    } else {
                      //console.log('error');
                      this.props.loading(false);
                    }
                  } catch (e) {
                    //console.log('no');
                    alert(e.message);
                  }
                } else {
                  alert('Please fill out all form fields.');
                  this.props.loading(false);
                }
              } else {
                alert(_zip);
              }
            } else {
              alert(_phone);
            }
          } else {
            alert(_last);
          }
        } else {
          alert(_first);
        }
      } else {
        alert(_email);
      }
    } else {
      alert(_pass);
    }
    this.props.loading(false);
  }

  displayInvalid = () => {
    toast.error('Invalid Access Code', {
      autoClose: 2000,
      className: 'invalid'
    });
  }
}

Signup.propTypes = {
  mainState: PropTypes.any.isRequired, // Mobx main global state
  authenticated: PropTypes.func.isRequired // Function to handle login/logout
}

export default Signup;
