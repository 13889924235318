import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { fill } from '../../../assets/globalStyles';
import './CalendarItem.css';

// Constants

class CalendarItem extends Component {
  constructor(props) {
    super(props);
    var loc;
    for (let i = 0; i < this.props.locations.length; i ++) {
      if (this.props.locations[i].area === this.props.obj.area) {
        loc = i;
      }
    }

    var _classname = 'container' + loc;
    this.state = {
      classname: _classname
    }
  }

  render() {

    return (
      <div style={fill} className={'dropdown'}>
        <div className={this.state.classname}>
          {' ' + this.props.obj.title}
        </div>
        <div className={'dropdown-content'}>
          <div onClick={() => this.click('edit', 'one')}>
            <h5>{
                this.props.obj.hasOwnProperty('recur') ?
                'Edit This Once' :
                'Edit'
              }</h5>
          </div>

          {
            this.props.obj.hasOwnProperty('recur') &&
            <div onClick={() => this.click('edit', 'all')}>
              <h5>{'Edit All Recurring'}</h5>
            </div>
          }

          <div onClick={() => this.click('delete', 'one')}>
            <h5>{
                this.props.obj.hasOwnProperty('recur') ?
                'Delete This Once' :
                'Delete'
              }</h5>
          </div>

          {
            this.props.obj.hasOwnProperty('recur') &&
            <div onClick={() => this.click('delete', 'all')}>
              <h5>{'Delete All Recurring'}</h5>
            </div>
          }
        </div>
      </div>
    );
  }

  click = (action, selection) => {
    if (action === 'edit') {
      if (selection === 'one') {
        let conf = window.confirm('You are only editing one instance of this item.');
        if (conf) {
          this.props.edit(this.props.obj, selection);
        } else {
          console.log('cancel');
        }
      } else {
        let conf = window.confirm('You are editing all remaining instances of this item.');
        if (conf) {
          this.props.edit(this.props.obj, selection);
        } else {
          console.log('cancel');
        }
      }
    } else {
      if (selection === 'one') {
        let conf = window.confirm('Are you sure you want to\ndelete this item?');
        if (conf) {
          console.log('delete');
          // delete item
          this.props.delete(this.props.obj, 'one');
        } else {
          console.log('cancel');
        }
      } else {
        let conf = window.confirm('Are you sure you want to delete\nall instances of this item?');
        if (conf) {
          console.log('delete');
          // delete all items
          this.props.delete(this.props.obj, 'all');
        } else {
          console.log('cancel');
        }
      }
    }
  }

}

CalendarItem.propTypes = {
  obj: PropTypes.any.isRequired, // Deal/Event Item
  locations: PropTypes.any.isRequired, // User's locations
  edit: PropTypes.func.isRequired // Function to edit a deal/event
}

export default CalendarItem;
