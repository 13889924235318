// Modules
import React, { Component, Fragment } from 'react';
import { ToastContainer } from 'react-toastify';
import Spinner from 'react-spinner';
import { isMobile } from 'react-device-detect';
import { observer } from 'mobx-react';
import { Auth } from 'aws-amplify';
import fullVideo from './assets/tutorials/full.mp4';

import './App.css';

// API
import { GET_USER, GET_ID } from './api/api';

// State Data
import mainState from './stateData/MainState';
import createState from './stateData/CreateState';

// Components
import Terms from './assets/components/Terms/Terms';

// Pages
import Login from './pages/Login/Login';
import Signup from './pages/Signup/Signup';
import BusinessHomePage from './pages/BusinessHome/BusinessHomePage';
import Create from './pages/Create/Create';
import Account from './pages/Account/Account';
import Subscriptions from './pages/Subscriptions/Subscriptions';
import VideoPage from './pages/Video/VideoPage';

// Constants

class App extends Component {
  constructor(props){
    super(props);
    this.state = {
      isAuthenticated: false,
      isAuthenticating: true,
      loading: true,
      terms: false,
      mobile: isMobile,
      video: false,
      tutorial: 'full'
    }
  }

  async componentDidMount(){
    try {
      var tokens = await Auth.currentSession();
      var temp = await Auth.currentAuthenticatedUser();

      this.setCurrentUser(temp.attributes.email);
    }
    catch(e) {
      if (e !== 'No current user') {
        alert(e);
      }
      this.setState({
        isAuthenticating: false,
        loading: false,
        mobile: isMobile
      });
    }
    //mainState.setDays();
  }

  render() {
    //var _loading = this.state.loading || createState.loading;

    const SCREENS = {
      login: <Login mainState={mainState} authenticated={this.userHasAuthenticated} authenticating={this.userIsAuthenticating} playVideo={this.playVideo}/>,
      signup: <Signup mainState={mainState} authenticated={this.userHasAuthenticated} authenticating={this.userIsAuthenticating} loading={this.loading} terms={this.toggleTerms} refresh={this.refresh}/>,
      home: <BusinessHomePage mainState={mainState} createState={createState} loading={this.loading} playVideo={this.playVideo}/>,
      create: <Create createState={createState} mainState={mainState} loading={this.userIsAuthenticating} playVideo={this.playVideo}/>,
      account: <Account mainState={mainState} terms={this.toggleTerms}/>,
      sub: <Subscriptions mainState={mainState} loading={this.loading} />
    }

    var _progress = <div className={'progressTxt'}>
      <h1>{createState.progress + '%'}</h1>
    </div>;

    return (
      <div className={'app'}>
        {
          createState.loading &&
          <div className={'loader'}>
            <Spinner />
            {
              createState.showProgress && _progress
            }
          </div>
        }

        {
          this.state.loading &&
          <div className={'loader'}>
            <Spinner />
          </div>
        }

        <div className={'navbar'}>
          {
            this.state.isAuthenticated ?
            <Fragment>
              {
                mainState.selectedPage === 'home' ?
                <button
                  id={'backBtn'}
                  ref={ btn => this.backBtn = btn }
                  className='all-btn navBtn backBtn'
                  type='button'
                  disabled
                  onClick={this.goBack}>
                  Back
                </button> :
                <button
                  id={'backBtn'}
                  ref={ btn => this.backBtn = btn }
                  className='all-btn navBtn backBtn'
                  type='button'
                  onClick={this.goBack}>
                  Back
                </button>
              }

              <div className={'navBuffer'}>
                <h2 className={'title'}>
                  {
                    mainState.selectedPage === 'create' ?
                    createState.screens[createState.navScreen] :
                    mainState.pages[mainState.selectedPage]
                  }
                </h2>
              </div>

              <button
                id={'logoutBtn'}
                ref={ btn => this.logoutBtn = btn }
                className='all-btn navBtn logoutBtn'
                type='button'
                onClick={this.logout}>
                Logout
              </button>
            </Fragment> :

            <Fragment>
              {
                mainState.selectedPage === 'login' ?
                <button
                  id={'backBtn'}
                  ref={ btn => this.backBtn = btn }
                  className='all-btn navBtn backBtn'
                  type='button'
                  disabled
                  onClick={this.goBack}>
                  Back
                </button> :
                <button
                  id={'backBtn'}
                  ref={ btn => this.backBtn = btn }
                  className='all-btn navBtn backBtn'
                  type='button'
                  onClick={this.goBack}>
                  Back
                </button>
              }

              <div className={'navBuffer'}>
                <h2 className={'title'}>
                  {
                    mainState.selectedPage === 'create' ?
                    createState.screens[createState.navScreen] :
                    mainState.pages[mainState.selectedPage]
                  }
                </h2>
              </div>

              <button
                id={'logoutBtn'}
                ref={ btn => this.logoutBtn = btn }
                className='all-btn navBtn logoutBtn'
                type='button'
                disabled
                onClick={this.logout}>
                Logout
              </button>
            </Fragment>
          }
        </div>

        {
          !this.state.isAuthenticating &&
          <div className={'page'}>
            {SCREENS[mainState.selectedPage]}
          </div>
        }

        {
          this.state.terms &&
          <div className={'terms'}>
            <Terms terms={this.toggleTerms}/>
          </div>
        }

        {
          this.state.mobile &&
          <div className={'mobileNotice'}>
            <h3>We are sorry, our portal is not optimized for mobile use at this time. Please access on a desktop, and we will have a mobile version up and running soon.</h3>
          </div>
        }

        {
          this.state.video &&
          <div className={'tutorialVideo'}>
            <button className={'all-btn videoBtn'} type='button' onClick={this.closeVideo}>Close</button>
            <VideoPage video={this.state.tutorial}/>
          </div>
        }

        <ToastContainer closeButton={false} hideProgressBar={true}/>
      </div>
    );
  }

  refresh = () => {
    this.forceUpdate();
  }

  toggleTerms = () => {
    let current = this.state.terms;
    this.setState({
      terms: !current
    });
  }

  playVideo = (_video) => {
    this.setState({
      video: true,
      tutorial: _video
    });
  }

  closeVideo = () => {
    this.setState({
      video: false
    });
  }

  userHasAuthenticated = (auth) => {
    this.setState({
      isAuthenticated: auth
    });
  }

  userIsAuthenticating = (auth) => {
    this.setState({
      isAuthenticating: auth,
      loading: auth
    });
  }

  loading = (isLoading) => {
    this.setState({
      loading: isLoading
    });
  }

  setCurrentUser = async (email) => {
    var init = {
      body: {
        "city": "dallas",
        "email": email
      }
    };
    var id = await GET_ID(init);
    //console.log(id);
    try {
      var result = await GET_USER(id);
      mainState.setUser(result);
      await mainState.resetSchedule();

      mainState.selectPage('home');
      this.userHasAuthenticated(true);
      this.userIsAuthenticating(false);

    } catch (e) {
      alert(e.message);
    }
  }

  goBack = () => {
    if (mainState.selectedPage === 'create') {
      switch (createState.navScreen) {
        case 'back': {
          mainState.selectPage('home');
          break;
        }
        case 'layout': {
          createState.setScreen('back');
          break;
        }
        case 'info': {
          createState.setScreen('layout');
          break;
        }
        case 'edit': {
          mainState.selectPage('home');
        }
        default: {
          mainState.selectPage('home');
        }
      }
    } else if (mainState.selectedPage === 'signup') {
      mainState.selectPage('login');
    } else if (mainState.selectedPage === 'sub') {
      mainState.selectPage('account');
    } else {
      mainState.selectPage('home');
    }
  }

  logout = async (event) => {
    this.loading(true);
    mainState.setLocation('-1');
    createState.setLocation('-1');
    await mainState.logout();
    await Auth.signOut();

    this.userHasAuthenticated(false);
    mainState.selectPage('login');
    this.loading(false);
  }
}

export default observer(App);
