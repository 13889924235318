import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import './BarcodeFront.css';

// Constants

class BarcodeFront extends Component {
  constructor(props) {
    super();

  }

  render() {

    return (
      <div className={'bfContainer'}>
        <div className={'bfEmptyTop'}><p>{''}</p></div>

        <div className={'bfHighlight'}>
          <h5>
            {
              this.props.which === 'event' ?
              this.props.info.name.toUpperCase() :
              this.props.info.title.toUpperCase()
            }
          </h5>
        </div>

        <div className={'bfBuffer1'}><p>{''}</p></div>

        <div className={'bfHours'}>
          <h5>{this.props.info.hours}</h5>
        </div>

        <div className={'bfBuffer2'}><p>{''}</p></div>

        <div className={'bfMainText'}>
          <h1>
            {
              this.props.which === 'event' ?
              (
                <Fragment>
                  {this.props.info.title1.toUpperCase()}
                  <br/>
                  {this.props.info.title2.toUpperCase()}
                </Fragment>
              ) :
              this.props.info.name2.length === 0 ?
              this.props.info.name1.toUpperCase() :
              (
                <Fragment>
                  {this.props.info.name1.toUpperCase()}
                  <br/>
                  {this.props.info.name2.toUpperCase()}
                </Fragment>
              )
            }
          </h1>
        </div>

        <div className={'bfH'}>
          <h4>{this.props.info.h}</h4>
        </div>

        <div className={'bfBuffer3'}><p>{''}</p></div>
      </div>
    );
  }

}

BarcodeFront.propTypes = {
  which: PropTypes.oneOf(['deal', 'event']).isRequired,
  info: PropTypes.any.isRequired
}

export default BarcodeFront;
