import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import { fill } from '../../../assets/globalStyles';

import './SelectBack.css';

import { DEALS, EVENTS } from '../../../assets/backs';
import { DIMGS, EIMGS } from './images';

// Constants
const BACKS = {
  deal: [DEALS, DIMGS],
  event: [EVENTS, EIMGS]
}

class SelectBack extends Component {
  constructor(props) {
    super();
    this.state = {
      selected: 'none'
    }
  }

  render() {
    return (
      <div style={fill}>
        <button
          className='all-btn backsBtn'
          type='button'
          onClick={this.next}>
          Next
        </button>

        <div className={'backsContainer'}>
          <div className={'backsRow'}>
            {
              BACKS[this.props.createState.mainObject.which][0].GROUP1.map(img => {
                return(
                  <div
                    className={`
                      backImg
                      ${
                        (this.state.selected === img) && "backSelected"
                      }
                    `}
                    onClick={() => this.selectBack(img)}
                  >
                    <img
                      className={'img-fluid'}
                      src={BACKS[this.props.createState.mainObject.which][1][img]}
                      alt={img}
                    />
                  </div>
                )
              })
            }
          </div>

          <div className={'backsRow'}>
            {
              BACKS[this.props.createState.mainObject.which][0].GROUP2.map(img => {
                return(
                  <div
                    className={`
                      backImg
                      ${
                        (this.state.selected === img) && "backSelected"
                      }
                    `}
                    onClick={() => this.selectBack(img)}
                  >
                    <img
                      className={'img-fluid'}
                      src={BACKS[this.props.createState.mainObject.which][1][img]}
                      alt={img}
                    />
                  </div>
                )
              })
            }
          </div>

          <div className={'backsRow'}>
            {
              BACKS[this.props.createState.mainObject.which][0].GROUP3.map(img => {
                return(
                  <div
                    className={`
                      backImg
                      ${
                        (this.state.selected === img) && "backSelected"
                      }
                    `}
                    onClick={() => this.selectBack(img)}
                  >
                    <img
                      className={'img-fluid'}
                      src={BACKS[this.props.createState.mainObject.which][1][img]}
                      alt={img}
                    />
                  </div>
                )
              })
            }
          </div>

          <div className={'backsRow'}>
            {
              BACKS[this.props.createState.mainObject.which][0].GROUP4.map(img => {
                return(
                  <div
                    className={`
                      backImg
                      ${
                        (this.state.selected === img) && "backSelected"
                      }
                    `}
                    onClick={() => this.selectBack(img)}
                  >
                    <img
                      className={'img-fluid'}
                      src={BACKS[this.props.createState.mainObject.which][1][img]}
                      alt={img}
                    />
                  </div>
                )
              })
            }
          </div>

          <div className={'backsRow'}>
            {
              BACKS[this.props.createState.mainObject.which][0].GROUP5.map(img => {
                return(
                  <div
                    className={`
                      backImg
                      ${
                        (this.state.selected === img) && "backSelected"
                      }
                    `}
                    onClick={() => this.selectBack(img)}
                  >
                    <img
                      className={'img-fluid'}
                      src={BACKS[this.props.createState.mainObject.which][1][img]}
                      alt={img}
                    />
                  </div>
                )
              })
            }
          </div>

          <div className={'backsRow'}>
            {
              BACKS[this.props.createState.mainObject.which][0].GROUP6.map(img => {
                return(
                  <div
                    className={`
                      backImg
                      ${
                        (this.state.selected === img) && "backSelected"
                      }
                    `}
                    onClick={() => this.selectBack(img)}
                  >
                    <img
                      className={'img-fluid'}
                      src={BACKS[this.props.createState.mainObject.which][1][img]}
                      alt={img}
                    />
                  </div>
                )
              })
            }
          </div>

          <div className={'backsRow'}>
            {
              BACKS[this.props.createState.mainObject.which][0].GROUP7.map(img => {
                return(
                  <div
                    className={`
                      backImg
                      ${
                        (this.state.selected === img) && "backSelected"
                      }
                    `}
                    onClick={() => this.selectBack(img)}
                  >
                    <img
                      className={'img-fluid'}
                      src={BACKS[this.props.createState.mainObject.which][1][img]}
                      alt={img}
                    />
                  </div>
                )
              })
            }
          </div>

          {
            this.props.createState.mainObject.which === 'deal' &&
            <Fragment>
              <div className={'backsRow'}>
                {
                  DEALS.GROUP8.map(img => {
                    return(
                      <div
                        className={`
                          backImg
                          ${
                            (this.state.selected === img) && "backSelected"
                          }
                        `}
                        onClick={() => this.selectBack(img)}
                      >
                        <img
                          className={'img-fluid'}
                          src={DIMGS[img]}
                          alt={img}
                        />
                      </div>
                    )
                  })
                }
              </div>

              <div className={'backsRow'}>
                {
                  DEALS.GROUP9.map(img => {
                    return(
                      <div
                        className={`
                          backImg
                          ${
                            (this.state.selected === img) && "backSelected"
                          }
                        `}
                        onClick={() => this.selectBack(img)}
                      >
                        <img
                          className={'img-fluid'}
                          src={DIMGS[img]}
                          alt={img}
                        />
                      </div>
                    )
                  })
                }
              </div>
            </Fragment>
          }
        </div>
      </div>
    );
  }

  selectBack = (back) => {
    this.setState({
      selected: back
    });
  }

  next = () => {
    if (this.state.selected === 'none') {
      alert('Please select a background.');
    } else {
      this.props.createState.setAttribute('back', this.state.selected);
      this.props.createState.setScreen('layout');
    }
  }
}

SelectBack.propTypes = {
  createState: PropTypes.any.isRequired, // Create info state
}

export default SelectBack;
