export const DIMGS = {
  avocadotoast: require('../../../assets/images/deals/backs/avocadotoast.png'),
  beer1: require('../../../assets/images/deals/backs/beer1.png'),
  beer2: require('../../../assets/images/deals/backs/beer2.png'),
  beeronpatio: require('../../../assets/images/deals/backs/beeronpatio.png'),
  breakfast1: require('../../../assets/images/deals/backs/breakfast1.png'),
  breakfast2: require('../../../assets/images/deals/backs/breakfast2.png'),
  brunch: require('../../../assets/images/deals/backs/brunch.png'),
  burger: require('../../../assets/images/deals/backs/burger.png'),
  burgerandfries: require('../../../assets/images/deals/backs/burgerandfries.png'),
  chipsandsalsa: require('../../../assets/images/deals/backs/chipsandsalsa.png'),
  cocktail1: require('../../../assets/images/deals/backs/cocktail1.png'),
  cocktail2: require('../../../assets/images/deals/backs/cocktail2.png'),
  cocktail3: require('../../../assets/images/deals/backs/cocktail3.png'),
  cupcake1: require('../../../assets/images/deals/backs/cupcake1.png'),
  cupcake2: require('../../../assets/images/deals/backs/cupcake2.png'),
  donuts: require('../../../assets/images/deals/backs/donuts.png'),
  drinkscheers: require('../../../assets/images/deals/backs/drinkscheers.png'),
  dumplings: require('../../../assets/images/deals/backs/dumplings.png'),
  espressopour: require('../../../assets/images/deals/backs/espressopour.png'),
  frozendrink: require('../../../assets/images/deals/backs/frozendrink.png'),
  fundrinks: require('../../../assets/images/deals/backs/fundrinks.png'),
  icecream1: require('../../../assets/images/deals/backs/icecream1.png'),
  icecream2: require('../../../assets/images/deals/backs/icecream2.png'),
  icecream3: require('../../../assets/images/deals/backs/icecream3.png'),
  icedmocha: require('../../../assets/images/deals/backs/icedmocha.png'),
  latte: require('../../../assets/images/deals/backs/latte.png'),
  macaroons: require('../../../assets/images/deals/backs/macaroons.png'),
  margarita: require('../../../assets/images/deals/backs/margarita.png'),
  pancakes: require('../../../assets/images/deals/backs/pancakes.png'),
  pizza1: require('../../../assets/images/deals/backs/pizza1.png'),
  pizza2: require('../../../assets/images/deals/backs/pizza2.png'),
  ramen: require('../../../assets/images/deals/backs/ramen.png'),
  sangria: require('../../../assets/images/deals/backs/sangria.png'),
  sushi: require('../../../assets/images/deals/backs/sushi.png'),
  tacos1: require('../../../assets/images/deals/backs/tacos1.png'),
  tacos2: require('../../../assets/images/deals/backs/tacos2.png'),
  wineandcheese: require('../../../assets/images/deals/backs/wineandcheese.png')
};

export const EIMGS = {
  acoustic: require('../../../assets/images/events/backs/acoustic.png'),
  balloons: require('../../../assets/images/events/backs/balloons.png'),
  beeronpatio: require('../../../assets/images/events/backs/beeronpatio.png'),
  coffee: require('../../../assets/images/events/backs/coffee.png'),
  concert1: require('../../../assets/images/events/backs/concert1.png'),
  concert2: require('../../../assets/images/events/backs/concert2.png'),
  crossfit: require('../../../assets/images/events/backs/crossfit.png'),
  danceworkout: require('../../../assets/images/events/backs/danceworkout.png'),
  drinks: require('../../../assets/images/events/backs/drinks.png'),
  drinksbypool: require('../../../assets/images/events/backs/drinksbypool.png'),
  electricguitar: require('../../../assets/images/events/backs/electricguitar.png'),
  fireworks: require('../../../assets/images/events/backs/fireworks.png'),
  fitness: require('../../../assets/images/events/backs/fitness.png'),
  graffiti: require('../../../assets/images/events/backs/graffiti.png'),
  lakeview: require('../../../assets/images/events/backs/lakeview.png'),
  mic: require('../../../assets/images/events/backs/mic.png'),
  notes: require('../../../assets/images/events/backs/notes.png'),
  party1: require('../../../assets/images/events/backs/party1.png'),
  party2: require('../../../assets/images/events/backs/party2.png'),
  pool: require('../../../assets/images/events/backs/pool.png'),
  poolbar: require('../../../assets/images/events/backs/poolbar.png'),
  soundboard: require('../../../assets/images/events/backs/soundboard.png'),
  sparkler: require('../../../assets/images/events/backs/sparkler.png'),
  spinclass: require('../../../assets/images/events/backs/spinclass.png'),
  workout: require('../../../assets/images/events/backs/workout.png'),
  yoga1: require('../../../assets/images/events/backs/yoga1.png'),
  yoga2: require('../../../assets/images/events/backs/yoga2.png')
};
