import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { compareAsc } from 'date-fns';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import { Elements, StripeProvider } from 'react-stripe-elements';
import { ID } from '../../api/tokens';

import { STRIPE_KEY } from '../../api/tokens';
import { CREATE_PAYMENT } from '../../api/api';
import BillingForm from '../../assets/components/BillingForm/BillingForm';
import { fill } from '../../assets/globalStyles';

import './Subscriptions.css';

const TEXT = {
  free: 'Free',
  monthly: 'Monthly',
  quarterly: 'Quarterly',
  yearly: 'Yearly'
}

class Subscriptions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: 'none',
      bill: (this.props.mainState.user.stripeID === '0'),
      lapsed: (compareAsc(new Date(), this.props.mainState.user.subDate) === 1) ? true : false,
      errorNotice: false,
      trialNotice: false,
      error: '0',
      retry: false
    }
  }

  componentDidMount(){
    this.setState({
      lapsed: (compareAsc(new Date(), this.props.mainState.user.subDate) === 1) ? true : false
    });
  }

  render() {

    let errors = {
      '0':
      <Fragment>
        <h3 className={'lineSpace'}>{'Error creating customer. You were not charged.'}</h3>
        <h3 className={'lineSpace'}>{'Please try again.'}</h3>
        <h3 className={'lineSpace'}>{'Contact ryan@mydallasapp.com if the problem persists.'}</h3>
      </Fragment>,
      '2':
      <Fragment>
        <h3 className={'lineSpace'}>{'Card Declined, you were not charged.'}</h3>
        <h3 className={'lineSpace'}>{'Please try again with a different payment method.'}</h3>
      </Fragment>,
      '3':
      <Fragment>
        <h3 className={'lineSpace'}>{'Our portal does not accept 3D secure payments at this time.'}</h3>
        <h3 className={'lineSpace'}>{'Please try again with a different payment method'}</h3>
        <h3 className={'lineSpace'}>{'or contact ryan@mydallasapp.com for a manual invoice.'}</h3>
      </Fragment>,
      '4':
      <Fragment>
        <h3 className={'lineSpace'}>{'Your subscription was successful, but there was an error'}</h3>
        <h3 className={'lineSpace'}>{'updating your account in our database.'}</h3>
        <h3 className={'lineSpace'}>{'Please contact ryan@mydallasapp.com to fix this issue.'}</h3>
      </Fragment>,
      '5':
      <Fragment>
        <h3 className={'lineSpace'}>{'Payment retry failed.'}</h3>
        <h3 className={'lineSpace'}>{'Please email ryan@mydallasapp.com for a manual invoice.'}</h3>
      </Fragment>
    }

    return (
      <div style={fill}>

        <div className={'currentSub'}>
          {
            this.state.lapsed ?
            <Fragment>
              <h3>{'Current Subscription: LAPSED'}</h3>
              <h3>{'Ended On: ' + this.buildDate(this.props.mainState.user.subDate)}</h3>
            </Fragment> :
            (this.props.mainState.user.subLength === 'free') ?
            <Fragment>
              <h3>{'Current Subscription: ' + TEXT[this.props.mainState.user.subLength]}</h3>
              <h3>{'Ending On: ' + this.buildDate(this.props.mainState.user.subDate)}</h3>
            </Fragment> :
            <Fragment>
              <h3>{'Current Subscription: ' + TEXT[this.props.mainState.user.subLength]}</h3>
              <h3>{'Renews On: ' + this.buildDate(this.props.mainState.user.subDate)}</h3>
            </Fragment>
          }
        </div>

        <div className={'subscriptions'}>
          <div className={'subText'}>
            <h2>{'Select A Subscription'}</h2>
            <h2>{'* If you change your subscription/billing length, your next bill will be prorated to reflect differences *'}</h2>
          </div>


          <div className={'subOptions'}>
            <div className={`
                subOne
                ${
                  (this.state.selected === 'monthly') && "subSelected"
                }
              `}
              onClick={() => this.selectOption('monthly')}
            >
              <img
                className={'img-fluid'}
                src={require('../../assets/images/subs/monthly.png')}
                alt={'Monthly'}
              />
            </div>

            <div className={`
                subThree
                ${
                  (this.state.selected === 'quarterly') && "subSelected"
                }
              `}
              onClick={() => this.selectOption('quarterly')}
            >
              <img
                className={'img-fluid'}
                src={require('../../assets/images/subs/quarterly.png')}
                alt={'3 Month'}
              />
            </div>

            <div className={`
                subTwelve
                ${
                  (this.state.selected === 'yearly') && "subSelected"
                }
              `}
              onClick={() => this.selectOption('yearly')}
            >
              <img
                className={'img-fluid'}
                src={require('../../assets/images/subs/yearly.png')}
                alt={'12 Month'}
              />
            </div>
          </div>

          <div className={'extraTxt'}>
            <h3 style={{fontSize: '1em'}}>{'* Price lock guarantee protects against price increases for duration of selected billing period.'}</h3>
          </div>
        </div>

        {
          (this.state.bill || this.state.retry) ?
          <StripeProvider apiKey={STRIPE_KEY}>
            <Elements>
              <BillingForm
                mainState={this.props.mainState}
                selection={this.state.selected}
                loading={this.props.loading}
                handleResponse={this.handleResponse}
                lapsed={this.state.lapsed}
                retry={this.state.retry}
              />
            </Elements>
          </StripeProvider> :
          <div className={'billing'}>
            <h2 className={'highlightText'} onClick={this.changeSub}>Use Previous Payment Method</h2>
            <h2 className={'highlightText'} onClick={this.toggleBilling}>Use New Payment Method</h2>
          </div>
        }

        {
          this.state.errorNotice &&
          <div className={'errorNotice'}>
            {errors[this.state.error]}
            <button className='subscribeNowBtn pushCloseBtn' type='button' onClick={() => this.closeError()}>Close</button>
          </div>
        }

        {
          this.state.trialNotice &&
          <div className={'trialNotice'}>
            <h3 className={'lineSpace'}>{'Success!'}</h3>
            <h3 className={'lineSpace'}>{'Your billing will start on September 1.'}</h3>
            <button className='subscribeNowBtn pushCloseBtn' type='button' onClick={() => this.closeTrial()}>Close</button>
          </div>
        }

      </div>
    );
  }

  toggleBilling = () => {
    this.setState({
      bill: true
    });
  }

  buildDate = (date) => {
    let day = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();
    return month + '/' + day + '/' + year;
  }

  selectOption = (option) => {
    this.setState({
      selected: option
    });
  }

  changeSub = async () => {
    if (this.state.selected === 'none') {
      alert('Please select a subscription above.');
    } else if (!this.state.lapsed && (this.state.selected === this.props.mainState.user.subLength)) {
      alert('Please select a new subscription length.');
    } else {
      this.props.loading(true);

      let auth = await ID();

      let init = {
        headers: {
          'Authorization': auth
        },
        body: {
          "action": "existing",
          "accountID": this.props.mainState.user.accountID,
          "customerID": this.props.mainState.user.stripeID,
          "source": "old",
          "type": "sub",
          "plan": this.state.selected
        }
      }

      try {
        let res = await CREATE_PAYMENT(init);
        this.handleResponse(res);
      } catch (e) {
        alert(e.message);
      }

      this.props.loading(false);
    }
  }

  handleResponse = (response) => {
    switch(response.code) {
      case 0: {
        this.setState({
          errorNotice: true,
          error: '0'
        });
        break;
      }
      case 1: {
        this.props.mainState.updateUser(response);
        if (compareAsc(new Date(), new Date(2019, 7, 31)) === 1) {
          this.displayToast(1);
          this.props.mainState.selectPage('home');
        } else {
          this.setState({
            trialNotice: true
          });
          this.displayToast(2);
        }
        break;
      }
      case 2: {
        this.props.mainState.updateUser(response);
        this.displayToast(0);
        this.setState({
          errorNotice: true,
          error: '2',
          retry: true
        });
        break;
      }
      case 3: {
        this.props.mainState.updateUser(response);
        this.displayToast(0);
        this.setState({
          errorNotice: true,
          error: '3',
          retry: true
        });
        break;
      }
      case 4: {
        this.props.mainState.updateUser(response);
        this.displayToast(1);
        this.setState({
          errorNotice: true,
          error: '4'
        });
        break;
      }
      case 5: {
        this.displayToast(0);
        this.setState({
          errorNotice: true,
          error: '5'
        });
        break;
      }
    }
  }

  closeError = () => {
    this.setState({
      errorNotice: false
    });
  }

  closeTrial = () => {
    this.setState({
      trialNotice: false
    });
    this.props.mainState.selectPage('home');
  }

  displayToast = (which) => {
    switch (which) {
      case 0: {
        toast.error('Payment Failed', {
          autoClose: 3000,
          className: 'invalid'
        });
        break;
      }
      case 1: {
        toast.error('Payment Successful!', {
          autoClose: 3000,
          className: 'success'
        });
        break;
      }
      case 2: {
        toast.error('Subscription Successful!', {
          autoClose: 3000,
          className: 'success'
        });
        break;
      }
      default: {

      }
    }
  }
}

Subscriptions.propTypes = {
  mainState: PropTypes.any.isRequired // Mobx main global state
}

export default Subscriptions;
