import React, { Component } from 'react';
import dateFns from 'date-fns';
import { FORMAT1 } from '../../../assets/date';

import '../../../App.css';
import './Calendar.css';

import CalendarItem from '../CalendarItem/CalendarItem';

class Calendar extends Component {
  constructor(props) {
    super();
    this.state = {
      today: new Date(),
      currentMonth: new Date(),
      selectedDay: new Date(),
      monthCounter: 0
    }
  }

  renderHeader = () => {
    const dateFormat = "MMMM YYYY";

    return (
      <div className="header row flex-middle">
        <div className="col col-start">
          <div className="icon" onClick={this.prevMonth}>
            chevron_left
          </div>
        </div>
        <div className="col col-center">
          <span>
            {dateFns.format(this.state.currentMonth, dateFormat)}
          </span>
        </div>
        <div className="col col-end" onClick={this.nextMonth}>
          <div className="icon">chevron_right</div>
        </div>
      </div>
    );
  }

  renderDays = () => {
    const dateFormat = "dddd";
    const days = [];

    let startDate = dateFns.startOfWeek(this.state.currentMonth);

    for (let i = 0; i < 7; i++) {
      days.push(
        <div className="col col-center" key={i}>
          {dateFns.format(dateFns.addDays(startDate, i), dateFormat)}
        </div>
      );
    }

    return (<div className="days row">{days}</div>);
  }

  renderCells = () => {
    const { currentMonth, selectedDate } = this.state;
    const monthStart = dateFns.startOfMonth(currentMonth);
    const monthEnd = dateFns.endOfMonth(monthStart);
    const startDate = dateFns.startOfWeek(monthStart);
    const endDate = dateFns.endOfWeek(monthEnd);

    const dateFormat = "D";
    const rows = [];

    let days = [];
    let day = startDate;
    let formattedDate = "";
    let today = new Date();


    while (day <= endDate) {
      for (let i = 0; i < 7; i++) {
        formattedDate = dateFns.format(day, dateFormat);
        const cloneDay = day;
        var _date = FORMAT1(day);
        days.push(
          <div
            className={`col cell ${
              (!dateFns.isSameMonth(day, monthStart) || (dateFns.compareAsc(today, day) === 1))
                ? "disabled"
                : dateFns.isSameDay(day, selectedDate) ? "selected" : ""
            }`}
            key={day}
          >
            <span className="number">{formattedDate}</span>
            <span className="bg">
              {
                this.props.data.hasOwnProperty(_date) ?
                this.props.data[_date].map(obj => {
                  return(
                    <div className="item">
                      <CalendarItem obj={obj} click={this.selectItem} locations={this.props.locations} edit={this.props.edit} delete={this.props.delete}/>
                    </div>
                  )
                }) :
                null
              }
            </span>
          </div>
        );
        day = dateFns.addDays(day, 1);
      }
      rows.push(
        <div className="row" key={day}>
          {days}
        </div>
      );
      days = [];
    }

    return (<div className="body">{rows}</div>);
  }

  render() {

    return (
      <div className='calendar'>
        {this.renderHeader()}

        {this.renderDays()}

        {this.renderCells()}
      </div>
    );
  }

  selectDay = (day) => {
    this.setState({
      selectedDate: day
    });
  }

  selectItem = (obj) => {
    console.log(obj);
  }

  prevMonth = () => {
    if (this.state.monthCounter > 0) {
      var newCounter = this.state.monthCounter - 1;
      //console.log(this.state.monthCounter);
      //console.log(newCounter);
      this.setState({
        currentMonth: dateFns.subMonths(this.state.currentMonth, 1),
        monthCounter: newCounter
      });
    }
  }

  nextMonth = () => {
    if (this.state.monthCounter < 2) {
      var newCounter = this.state.monthCounter + 1;
      //console.log(this.state.monthCounter);
      //console.log(newCounter);
      this.setState({
        currentMonth: dateFns.addMonths(this.state.currentMonth, 1),
        monthCounter: newCounter
      });
    }
  }
}

export default Calendar;
