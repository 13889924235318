import React, { Component } from 'react';
import PropTypes from 'prop-types';

import './SingleBack.css';

// Constants

class SingleBack extends Component {
  constructor(props) {
    super();
    this.state = {

    }
  }

  render() {

    return (
      <div className={'sbContainer'}>

        <div className={'sbH'}>
          <h4>
            {this.props.info.h}
          </h4>
        </div>

        <div className={'sbDetails'}>
          <h5>
            {this.props.info.detailsL1}
            <br/>
            {this.props.info.detailsL2}
            <br/>
            {this.props.info.detailsL3}
            <br/>
            {this.props.info.detailsL4}
            <br/>
            {this.props.info.detailsL5}
            <br/>
            {this.props.info.detailsL6}
            <br/>
            {this.props.info.detailsL7}
          </h5>
        </div>

        {
          ((this.props.which === 'event') && (this.props.info.link !== '')) ?
          <div className={'sbLink'}>
            <h4>{'Go To Event Site'}</h4>
          </div> : null
        }
      </div>
    );
  }
}

SingleBack.propTypes = {
  which: PropTypes.oneOf(['deal', 'event']).isRequired,
  info: PropTypes.any.isRequired
}

export default SingleBack;
