import React, { Component } from 'react';

import { fill } from '../../../assets/globalStyles';

import './Terms.css';

class Terms extends Component {
  constructor(props) {
    super();
  }

  render() {
    return (
      <div style={fill}>
        <h3 className={'closeTerms'} onClick={this.props.terms}>Close</h3>
        <h1>Website Terms and Conditions of Use</h1>

        <h2>1. Terms</h2>

        <p>{'By accessing this Website, accessible from https://portal.mydallasapp.com, you are agreeing to be bound by these Website Terms and Conditions of Use and agree that you are responsible for the agreement with any applicable local laws. If you disagree with any of these terms, you are prohibited from accessing this site. The materials contained in this Website are protected by copyright and trademark law.'}</p>
        <p>{'https://portal.mydallasapp.com'} allows you to create information that is displayed to users in a mobile app. myDallas understands that information related to the entertainment industry may include mild profanity or sexual themes. Users must be 17+ to use the adjoining mobile app, so explicit themes are allowed within reason, left up to the discretion of myDallas&trade;.
          myDallas will inform you if any information is removed, and may terminate your access for repeated instances. Please contact support@mydallasapp.com if you have a question about certain content.
        </p>
        <p>By creating an account and using {'https://portal.mydallasapp.com'} you claim to have a legal authority to manage an account for the business entity selected at signup. If you create an account without the proper authority for a business entity, you may or may not be subject to civil litigation and be held liable for any damages caused to the business by your actions. myDallas assumes no responsibility for fraudulent use of the Website.</p>

        <h2>2. Use License</h2>

        <p>Permission is granted to request one copy of the materials on the myDallas Website for personal, non-commercial transitory viewing only. This is the grant of a license, not a transfer of title, and under this license you may not:</p>

        <ul>
            <li>modify or copy the materials;</li>
            <li>use the materials for any commercial purpose or for any public display;</li>
            <li>{'attempt to reverse engineer any software contained on myDallas\'s Website;'}</li>
            <li>remove any copyright or other proprietary notations from the materials; or</li>
            <li>transferring the materials to another person or "mirror" the materials on any other server.</li>
        </ul>

        <p>{'This will let myDallas terminate upon violations of any of these restrictions. Upon termination, your viewing right will also be terminated and you should destroy any downloaded materials in your possession whether it is printed or electronic format.'}</p>

        <h2>3. Disclaimer</h2>

        <p>{'All the materials on myDallas\’ Website are provided "as is". myDallas makes no warranties, may it be expressed or implied, therefore negates all other warranties. Furthermore, myDallas does not make any representations concerning the accuracy or reliability of the use of the materials on its Website or otherwise relating to such materials or any sites linked to this Website.'}</p>

        <h2>4. Limitations</h2>

        <p>
          {'myDallas or its suppliers will not be hold accountable for any damages that will arise with the use or inability to use the materials on https://portal.mydallasapp.com, even if myDallas or an authorized representative of this Website has been notified, orally or written, of the possibility of such damage. Some jurisdiction does not allow limitations on implied warranties or limitations of liability for incidental damages, ' +
            'these limitations may not apply to you.'}
        </p>

        <h2>5. Revisions and Errata</h2>

        <p>{'The materials appearing on https://portal.mydallasapp.com may include technical, typographical, or photographic errors. myDallas will not promise that any of the materials in this Website are accurate, complete, or current. myDallas may change the materials contained on its Website at any time without notice. myDallas does not make any commitment to update the materials.'}</p>

        <h2>6. Links</h2>

        <p>{'myDallas has not reviewed all of the sites linked to its Website and is not responsible for the contents of any such linked site. The presence of any link does not imply endorsement by myDallas of the site. The use of any linked website is at the user’s own risk.'}</p>

        <h2>7. Site Terms of Use Modifications</h2>

        <p>{'myDallas may revise these Terms of Use for its Website at any time without prior notice. By using this Website, you are agreeing to be bound by the current version of these Terms and Conditions of Use.'}</p>

        <h2>8. Subscriptions</h2>

        <p>All payments on {'https://portal.mydallasapp.com'} are handled by Stripe&trade; payments API, and myDallas never stores or transmits your payment information.</p>
        <p>{'You may email support@mydallasapp.com to cancel your subscription at any time. Upon cancellation, Monthly and Quarterly subscriptions already paid will not be refunded, but will not renew for the next billing period. Yearly subscriptions may be refunded according to unused time, for up to half of the current subscription period, up to the discretion of myDallas. ' +
          'If myDallas terminates your account for violations laid out in these Terms, you will not be refunded for any portion of your subscription. All refunds'}
        </p>

        <h2>9. Your Privacy</h2>

        <p>Please read our <a target="_blank" rel="noopener noreferrer" href="http://privacy.mydallasapp.com">Privacy Policy</a>. This Website is operated under the same privacy rules as our mobile app.</p>

        <h2>10. Governing Law</h2>

        <p>{'Any claim related to myDallas\' Website shall be governed by the laws of the US without regards to its conflict of law provisions.'}</p>
      </div>
    );
  }
}

export default Terms;
