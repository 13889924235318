import React, { Component } from 'react';
import PropTypes from 'prop-types';

import './DoubleBack.css';

// Constants

class DoubleBack extends Component {
  constructor(props) {
    super();
  }

  render() {
    return (
      <div className={'dbContainer'}>

        <div className={'dbH1'}>
          <h4>
            {this.props.info.h1}
          </h4>
        </div>

        <div className={'dbDetails1'}>
          <h5>
            {this.props.info.details11}
            <br/>
            {this.props.info.details12}
            <br/>
            {this.props.info.details13}
          </h5>
        </div>

        <div className={'dbH2'}>
          <h4>
            {this.props.info.h2}
          </h4>
        </div>

        <div className={'dbDetails2'}>
          <h5>
            {this.props.info.details21}
            <br/>
            {this.props.info.details22}
            <br/>
            {this.props.info.details23}
          </h5>
        </div>
      </div>
    );
  }
}

DoubleBack.propTypes = {
  which: PropTypes.oneOf(['deal', 'event']).isRequired,
  info: PropTypes.any.isRequired
}

export default DoubleBack;
