// State to manage overall site

import { decorate, observable } from 'mobx';
import { addDays, compareAsc, startOfWeek } from 'date-fns';
import { FORMAT1 } from '../assets/date';
import { GET_SCHEDULE } from '../api/api';

class MainState {

  //var today = new Date();
  end = addDays(new Date(), 90);
  days = {};
  weeks = [];

  locationSelection = '-1';
  setLocation = async (loc) => {
    this.locationSelection = loc;
    await this.resetSchedule();
  }

  setDays = async () => {
    this.weeks = [];
    for (let key in this.days) {
      delete this.days[key];
    }

    let today = new Date();
    do {
      let key = FORMAT1(today);
      this.days[key] = [];
      today = addDays(today, 1);
    } while (compareAsc(today, this.end) !== 1);
    //console.log(this.days);

    let start = startOfWeek(new Date());
    let _end = startOfWeek(this.end);
    do {
      let week = FORMAT1(start);
      this.weeks.push(week);
      start = addDays(start, 7);
    } while (compareAsc(start, _end) !== 1);
    //console.log(this.weeks);
  }


  selectedPage = 'login';
  prevPage = 'none';
  login = true;
  signup = false;
  home = false;
  create = false;
  account = false;
  sub = false;
  //video = false;

  pages = {
    login: 'myDallas Portal',
    signup: 'Business Signup',
    home: 'Home',
    create: 'Create',
    account: 'Account Information',
    analytics: 'Analytics',
    sub: 'Subscription Options',
    //video: 'myDallas Preview'
  }

  user = {
    first: '',
    last: '',
    phone: '',
    email: '',
    position: '',
    placeID: '',
    accountID: '',
    placeName: '',
    events: 0,
    subDate: new Date(2019, 7, 31),
    subLength: 'free',
    event: '', // default name for events
    deal: '', //default name for deals
    locations: [],
    stripeID: '0'
  };

  rawSchedule = '';

  updateUser = (props) => {
    if (props.hasOwnProperty('subDate')) {
      this.user.subDate = new Date(JSON.parse(props.subDate));
    }
    if (props.hasOwnProperty('id')) {
      this.user.stripeID = props.id;
    }
    if (props.hasOwnProperty('subLength')) {
      this.user.subLength = props.subLength;
    }
    //console.log(JSON.stringify(this.user));
  }

  setUser = async (user) => {
    this.user.first = user.admin.first;
    this.user.last = user.admin.last;
    this.user.phone = user.admin.phone;
    this.user.email = user.admin.email;
    this.user.position = user.admin.position;
    this.user.stripeID = user.admin.stripeID;
    this.user.placeID = user.placeID;
    this.user.accountID = user.accountID;
    this.user.placeName = user.placeName;
    this.user.events = user.events;
    this.user.subDate = new Date(JSON.parse(user.subInfo.subDate));
    this.user.subLength = user.subInfo.subLength;
    this.user.event = user.defaults.eventName;
    this.user.deal = user.defaults.dealName;
    this.user.locations = user.locations;
  }

  setSchedule = async (schedule) => {
    this.rawSchedule = JSON.stringify(schedule);
    //console.log(schedule);
    for (var i = 0; i < this.weeks.length; i++) {
      // i loops through each week in the current timeframe
      if (schedule.scheduled.hasOwnProperty(this.weeks[i])) {
        // if there are any items scheduled for a given week
        for (var j = 0; j < schedule.scheduled[this.weeks[i]].length; j++) {
          // j loops through each scheduled item in a given week
          var _day = schedule.scheduled[this.weeks[i]][j].date;
          var _date = new Date(
            parseInt(schedule.scheduled[this.weeks[i]][j].year),
            parseInt(schedule.scheduled[this.weeks[i]][j].month) - 1,
            parseInt(schedule.scheduled[this.weeks[i]][j].day)
          );
          if (this.isCurrent(_date) && !schedule.scheduled[this.weeks[i]][j].deleted) {
            this.days[_day].push(schedule.scheduled[this.weeks[i]][j]);
          }
        }
      }
    }
  }

  resetSchedule = async () => {
    await this.setDays();
    //console.log('reset schedule = ' + this.locationSelection);
    if (this.locationSelection === '-1') {
      for (let i = 0; i < this.user.locations.length; i ++) {
        let schedule = await GET_SCHEDULE(this.user.placeID, this.user.locations[i].area);
        //console.log(schedule);
        await this.setSchedule(schedule);
      }
    } else {
      let schedule = await GET_SCHEDULE(this.user.placeID, this.user.locations[this.locationSelection].area);
      //console.log(schedule);
      await this.setSchedule(schedule);
    }
  }

  isCurrent = (date) => {
    var compare = compareAsc(date, new Date());
    return (compare === 1);
  }


  setPrev = (page) => {
    this.prevPage = page;
  }

  selectPage = (page) => {
    this.selectedPage = page;
  }

  logout = async () => {
    this.weeks = [];
    for (var key in this.days) {
      delete this.days[key];
    }
  }
}

decorate(MainState, {
  selectedPage: observable,
  prevPage: observable,
  login: observable,
  business: observable,
  signup: observable,
  create: observable,
  account: observable,
  sub: observable,
  //video: observable,
  pages: observable,
  places: observable,
  user: observable,
  schedule: observable,
});

const mainState = new MainState();
export default mainState;
