export const PLACES = [
  {
    id: 'test',
    name: 'Testing Place',
    latitude: 32.821599,
    longitude: -96.788433
  }
];

export const PLACES2 = [
  {
    id: 'abacus',
    name: 'Abacus',
    latitude: 32.821599,
    longitude: -96.788433
  },
  {
    id: 'ac',
    name: 'AC Lounge',
    latitude: 32.780332,
    longitude: -96.796202
  },
  {
    id: 'adair',
    name: 'Adair\'s Saloon',
    latitude: 32.782599,
    longitude: -96.785074
  },
  {
    id: 'addisonicehouse',
    name: 'Addison Ice House',
    latitude: 32.935460,
    longitude: -96.851035
  },{
    id: 'adolpus',
    name: 'The Adolphus Hotel',
    latitude: 32.780127,
    longitude: -96.799640
  },
  {
    id: 'biernat',
    name: 'Al Biernat\'s',
    latitude: 32.820280,
    longitude: -96.802661
  },
  {
    id: 'angry',
    name: 'Angry Dog',
    latitude: 32.782905,
    longitude: -96.783564
  },
  {
    id: 'asador',
    name: 'Asador',
    latitude: 32.803663,
    longitude: -96.831911
  },
  {
    id: 'louie',
    name: 'Bar Louie',
    latitude: 32.869703,
    longitude: -96.768075
  },
  {
    id: 'barcadia',
    name: 'Barcadia',
    latitude: 32.811685,
    longitude: -96.775125
  },
  {
    id: 'barley',
    name: 'Barley House',
    latitude: 32.841761,
    longitude: -96.771971
  },
  {
    id: 'bbc',
    name: 'British Beverage Company',
    latitude: 32.798426,
    longitude: -96.803712
  },
  {
    id: 'billycc',
    name: 'Billy Can Can',
    latitude: 32.78086,
    longitude: -96.80911
  },
  {
    id: 'blackfriar',
    name: 'Blackfriar Pub',
    latitude: 32.797289,
    longitude: -96.801874
  },
  {
    id: 'blatt',
    name: 'Blatt Beer & Table',
    latitude: 32.881182,
    longitude: -96.773508
  },
  {
    id: 'bluefish',
    name: 'The Blue Fish (Addison)',
    latitude: 32.996369,
    longitude: -96.830616
  },
  {
    id: 'bluefish',
    name: 'The Blue Fish (Las Colinas)',
    latitude: 32.890266,
    longitude: -96.958651
  },
  {
    id: 'bluefish',
    name: 'The Blue Fish (Greenville)',
    latitude: 32.830327,
    longitude: -96.770500
  },
  {
    id: 'bluegoose',
    name: 'Blue Goose',
    latitude: 32.823649,
    longitude: -96.770383
  },
  {
    id: 'bluemesa',
    name: 'Blue Mesa',
    latitude: 32.796784,
    longitude: -96.797151
  },
  {
    id: 'bluessg',
    name: 'Blue Sushi Sake Grill (Preston Hollow)',
    latitude: 32.881049,
    longitude: -96.773229
  },
  {
    id: 'bluessg',
    name: 'Blue Sushi Sake Grill (Uptown)',
    latitude: 32.802875,
    longitude: -96.799506
  },
  {
    id: 'bolsa',
    name: 'Bolsa',
    latitude: 32.749314,
    longitude: -96.831981
  },
  {
    id: 'boulevardier',
    name: 'Boulevardier',
    latitude: 32.748099,
    longitude: -96.827804
  },
  {
    id: 'bbanter',
    name: 'Bourbon & Banter',
    latitude: 32.780673,
    longitude: -96.794667
  },
  {
    id: 'bowen',
    name: 'Bowen House',
    latitude: 32.797922,
    longitude: -96.801963
  },
  {
    id: 'bbarrel',
    name: 'Bowl & Barrel',
    latitude: 32.870145,
    longitude: -96.767321
  },
  {
    id: 'braindead',
    name: 'Braindead Brewing',
    latitude: 32.783809,
    longitude: -96.785383
  },
  {
    id: 'bwinners',
    name: 'Bread Winners (Uptown)',
    latitude: 32.804519,
    longitude: -96.799528
  },
  {
    id: 'bwinners',
    name: 'Bread Winners (Preston Hollow)',
    latitude: 32.850136,
    longitude: -96.818350
  },
  {
    id: 'bwinners',
    name: 'Bread Winners (Northeast)',
    latitude: 32.868426,
    longitude: -96.773790
  },
  {
    id: 'bryantavern',
    name: 'Bryan Street Tavern',
    latitude: 32.799660,
    longitude: -96.779144
  },
  {
    id: 'bullion',
    name: 'Bullion',
    latitude: 32.776358,
    longitude: -96.805632
  },
  {
    id: 'buzzbrewslakewood',
    name: 'BuzzBrews (Lakewood)',
    latitude: 32.812272,
    longitude: -96.761608
  },
  {
    id: 'salsera',
    name: 'Cafe Salsera',
    latitude: 32.784062,
    longitude: -96.786100
  },
  {
    id: 'campisi',
    name: 'Campisi\'s',
    latitude: 32.781287,
    longitude: -96.798687
  },
  {
    id: 'campuzano',
    name: 'Campuzano Mexican Food',
    latitude: 32.805783,
    longitude: -96.812074
  },
  {
    id: 'rosso',
    name: 'Cane Rosso',
    latitude: 32.782513,
    longitude: -96.785503
  },
  {
    id: 'capitolpub',
    name: 'Capitol Pub',
    latitude: 32.814794,
    longitude: -96.778794
  },
  {
    id: 'cedarspringstaphouse',
    name: 'Cedar Springs Taphouse',
    latitude: 32.811779,
    longitude: -96.812689
  },
  {
    id: 'celebration',
    name: 'Celebration',
    latitude: 32.851530,
    longitude: -96.833267
  },
  {
    id: 'celestial',
    name: 'Celestial Beerworks',
    latitude: 32.820003,
    longitude: -96.833085
  },
  {
    id: 'cheesecake',
    name: 'Cheesecake Factory',
    latitude: 32.864222,
    longitude: -96.773586
  },
  {
    id: 'chelsea',
    name: 'Chelsea Corner',
    latitude: 32.826626,
    longitude: -96.785118
  },
  {
    id: 'christie',
    name: 'Christie\'s Sports Bar',
    latitude: 32.799513,
    longitude: -96.801133
  },
  {
    id: 'chuys',
    name: 'Chuy\'s',
    latitude: 32.822407,
    longitude: -96.787433
  },
  {
    id: 'chbistro',
    name: 'City Hall Bistro',
    latitude: 32.779885,
    longitude: -96.799832
  },
  {
    id: 'citycouncil',
    name: 'City Council',
    latitude: 32.796821,
    longitude: -96.797184
  },
  {
    id: 'citytavern',
    name: 'City Tavern',
    latitude: 32.781368,
    longitude: -96.799023
  },
  {
    id: 'cockandbull',
    name: 'The Cock & Bull',
    latitude: 32.813014,
    longitude: -96.753574
  },
  {
    id: 'cbc',
    name: 'Cold Beer Company',
    latitude: 32.785528,
    longitude: -96.774478
  },
  {
    id: 'commontable',
    name: 'The Common Table',
    latitude: 32.798176,
    longitude: -96.806216
  },
  {
    id: 'communitybeer',
    name: 'Community Beer Company',
    latitude: 32.791416,
    longitude: -96.816038
  },
  {
    id: 'cookw',
    name: 'Cook Hall at the W Hotel',
    latitude: 32.788520,
    longitude: -96.808906
  },
  {
    id: 'cork',
    name: 'Cork Wine Bar',
    latitude: 32.807177,
    longitude: -96.796344
  },
  {
    id: 'irishman',
    name: 'The Crafty Irishman',
    latitude: 32.81160,
    longitude: -96.796138
  },
  {
    id: 'cru',
    name: 'Cru',
    latitude: 32.807816,
    longitude: -96.798413
  },
  {
    id: 'dakota',
    name: 'Dakota\'s',
    latitude: 32.785035,
    longitude: -96.800662
  },
  {
    id: 'dalat',
    name: 'DaLat',
    latitude: 32.812585,
    longitude: -96.78405
  },
  {
    id: 'deepellumbrewing',
    name: 'Deep Ellum Brewing Company',
    latitude: 32.780790,
    longitude: -96.781642
  },
  {
    id: 'deepsushi',
    name: 'Deep Sushi',
    latitude: 32.784175,
    longitude: -96.785580
  },
  {
    id: 'delfrisco',
    name: 'Del Frisco\'s Steakhouse',
    latitude: 32.791470,
    longitude: -96.803686
  },
  {
    id: 'delfriscogrille',
    name: 'Del Frisco\'s Grille',
    latitude: 32.803845,
    longitude: -96.799370
  },
  {
    id: 'desperado',
    name: 'Desperado\'s',
    latitude: 32.846195,
    longitude: -96.769452
  },
  {
    id: 'dodies',
    name: 'Dodie\'s',
    latitude: 32.823327,
    longitude: -96.770367
  },
  {
    id: 'doublewide',
    name: 'Double Wide',
    latitude: 32.785110,
    longitude: -96.773725
  },
  {
    id: 'doughbro',
    name: 'Dough Bros',
    latitude: 32.968909,
    longitude: -96.820466
  },
  {
    id: 'dubliner',
    name: 'Dubliner',
    latitude: 32.823561,
    longitude: -96.769913
  },
  {
    id: 'ebar',
    name: 'E Bar Tex Mex',
    latitude: 32.801301,
    longitude: -96.785792
  },
  {
    id: 'eastwood',
    name: 'Eastwood\'s Bar',
    latitude: 32.805401,
    longitude: -96.798928
  },
  {
    id: 'eddiev',
    name: 'Eddie V\'s',
    latitude: 32.818066,
    longitude: -96.802663
  },
  {
    id: 'bolero',
    name: 'El Bolero Cocina',
    latitude: 32.793335,
    longitude: -96.823178
  },
  {
    id: 'elfenix',
    name: 'El Fenix (Uptown)',
    latitude: 32.787390,
    longitude: -96.804859
  },
  {
    id: 'elfenix',
    name: 'El Fenix (Preston Hollow)',
    latitude: 32.829346,
    longitude: -96.825101
  },
  {
    id: 'vecion',
    name: 'El Vecino',
    latitude: 32.845399,
    longitude: -96.711386
  },
  {
    id: 'enopizza',
    name: 'Eno\'s Pizza Tavern',
    latitude: 32.748409,
    longitude: -96.828468
  },
  {
    id: 'eureka',
    name: 'Eureka!',
    latitude: 32.808414,
    longitude: -96.79602
  },
  {
    id: 'fearing',
    name: 'Fearing\'s Restaurant',
    latitude: 32.792138,
    longitude: -96.803520
  },
  {
    id: 'fernando',
    name: 'Fernando\'s Mexican Cuisine',
    latitude: 32.866069,
    longitude: -96.836973
  },
  {
    id: 'ferriswheel',
    name: 'Ferris Wheeler\'s Backyard BBQ',
    latitude: 32.797552,
    longitude: -96.824323
  },
  {
    id: 'fishcity',
    name: 'Fish City Grill',
    latitude: 32.893632,
    longitude: -96.802391
  },
  {
    id: 'fivesixty',
    name: 'Five Sixty',
    latitude: 32.774981,
    longitude: -96.809609
  },
  {
    id: 'florast',
    name: 'Flora Street Cafe',
    latitude: 32.789515,
    longitude: -96.797697
  },
  {
    id: 'flyingsaucer',
    name: 'Flying Saucer',
    latitude: 32.952839,
    longitude: -96.818876
  },
  {
    id: 'foudationhob',
    name: 'Foundation Room (HOB)',
    latitude: 32.784941,
    longitude: -96.808373
  },
  {
    id: 'foundrychicken',
    name: 'The Foundry/Chicken Scratch',
    latitude: 32.772418,
    longitude: -96.831753
  },
  {
    id: 'fourcorners',
    name: 'Four Corners Brewing Company',
    latitude: 32.772082,
    longitude: -96.791786
  },
  {
    id: 'frankie',
    name: 'Frankie\'s Downtown',
    latitude: 32.780478,
    longitude: -96.800694
  },
  {
    id: 'frenchroom',
    name: 'The French Room',
    latitude: 32.780040,
    longitude: -96.799714
  },
  {
    id: 'frontroom',
    name: 'Front Room Tavern',
    latitude: 32.841025,
    longitude: -96.787333
  },
  {
    id: 'gatorsdowntown',
    name: 'Gator\'s Downtown',
    latitude: 32.781978,
    longitude: -96.806697
  },
  {
    id: 'gemma',
    name: 'Gemma',
    latitude: 32.813873,
    longitude: -96.777922
  },
  {
    id: 'genghis',
    name: 'Genghis Grill',
    latitude: 32.852706,
    longitude: -96.768500
  },
  {
    id: 'ginger',
    name: 'The Ginger Man',
    latitude: 32.798259,
    longitude: -96.802969
  },
  {
    id: 'glorias',
    name: 'Gloria\'s (Addison)',
    latitude: 32.951879,
    longitude: -96.822094
  },
  {
    id: 'glorias',
    name: 'Gloria\'s (Greenville)',
    latitude: 32.8323335,
    longitude: -96.770283
  },
  {
    id: 'glorias',
    name: 'Gloria\'s (Lemmon)',
    latitude: 32.807129,
    longitude: -96.800427
  },
  {
    id: 'glorias',
    name: 'Gloria\'s (Oak Cliff)',
    latitude: 32.749605,
    longitude: -96.827539
  },
  {
    id: 'glorias',
    name: 'Gloria\'s (Uptown)',
    latitude: 32.807135,
    longitude: -96.800059
  },
  {
    id: 'goat',
    name: 'The Goat',
    latitude: 32.812397,
    longitude: -96.731986
  },
  {
    id: 'go4it',
    name: 'Go 4 It Sports Grill',
    latitude: 32.865298,
    longitude: -96.701160
  },
  {
    id: 'goodfriend',
    name: 'Goodfriend Beer Garden',
    latitude: 32.839464,
    longitude: -96.696681
  },
  {
    id: 'grape',
    name: 'The Grape',
    latitude: 32.823343,
    longitude: -96.769987
  },
  {
    id: 'grapevinebar',
    name: 'The Grapevine Bar',
    latitude: 32.805327,
    longitude: -96.81506
  },
  {
    id: 'greendoor',
    name: 'Green Door Public House',
    latitude: 32.778638,
    longitude: -96.79187
  },
  {
    id: 'grillalley',
    name: 'The Grill on the Alley',
    latitude: 32.928892,
    longitude: -96.820346
  },
  {
    id: 'groveharwood',
    name: 'The Grove at Harwood',
    latitude: 32.796603,
    longitude: -96.810316
  },
  {
    id: 'gui',
    name: 'Gui Sushi',
    latitude: 32.798455,
    longitude: -96.801391
  },
  {
    id: 'gungho',
    name: 'Gung Ho',
    latitude: 32.814145,
    longitude: -96.769947
  },
  {
    id: 'halcyon',
    name: 'Halcyon',
    latitude: 32.824000,
    longitude: -96.769934
  },
  {
    id: 'happiest',
    name: 'Happiest Hour',
    latitude: 32.790994,
    longitude: -96.806644
  },
  {
    id: 'harlowe',
    name: 'Harlowe MXM',
    latitude: 32.784302,
    longitude: -96.782472
  },

  {
    id: 'henrymajestic',
    name: 'Henry\'s Majestic',
    latitude: 32.826870,
    longitude: -96.784786
  },
  {
    id: 'hgsply',
    name: 'HG Sply Co',
    latitude: 32.814172,
    longitude: -96.769782
  },
  {
    id: 'hide',
    name: 'Hide',
    latitude: 32.784643,
    longitude: -96.782904
  },
  {
    id: 'highfive',
    name: 'High Fives',
    latitude: 32.811773,
    longitude: -96.774401
  },
  {
    id: 'hopdoddy',
    name: 'Hopdoddy (Park Cities)',
    latitude: 32.863386,
    longitude: -96.806733
  },
  {
    id: 'hopdoddy',
    name: 'Hopdoddy (Uptown)',
    latitude: 32.804385,
    longitude: -96.799689
  },
  {
    id: 'houndstooth',
    name: 'Houndstooth Coffee',
    latitude: 32.769120,
    longitude: -96.835447
  },
  {
    id: 'wang',
    name: 'Howard Wang\'s',
    latitude: 32.864721,
    longitude: -96.835900
  },
  {
    id: 'hudsonhouse',
    name: 'Hudson House',
    latitude: 32.851376,
    longitude: -96.811727
  },
  {
    id: 'illminster',
    name: 'Ill Minster Pub',
    latitude: 32.799977,
    longitude: -96.804674
  },
  {
    id: 'imoto',
    name: 'Imoto',
    latitude: 32.787481,
    longitude: -96.809026
  },
  {
    id: 'indiapalace',
    name: 'India Palace',
    latitude: 32.923182,
    longitude: -96.804439
  },
  {
    id: 'inwoodlounge',
    name: 'Inwood Lounge',
    latitude: 32.850499,
    longitude: -96.82163
  },
  {
    id: 'intavern',
    name: 'Inwood Tavern',
    latitude: 32.850119,
    longitude: -96.821619
  },
  {
    id: 'ironcactus',
    name: 'Iron Cactus',
    latitude: 32.780490,
    longitude: -96.798599
  },
  {
    id: 'ivykitchen',
    name: 'Ivy Kitchen',
    latitude: 32.954620,
    longitude: -96.812793
  },
  {
    id: 'ivytavern',
    name: 'Ivy Tavern',
    latitude: 32.826869,
    longitude: -96.822608
  },
  {
    id: 'izkina',
    name: 'Izkina',
    latitude: 32.784837,
    longitude: -96.783454
  },
  {
    id: 'jalisco',
    name: 'Jalisco Norte',
    latitude: 32.814525,
    longitude: -96.800876
  },
  {
    id: 'katyice',
    name: 'Katy Trail Ice House',
    latitude: 32.800761,
    longitude: -96.807528
  },
  {
    id: 'komali',
    name: 'Komali',
    latitude: 32.816037,
    longitude: -96.792306
  },
  {
    id: 'knife',
    name: 'Knife Dallas',
    latitude: 32.836186,
    longitude: -96.778047
  },
  {
    id: 'kona',
    name: 'Kona Grill',
    latitude: 32.869417,
    longitude: -96.773440
  },
  {
    id: 'kungfusaloon',
    name: 'Kung Fu Saloon',
    latitude: 32.799140,
    longitude: -96.805205
  },
  {
    id: 'lakehouse',
    name: 'Lake House Bar and Grill',
    latitude: 32.854776,
    longitude: -96.732076
  },
  {
    id: 'lasalmas',
    name: 'Las Almas Rotas',
    latitude: 32.781837,
    longitude: -96.766616
  },
  {
    id: 'leeharvey',
    name: 'Lee Harvey\'s',
    latitude: 32.768046,
    longitude: -96.789365
  },
  {
    id: 'libertine',
    name: 'Libertine Bar',
    latitude: 32.815576,
    longitude: -96.770388
  },
  {
    id: 'warwicklibrary',
    name: 'The Library (Warwick Melrose)',
    latitude: 32.809049,
    longitude: -96.809272
  },
  {
    id: 'littlekatana',
    name: 'Little Katana',
    latitude: 32.822489,
    longitude: -96.789695
  },
  {
    id: 'lizard',
    name: 'The Lizard Lounge',
    latitude: 32.784849,
    longitude: -96.790228
  },
  {
    id: 'lot',
    name: 'The Lot',
    latitude: 32.810338,
    longitude: -96.729252
  },
  {
    id: 'londoner',
    name: 'The Londoner',
    latitude: 32.952368,
    longitude: -96.838416
  },
  {
    id: 'lounge31',
    name: 'Lounge 31',
    latitude: 32.835855,
    longitude: -96.805485
  },
  {
    id: 'malai',
    name: 'Malai Kitchen',
    latitude: 32.808674,
    longitude: -96.796897
  },
  {
    id: 'malarkeys',
    name: 'Malarkey\'s Tavern',
    latitude: 32.989701,
    longitude: -96.831188
  },
  {
    id: 'mansion',
    name: 'The Mansion Bar',
    latitude: 32.804142,
    longitude: -96.807351
  },
  {
    id: 'marios',
    name: 'Mario\'s',
    latitude: 32.827409,
    longitude: -96.822847
  },
  {
    id: 'martinez',
    name: 'Matt\'s Rancho Martinez',
    latitude: 32.812889,
    longitude: -96.761413
  },
  {
    id: 'meddlesome',
    name: 'Meddlesome Moth',
    latitude: 32.796854,
    longitude: -96.820234
  },
  {
    id: 'merchant',
    name: 'Merchant House',
    latitude: 32.806305,
    longitude: -96.816201
  },
  {
    id: 'rambler',
    name: 'Midnight Rambler',
    latitude: 32.780616,
    longitude: -96.798320
  },
  {
    id: 'mirador',
    name: 'Mirador',
    latitude: 32.781072,
    longitude: -96.797922
  },
  {
    id: 'morton',
    name: 'Morton\'s The Steakhouse',
    latitude: 32.793172,
    longitude: -96.802420
  },
  {
    id: 'mucky',
    name: 'The Mucky Duck',
    latitude: 32.961764,
    longitude: -96.823319
  },
  {
    id: 'mutts',
    name: 'MUTTS Canine Cantina',
    latitude: 32.806033,
    longitude: -96.795114
  },
  {
    id: 'namo',
    name: 'Namo',
    latitude: 32.808112,
    longitude: -96.797925
  },
  {
    id: 'nickandsam',
    name: 'Nick & Sam\'s Grill',
    latitude: 32.798484,
    longitude: -96.807407
  },
  {
    id: 'nickelrye',
    name: 'Nickel & Rye',
    latitude: 32.796035,
    longitude: -96.802022
  },
  {
    id: 'nobu',
    name: 'Nobu Dallas',
    latitude: 32.794220,
    longitude: -96.803998
  },
  {
    id: 'nodding',
    name: 'The Nodding Donkey',
    latitude: 32.796580,
    longitude: -96.796937
  },
  {
    id: 'nola',
    name: 'NOLA Brasserie',
    latitude: 32.780229,
    longitude: -96.802044
  },
  {
    id: 'oak',
    name: 'Oak',
    latitude: 32.797204,
    longitude: -96.819324
  },
  {
    id: 'oceanprime',
    name: 'Ocean Prime',
    latitude: 32.794104,
    longitude: -96.806402
  },
  {
    id: 'offrecord',
    name: 'Off the Record',
    latitude: 32.784414,
    longitude: -96.783998
  },
  {
    id: 'oldchicago',
    name: 'Old Chicago Pizza & Taproom',
    latitude: 32.838454,
    longitude: -96.776667
  },
  {
    id: 'oldmonk',
    name: 'Old Monk',
    latitude: 32.820025,
    longitude: -96.784913
  },
  {
    id: 'oneeyed',
    name: 'One Eyed Penguin',
    latitude: 32.780226,
    longitude: -96.800005
  },
  {
    id: 'ontheborder',
    name: 'On the Border',
    latitude: 32.823303,
    longitude: -96.788542
  },
  {
    id: 'openingbell',
    name: 'Opening Bell',
    latitude: 32.767245,
    longitude: -96.796046
  },
  {
    id: 'originkitchen',
    name: 'Origin Kitchen + Bar',
    latitude: 32.820531,
    longitude: -96.788374
  },
  {
    id: 'ozona',
    name: 'Ozona',
    latitude: 32.843593,
    longitude: -96.770897
  },
  {
    id: 'pakpao',
    name: 'Pakpao Thai Food',
    latitude: 32.797172,
    longitude: -96.819343
  },
  {
    id: 'parliament',
    name: 'Parliament',
    latitude: 32.797830,
    longitude: -96.798404
  },
  {
    id: 'paulmartin',
    name: 'Paul Martin\'s American Grill',
    latitude: 32.815042,
    longitude: -96.801842
  },
  {
    id: 'laststand',
    name: 'The People\'s Last Stand',
    latitude: 32.838293,
    longitude: -96.776158
  },
  {
    id: 'perle',
    name: 'Perle on Maple',
    latitude: 32.797611,
    longitude: -96.807348
  },
  {
    id: 'perryssteak',
    name: 'Perry\'s Steakhouse & Grille',
    latitude: 32.791097,
    longitude: -96.803187
  },
  {
    id: 'peticolas',
    name: 'Peticolas Brewing Co',
    latitude: 32.796705,
    longitude: -96.828966
  },
  {
    id: 'phd',
    name: 'PhD',
    latitude: 32.749214,
    longitude: -96.843169
  },
  {
    id: 'pinot',
    name: 'Pinot\'s Palette (Addison)',
    latitude: 32.953076,
    longitude: -96.816389
  },
  {
    id: 'pinot',
    name: 'Pinot\'s Palette (E Mockingbird)',
    latitude: 32.837123,
    longitude: -96.751789
  },
  {
    id: 'pinot',
    name: 'Pinot\'s Palette (W Mockingbird)',
    latitude: 32.835953,
    longitude: -96.828390
  },
  {
    id: 'pizzeriatesta',
    name: 'Pizzaria Testa',
    latitude: 32.83059,
    longitude: -96.770428
  },
  {
    id: 'playground',
    name: 'Playground Bar',
    latitude: 32.799952,
    longitude: -96.800587
  },
  {
    id: 'pluckers',
    name: 'Pluckers Wing Bar',
    latitude: 32.852804,
    longitude: -96.768291
  },
  {
    id: 'pressbox',
    name: 'Press Box Grill',
    latitude: 32.781681,
    longitude: -96.797399
  },
  {
    id: 'prophet',
    name: 'The Prophet Bar',
    latitude: 32.783755,
    longitude: -96.787092
  },
  {
    id: 'pyramid',
    name: 'Pyramid Restaurant & Lobby Bar',
    latitude: 32.785823,
    longitude: -96.801953
  },
  {
    id: 'quarterbar',
    name: 'Quarter Bar Uptown',
    latitude: 32.804739,
    longitude: -96.799367
  },
  {
    id: 'rustytaco',
    name: 'Rusty Taco (Northeast)',
    latitude: 32.845838,
    longitude: -96.769642
  },
  {
    id: 'rustytaco',
    name: 'Rusty Taco (Oak Lawn)',
    latitude: 32.812745,
    longitude: -96.804374
  },
  {
    id: 'rdkitchen',
    name: 'R+D Kitchen',
    latitude: 32.863278,
    longitude: -96.802513
  },
  {
    id: 'rapscallion',
    name: 'Rapscallion',
    latitude: 32.815173,
    longitude: -96.770420
  },
  {
    id: 'rattlesnakeritz',
    name: 'Rattlesnake Bar (Ritz-Carlton)',
    latitude: 32.792400,
    longitude: -96.803775
  },
  {
    id: 'ravenna',
    name: 'Ravenna Italian Grill & Bar',
    latitude: 32.780029,
    longitude: -96.801101
  },
  {
    id: 'redlobster',
    name: 'Red Lobster',
    latitude: 32.862856,
    longitude: -96.896539
  },
  {
    id: 'rjmexican',
    name: 'RJ Mexican Cuisine',
    latitude: 32.781618,
    longitude: -96.807053
  },
  {
    id: 'roccos',
    name: 'Rocco\'s Uptown',
    latitude: 32.799276,
    longitude: -96.802509
  },
  {
    id: 'rodeogoat',
    name: 'Rodeo Goat',
    latitude: 32.797177,
    longitude: -96.823911
  },
  {
    id: 'roundupsaloon',
    name: 'Round Up Saloon',
    latitude: 32.810711,
    longitude: -96.810413
  },
  {
    id: 'ruthschris',
    name: 'Ruth\'s Chris Steakhouse (Addison)',
    latitude: 32.993255,
    longitude: -96.828526
  },
  {
    id: 'ruthschris',
    name: 'Ruth\'s Chris Steakhouse (Uptown)',
    latitude: 32.790540,
    longitude: -96.804841
  },
  {
    id: 'sablon',
    name: 'Sablon Chocolate Lounge',
    latitude: 32.808988,
    longitude: -96.795726
  },
  {
    id: 'saintanne',
    name: 'Saint Ann',
    latitude: 32.791491,
    longitude: -96.806250
  },
  {
    id: 'savor',
    name: 'Savor Gastropub',
    latitude: 32.789408,
    longitude: -96.802014
  },
  {
    id: 'scout',
    name: 'Scout',
    latitude: 32.780132,
    longitude: -96.795073
  },
  {
    id: 'season52',
    name: 'Season\'s 52',
    latitude: 32.866905,
    longitude: -96.773666
  },
  {
    id: 'secondfloor',
    name: 'The Second Floor',
    latitude: 32.929859,
    longitude: -96.820452
  },
  {
    id: 'ser',
    name: 'SER Steak & Spirits',
    latitude: 32.800412,
    longitude: -96.830250
  },
  {
    id: 'shuck',
    name: 'Shuck n\' Jive',
    latitude: 32.851402,
    longitude: -96.770385
  },
  {
    id: 'sitapas',
    name: 'Si Tapas',
    latitude: 32.796019,
    longitude: -96.796702
  },
  {
    id: 'singlewide',
    name: 'Single Wide',
    latitude: 32.815233,
    longitude: -96.769971
  },
  {
    id: 'skellig',
    name: 'Skellig',
    latitude: 32.815058,
    longitude: -96.778942
  },
  {
    id: 'slaters',
    name: 'Slater\'s 50/50 (Greenville)',
    latitude: 32.823288,
    longitude: -96.770413
  },
  {
    id: 'slaters',
    name: 'Slater\'s 50/50 (Preston)',
    latitude: 32.907130,
    longitude: -96.805918
  },
  {
    id: 'snuffers',
    name: 'Snuffer\'s (Greenville)',
    latitude: 32.831046,
    longitude: -96.769961
  },
  {
    id: 'snuffers',
    name: 'Snuffer\'s (Addison)',
    latitude: 32.953747,
    longitude: -96.839589
  },
  {
    id: 'hermannhall',
    name: 'Sons of Hermann Hall',
    latitude: 32.786156,
    longitude: -96.775613
  },
  {
    id: 'dancingmarlin',
    name: 'St Pete\'s Dancing Marlin',
    latitude: 32.782962,
    longitude: -96.783294
  },
  {
    id: 'stansblue',
    name: 'Stan\'s Blue Note',
    latitude: 32.824196,
    longitude: -96.769922
  },
  {
    id: 'standardpour',
    name: 'The Standard Pour',
    latitude: 32.799689,
    longitude: -96.800687
  },
  {
    id: 'stateallen',
    name: 'Stat and Allen',
    latitude: 32.797511,
    longitude: -96.797985
  },
  {
    id: 'steamtheory',
    name: 'Steam Theory Brewery',
    latitude: 32.778252,
    longitude: -96.829992
  },
  {
    id: 'steelsushi',
    name: 'Steel Restaurant & Lounge',
    latitude: 32.809187,
    longitude: -96.806940
  },
  {
    id: 'stereodtx',
    name: 'Stereo Live Dallas',
    latitude: 32.859957,
    longitude: -96.879265
  },
  {
    id: 'strokers',
    name: 'Strokers Ice House',
    latitude: 32.841954,
    longitude: -96.870502
  },
  {
    id: 'stonedeck',
    name: 'Stonedeck Pizza Pub',
    latitude: 32.784319,
    longitude: -96.786037
  },
  {
    id: 'stoneleigh',
    name: 'Stoneleigh P',
    latitude: 32.798006,
    longitude: -96.806970
  },
  {
    id: 'sundowngranada',
    name: 'Sundown at the Granada',
    latitude: 32.830667,
    longitude: -96.769956
  },
  {
    id: 'sushiaxiom',
    name: 'Sushi Axiom',
    latitude: 32.954551,
    longitude: -96.817701
  },
  {
    id: 'sushizushi',
    name: 'Sushi Zushi',
    latitude: 32.807285,
    longitude: -96.796425
  },
  {
    id: 'tacolingo',
    name: 'Taco Lingo',
    latitude: 32.78936,
    longitude: -96.805831
  },
  {
    id: 'taqueriaventana',
    name: 'Taqueria La Ventana (Oak Lawn)',
    latitude: 32.809930,
    longitude: -96.810131
  },
  {
    id: 'taqueriaventana',
    name: 'Taqueria La Ventana (Victory Park)',
    latitude: 32.787789,
    longitude: -96.804959
  },
  {
    id: 'tates',
    name: 'Tate\'s',
    latitude: 32.798614,
    longitude: -96.801371
  },
  {
    id: 'tenbells',
    name: 'Ten Bells Tavern',
    latitude: 32.748746,
    longitude: -96.825993
  },
  {
    id: 'tenfifty',
    name: 'Ten50 BBQ',
    latitude: 32.963559,
    longitude: -96.723539
  },
  {
    id: 'terillis',
    name: 'Terilli\'s',
    latitude: 32.823039,
    longitude: -96.770327
  },
  {
    id: 'texasale',
    name: 'Texas Ale Project',
    latitude: 32.786009,
    longitude: -96.818288
  },
  {
    id: 'texastheatre',
    name: 'The Texas Theater',
    latitude: 32.743279,
    longitude: -96.825979
  },
  {
    id: 'tillmans',
    name: 'Tillman\'s Roadhouse',
    latitude: 32.748717,
    longitude: -96.827686
  },
  {
    id: 'toasted',
    name: 'Toasted Coffee + Kitchen',
    latitude: 32.810481,
    longitude: -96.770803
  },
  {
    id: 'tot',
    name: 'Time Out Tavern (The TOT)',
    latitude: 32.851380,
    longitude: -96.823923
  },
  {
    id: 'timesten',
    name: 'Times Ten Cellars',
    latitude: 32.814807,
    longitude: -96.753967
  },
  {
    id: 'tinyvictories',
    name: 'Tiny Victories',
    latitude: 32.749763,
    longitude: -96.837504
  },
  {
    id: 'tjseafood',
    name: 'TJ\'s Seafood Market',
    latitude: 32.819909,
    longitude: -96.801976
  },
  {
    id: 'torchys',
    name: 'Torchy\'s Tacos (Park Cities)',
    latitude: 32.841789,
    longitude: -96.771391
  },
  {
    id: 'torchys',
    name: 'Torchy\'s Tacos (White Rock)',
    latitude: 32.832220,
    longitude: -96.700709
  },
  {
    id: 'torchys',
    name: 'Torchy\'s Tacos (Preston Hollow)',
    latitude: 32.909911,
    longitude: -96.805887
  },
  {
    id: 'torchys',
    name: 'Torchy\'s Tacos (Las Colinas)',
    latitude: 32.912928,
    longitude: -96.953327
  },
  {
    id: 'toulouse',
    name: 'Toulouse',
    latitude: 32.824093,
    longitude: -96.790853
  },
  {
    id: 'trickyfish',
    name: 'Tricky Fish',
    latitude: 33.001602,
    longitude: -96.700905
  },
  {
    id: 'trinitycider',
    name: 'Trinity Cider',
    latitude: 32.783602,
    longitude: -96.784212
  },
  {
    id: 'trinityhall',
    name: 'Trinity Hall Irish Pub',
    latitude: 32.838100,
    longitude: -96.775674
  },
  {
    id: 'trulucks',
    name: 'Truluck\'s',
    latitude: 32.794845,
    longitude: -96.803024
  },
  {
    id: 'tulum',
    name: 'Tulum',
    latitude: 32.820170,
    longitude: -96.801987
  },
  {
    id: 'tupinamba',
    name: 'Tupinamba',
    latitude: 32.878299,
    longitude: -96.77136
  },
  {
    id: 'peaks',
    name: 'Twin Peaks',
    latitude: 32.838135,
    longitude: -96.775662
  },
  {
    id: 'uchiba',
    name: 'Uchiba',
    latitude: 32.796781,
    longitude: -96.806562
  },
  {
    id: 'uncleuber',
    name: 'Uncle Uber\'s Sammich Shop',
    latitude: 32.783335,
    longitude: -96.783414
  },
  {
    id: 'unionpark',
    name: 'Union Park',
    latitude: 32.961963,
    longitude: -96.822983
  },
  {
    id: 'uptownpub',
    name: 'Uptown Pub',
    latitude: 32.807320,
    longitude: -96.797689
  },
  {
    id: 'urbantaco',
    name: 'Urban Taco',
    latitude: 32.837618,
    longitude: -96.775691
  },
  {
    id: 'v12',
    name: 'V12 Yoga',
    latitude: 32.778668,
    longitude: -96.791827
  },
  {
    id: 'veritas',
    name: 'Veritas Wine Room',
    latitude: 32.814056,
    longitude: -96.778234
  },
  {
    id: 'vetted',
    name: 'Vetted Well',
    latitude: 32.769890,
    longitude: -96.799549
  },
  {
    id: 'vidorra',
    name: 'Vidorra',
    latitude: 32.783384,
    longitude: -96.784646
  },
  {
    id: 'villageburger',
    name: 'Village Burger Bar',
    latitude: 32.788977,
    longitude: -96.803886
  },
  {
    id: 'watergrill',
    name: 'Water Grill',
    latitude: 32.790411,
    longitude: -96.803380
  },
  {
    id: 'waterproof',
    name: 'Waterproof',
    latitude: 32.780595,
    longitude: -96.794927
  },
  {
    id: 'whistlingpig',
    name: 'Whistling Pig Neighborhood Pub',
    latitude: 32.812298,
    longitude: -96.698372
  },
  {
    id: 'whiterockalehouse',
    name: 'White Rock Alehouse & Brewery',
    latitude: 32.813671,
    longitude: -96.730243
  },
  {
    id: 'wilddetectives',
    name: 'The Wild Detectives',
    latitude: 32.747864,
    longitude: -96.827242
  },
  {
    id: 'wildsalsa',
    name: 'Wild Salsa',
    latitude: 32.781175,
    longitude: -96.795776
  },
  {
    id: 'windmill',
    name: 'Windmill Lounge',
    latitude: 32.818193,
    longitude: -96.832372
  },
  {
    id: 'woodlands',
    name: 'Woodlands American Grill',
    latitude: 32.909817,
    longitude: -96.801792
  },
  {
    id: 'yoranch',
    name: 'YO Steakhouse',
    latitude: 32.781343,
    longitude: -96.806554
  },
  {
    id: 'ziziki',
    name: 'Ziziki\'s (Knox-Henderson)',
    latitude: 32.822083,
    longitude: -96.790826
  },
  {
    id: 'ziziki',
    name: 'Ziziki\'s (Preston Hollow)',
    latitude: 32.908884,
    longitude: -96.804798
  }
];
