import { API } from 'aws-amplify';
import { ID } from './tokens';

const api = 'mydallas-portal';

const GET_ID = async (init) => {
  var path = '/dallas/map';

  try {
    var id = await API.post(api, path, init);
    var _id = JSON.parse(id.body);
    //console.log(id);

    return _id;
  } catch (e) {
    //console.log('erroe');
    //console.log(e);
    alert(e.message);
    return e;
  }
}

const CREATE_USER = async (init) => {
  var path = '/dallas';
  try {
    var create = await API.post(api, path, init);
    //console.log(JSON.parse(create.body));
    //console.log('created user');
    if (JSON.parse(create.body) === 'email') {
      alert('This email address is already being used on another account.\nPlease contact ryan@mydallasapp.com if this is a mistake.');
      return 0;
    } else if (JSON.parse(create.body) === "exists") {
      alert('There is already an administrator signed up for this location.\nPlease contact ryan@mydallasapp.com if this is a mistake.');
      return 0;
    } else if (JSON.parse(create.body) === 'success') {
      return 1;
    }
  } catch (e) {
    alert(e.message);
    return 0;
  }
}

const GET_USER = async (accountID) => {
  var auth = await ID();
  var init = {
    headers: {
      'Authorization': auth
    }
  }
  var path = '/dallas/' + accountID + '/account';
  try {
    var user = await API.get(api, path, init);
    var _user = JSON.parse(user.body).Item;
    return _user;
  } catch (e) {
    alert(e.message);
    return 0;
  }
}

const GET_SCHEDULE = async (user, _area) => {
  var auth = await ID();
  var init = {
    headers: {
      'Authorization': auth
    },
    queryStringParameters: {
      'area': _area
    }
  }
  var path = '/dallas/' + user + '/schedule';

  try {
    var schedule = await API.get(api, path, init);
    var _schedule;
    if (typeof(JSON.parse(schedule.body).Item) !== 'undefined') {
      _schedule = JSON.parse(schedule.body).Item;
    } else {
      _schedule = {
        scheduled: {}
      }
    }
    //console.log(_schedule);

    return _schedule;
  } catch (e) {
    alert(e.message);
    return 0;
  }
}

const CREATE_ITEM = async (init) => {
  var path = '/dallas/' + init.body.placeID + '/schedule';

  try {
    var result = await API.post(api, path, init);
    //console.log(result);
  } catch (e) {
    //console.log('here');
    //console.log(e);
    alert(e.response);
  }
}

const CREATE_PAYMENT = async (init) => {
  let path = '/dallas/' + init.body.accountID + '/payment';

  try {
    let result = await API.post(api, path, init);
    //console.log(result);

    let res = JSON.parse(result.body);
    //console.log(res);
    return res;
  } catch (e) {
    //console.log('fail');
    //console.log(e);
    if (e.errorMessage === 'An error occurred with our connection to Stripe.') {
      alert('Please try again. You were not charged.');
    }
    alert('Error');
    return 0;
  }
}

export {
  GET_ID,
  CREATE_USER,
  GET_USER,
  GET_SCHEDULE,
  CREATE_ITEM,
  CREATE_PAYMENT
}
