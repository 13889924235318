import React, { Component } from 'react';
import PropTypes from 'prop-types';

import './BarcodeBack.css';

// Constants

class BarcodeBack extends Component {
  constructor(props) {
    super();

  }

  render() {

    return (
      <div className={'bbContainer'}>

        <div className={'bbH'}>
          <h4>
            {this.props.info.h}
          </h4>
        </div>

        <div className={'bbDetails'}>
          <h5>
            {this.props.info.detailsL1}
            <br/>
            {this.props.info.detailsL2}
            <br/>
            {this.props.info.detailsL3}
          </h5>
        </div>

        <div className={'bbBarcode-outer'}>
          <div className={'bbBarcode-inner'}>
            <img
              className={'img-fluid'}
              src={require('../../../assets/images/barcode.png')}
              alt={'Sample Barcode'}
            />
          </div>
        </div>

        {
          ((this.props.which === 'event') && (this.props.info.link !== '0')) ?
          <div className={'bbLink'}>
            <h4>{'Go To Event Site'}</h4>
          </div> : null
        }
      </div>
    );
  }
}

BarcodeBack.propTypes = {
  which: PropTypes.oneOf(['deal', 'event']).isRequired,
  info: PropTypes.any.isRequired
}

export default BarcodeBack;
