import React, { Component, Fragment } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import { addWeeks, differenceInWeeks } from 'date-fns';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';

import { fill } from '../../../assets/globalStyles';

import './InputInfo.css';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-datepicker/dist/react-datepicker-cssmodules.css';

import { DIMGS, EIMGS } from '../SelectBack/images';
import SingleFront from '../../../assets/components/SingleFront/SingleFront';
import SingleBack from '../../../assets/components/SingleBack/SingleBack';
import DoubleFront from '../../../assets/components/DoubleFront/DoubleFront';
import DoubleBack from '../../../assets/components/DoubleBack/DoubleBack';
import BarcodeFront from '../../../assets/components/BarcodeFront/BarcodeFront';
import BarcodeBack from '../../../assets/components/BarcodeBack/BarcodeBack';

const CATEGORIES = {
  deal: [
    ['brunch', 'Brunch'],
    ['lunch', 'Lunch'],
    ['happy', 'Happy Hour'],
    ['dinner', 'Dinner'],
    ['late', 'Late Night'],
    ['retail', 'Retail']
  ],
  event: [
    ['dancing', 'Dancing'],
    ['family', 'Family'],
    ['fitness', 'Fitness'],
    ['karaoke', 'Karaoke'],
    ['music', 'Music'],
    ['sports', 'Sports'],
    ['trivia', 'Trivia'],
    ['misc', 'Miscellaneous']
  ]
}

const IMGS = {
  deal: DIMGS,
  event: EIMGS
}

// Constants

class InputInfo extends Component {
  constructor(props) {
    super(props);
    var _enddate = (this.props.mainState.user.subLength === 'free') ? new Date(2019, 8, 1) : addWeeks(new Date(), 12);
    this.state = {
      enddate: _enddate,
      weeksout: 1,
      valid: false,
      name: 'Business Name',
      name1: 'Name Line 1',
      name2: 'Name Line 2',
      date: '',
      startTime: '',
      endTime: '',
      day: '',
      month: '',
      year: '',
      hours: '6 a.m. - 6 p.m.',
      start: {
        hour: 0,
        min: 0
      },
      end: {
        hour: 0,
        min: 0
      },
      title: this.props.createState.mainObject.layout === 'barcode' ? 'myDallas Exclusive!' : 'Title Goes Here',
      title1: this.props.createState.mainObject.layout === 'barcode' ? 'myDallas' : 'Title Line 1',
      title2: this.props.createState.mainObject.layout === 'barcode' ? 'Exclusive' : 'Title Line 2',
      h: 'Heading Here',
      h1: 'Heading 1 Here',
      h2: 'Heading 2 Here',
      details: '',
      detailsL1: 'Details Line 1',
      detailsL2: 'Details Line 2',
      detailsL3: 'Details Line 3',
      detailsL4: '',
      detailsL5: 'Details Line 5',
      detailsL6: 'Details Line 6',
      detailsL7: '',
      details1: '',
      details11: 'Details 1 Line 1',
      details12: 'Details 1 Line 2',
      details13: 'Details 1 Line 3',
      details2: '',
      details21: 'Details 2 Line 1',
      details22: 'Details 2 Line 2',
      details23: 'Details 2 Line 3',
      link: '',
      recurring: '',
      barcode: '',
      url: '0'
    }
  }

  render() {

    var _layouts = {
      event: {
        single:
          <Fragment>
            <Form.Row>
              <Form.Group as={Col} controlId={'title1'}>
                <Form.Label className={'inputLabel'}>
                  Title Line 1
                </Form.Label>
                <Form.Control
                  onChange={this.handleChange}
                  type={'text'}
                  maxLength={'17'}
                  value={this.state.title1}
                />
              <Form.Text className={'inputLabel'}>Characters remaining: {17 - this.state.title1.length}</Form.Text>
              </Form.Group>

              <Form.Group as={Col} controlId={'title2'}>
                <Form.Label className={'inputLabel'}>
                  Title Line 2 (optional)
                </Form.Label>
                <Form.Control
                  onChange={this.handleChange}
                  type={'text'}
                  maxLength={17}
                  value={this.state.title2}
                />
              <Form.Text className={'inputLabel'}>Characters remaining: {17 - this.state.title2.length}</Form.Text>
              </Form.Group>

              <Form.Group as={Col} controlId={'h'}>
                <Form.Label className={'inputLabel'}>
                  Heading
                </Form.Label>
                <Form.Control
                  onChange={this.handleChange}
                  type={'text'}
                  maxLength={'30'}
                  value={this.state.h}
                />
              <Form.Text className={'inputLabel'}>Characters remaining: {30 - this.state.h.length}</Form.Text>
              </Form.Group>
            </Form.Row>

            <Form.Group controlId={'detailsL1'}>
              <Form.Label className={'inputLabel'}>
                Details Line 1
              </Form.Label>
              <Form.Control
                onChange={this.handleChange}
                type={'text'}
                maxLength={'36'}
                value={this.state.detailsL1}
              />
            <Form.Text className={'inputLabel'}>Leave blank for empty line. Characters remaining: {36 - this.state.detailsL1.length}</Form.Text>
            </Form.Group>
            <Form.Group controlId={'detailsL2'}>
              <Form.Label className={'inputLabel'}>
                Details Line 2
              </Form.Label>
              <Form.Control
                onChange={this.handleChange}
                type={'text'}
                maxLength={'36'}
                value={this.state.detailsL2}
              />
            <Form.Text className={'inputLabel'}>Leave blank for empty line. Characters remaining: {36 - this.state.detailsL2.length}</Form.Text>
            </Form.Group>
            <Form.Group controlId={'detailsL3'}>
              <Form.Label className={'inputLabel'}>
                Details Line 3
              </Form.Label>
              <Form.Control
                onChange={this.handleChange}
                type={'text'}
                maxLength={'36'}
                value={this.state.detailsL3}
              />
            <Form.Text className={'inputLabel'}>Leave blank for empty line. Characters remaining: {36 - this.state.detailsL3.length}</Form.Text>
            </Form.Group>
            <Form.Group controlId={'detailsL4'}>
              <Form.Label className={'inputLabel'}>
                Details Line 4
              </Form.Label>
              <Form.Control
                onChange={this.handleChange}
                type={'text'}
                maxLength={'36'}
                value={this.state.detailsL4}
              />
            <Form.Text className={'inputLabel'}>Leave blank for empty line. Characters remaining: {36 - this.state.detailsL4.length}</Form.Text>
            </Form.Group>
            <Form.Group controlId={'detailsL5'}>
              <Form.Label className={'inputLabel'}>
                Details Line 5
              </Form.Label>
              <Form.Control
                onChange={this.handleChange}
                type={'text'}
                maxLength={'36'}
                value={this.state.detailsL5}
              />
            <Form.Text className={'inputLabel'}>Leave blank for empty line. Characters remaining: {36 - this.state.detailsL5.length}</Form.Text>
            </Form.Group>
            <Form.Group controlId={'detailsL6'}>
              <Form.Label className={'inputLabel'}>
                Details Line 6
              </Form.Label>
              <Form.Control
                onChange={this.handleChange}
                type={'text'}
                maxLength={'36'}
                value={this.state.detailsL6}
              />
            <Form.Text className={'inputLabel'}>Leave blank for empty line. Characters remaining: {36 - this.state.detailsL6.length}</Form.Text>
            </Form.Group>
            <Form.Group controlId={'detailsL7'}>
              <Form.Label className={'inputLabel'}>
                Details Line 7
              </Form.Label>
              <Form.Control
                onChange={this.handleChange}
                type={'text'}
                maxLength={'36'}
                value={this.state.detailsL7}
              />
            <Form.Text className={'inputLabel'}>Leave blank for empty line. Characters remaining: {36 - this.state.detailsL7.length}</Form.Text>
            </Form.Group>

            <Form.Group controlId={'link'}>
              <Form.Label className={'inputLabel'}>
                Link (optional)
              </Form.Label>
              <Form.Control
                onChange={this.handleChange}
                onPaste={this.handleChange}
                value={this.state.link}
                type={'text'}
              />
            <Form.Text className={'inputLabel'}>Please use full web address including 'http://' or 'https://'</Form.Text>
            </Form.Group>
          </Fragment>,
        barcode:
          <Fragment>
            <Form.Row>
              <Form.Group as={Col} controlId={'barcode'}>
                <Form.Label className={'inputLabel'}>
                  Barcode
                </Form.Label>
                <Form.Control as={'select'} ref={ obj => this.barcode = obj }>
                  <option>Select A File ...</option>
                  {
                    this.props.createState.mainObject.barcodes.map((code, i) => {
                      return(
                        <option>{code}</option>
                      )
                    })
                  }
                </Form.Control>
              </Form.Group>

              <Form.Group as={Col} controlId={'h'}>
                <Form.Label className={'inputLabel'}>
                  Heading
                </Form.Label>
                <Form.Control
                  onChange={this.handleChange}
                  type={'text'}
                  maxLength={'30'}
                  value={this.state.h}
                />
              <Form.Text className={'inputLabel'}>Characters remaining: {30 - this.state.h.length}</Form.Text>
              </Form.Group>
            </Form.Row>

            <Form.Group controlId={'detailsL1'}>
              <Form.Label className={'inputLabel'}>
                Details Line 1
              </Form.Label>
              <Form.Control
                onChange={this.handleChange}
                type={'text'}
                maxLength={'36'}
                value={this.state.detailsL1}
              />
            <Form.Text className={'inputLabel'}>Leave blank for empty line. Characters remaining: {36 - this.state.detailsL1.length}</Form.Text>
            </Form.Group>
            <Form.Group controlId={'detailsL2'}>
              <Form.Label className={'inputLabel'}>
                Details Line 2
              </Form.Label>
              <Form.Control
                onChange={this.handleChange}
                type={'text'}
                maxLength={'36'}
                value={this.state.detailsL2}
              />
            <Form.Text className={'inputLabel'}>Leave blank for empty line. Characters remaining: {36 - this.state.detailsL2.length}</Form.Text>
            </Form.Group>
            <Form.Group controlId={'detailsL3'}>
              <Form.Label className={'inputLabel'}>
                Details Line 3
              </Form.Label>
              <Form.Control
                onChange={this.handleChange}
                type={'text'}
                maxLength={'36'}
                value={this.state.detailsL3}
              />
            <Form.Text className={'inputLabel'}>Leave blank for empty line. Characters remaining: {36 - this.state.detailsL3.length}</Form.Text>
            </Form.Group>

            <Form.Group controlId={'link'}>
              <Form.Label className={'inputLabel'}>
                Link (optional)
              </Form.Label>
              <Form.Control
                onChange={this.handleChange}
                onPaste={this.handleChange}
                value={this.state.link}
                type={'text'}
              />
            <Form.Text className={'inputLabel'}>Please use full web address including 'http://' or 'https://'</Form.Text>
            </Form.Group>
          </Fragment>
      },
      deal: {
        single:
          <Fragment>
            <Form.Row>
              <Form.Group as={Col} controlId={'title'}>
                <Form.Label className={'inputLabel'}>
                  Title
                </Form.Label>
                <Form.Control
                  onChange={this.handleChange}
                  type={'text'}
                  maxLength={'30'}
                  value={this.state.title}
                />
              <Form.Text className={'inputLabel'}>Characters remaining: {30 - this.state.title.length}</Form.Text>
              </Form.Group>

              <Form.Group as={Col} controlId={'h'}>
                <Form.Label className={'inputLabel'}>
                  Heading
                </Form.Label>
                <Form.Control
                  onChange={this.handleChange}
                  type={'text'}
                  maxLength={'30'}
                  value={this.state.h}
                />
              <Form.Text className={'inputLabel'}>Characters remaining: {30 - this.state.h.length}</Form.Text>
              </Form.Group>
            </Form.Row>

            <Form.Group controlId={'detailsL1'}>
              <Form.Label className={'inputLabel'}>
                Details Line 1
              </Form.Label>
              <Form.Control
                onChange={this.handleChange}
                type={'text'}
                maxLength={'36'}
                value={this.state.detailsL1}
              />
            <Form.Text className={'inputLabel'}>Leave blank for empty line. Characters remaining: {36 - this.state.detailsL1.length}</Form.Text>
            </Form.Group>
            <Form.Group controlId={'detailsL2'}>
              <Form.Label className={'inputLabel'}>
                Details Line 2
              </Form.Label>
              <Form.Control
                onChange={this.handleChange}
                type={'text'}
                maxLength={'36'}
                value={this.state.detailsL2}
              />
            <Form.Text className={'inputLabel'}>Leave blank for empty line. Characters remaining: {36 - this.state.detailsL2.length}</Form.Text>
            </Form.Group>
            <Form.Group controlId={'detailsL3'}>
              <Form.Label className={'inputLabel'}>
                Details Line 3
              </Form.Label>
              <Form.Control
                onChange={this.handleChange}
                type={'text'}
                maxLength={'36'}
                value={this.state.detailsL3}
              />
            <Form.Text className={'inputLabel'}>Leave blank for empty line. Characters remaining: {36 - this.state.detailsL3.length}</Form.Text>
            </Form.Group>
            <Form.Group controlId={'detailsL4'}>
              <Form.Label className={'inputLabel'}>
                Details Line 4
              </Form.Label>
              <Form.Control
                onChange={this.handleChange}
                type={'text'}
                maxLength={'36'}
                value={this.state.detailsL4}
              />
            <Form.Text className={'inputLabel'}>Leave blank for empty line. Characters remaining: {36 - this.state.detailsL4.length}</Form.Text>
            </Form.Group>
            <Form.Group controlId={'detailsL5'}>
              <Form.Label className={'inputLabel'}>
                Details Line 5
              </Form.Label>
              <Form.Control
                onChange={this.handleChange}
                type={'text'}
                maxLength={'36'}
                value={this.state.detailsL5}
              />
            <Form.Text className={'inputLabel'}>Leave blank for empty line. Characters remaining: {36 - this.state.detailsL5.length}</Form.Text>
            </Form.Group>
            <Form.Group controlId={'detailsL6'}>
              <Form.Label className={'inputLabel'}>
                Details Line 6
              </Form.Label>
              <Form.Control
                onChange={this.handleChange}
                type={'text'}
                maxLength={'36'}
                value={this.state.detailsL6}
              />
            <Form.Text className={'inputLabel'}>Leave blank for empty line. Characters remaining: {36 - this.state.detailsL6.length}</Form.Text>
            </Form.Group>
            <Form.Group controlId={'detailsL7'}>
              <Form.Label className={'inputLabel'}>
                Details Line 7
              </Form.Label>
              <Form.Control
                onChange={this.handleChange}
                type={'text'}
                maxLength={'36'}
                value={this.state.detailsL7}
              />
            <Form.Text className={'inputLabel'}>Leave blank for empty line. Characters remaining: {36 - this.state.detailsL7.length}</Form.Text>
            </Form.Group>
          </Fragment>,
        double:
          <Fragment>
            <Form.Row>
              <Form.Group as={Col} controlId={'title'}>
                <Form.Label className={'inputLabel'}>
                  Title
                </Form.Label>
                <Form.Control
                  onChange={this.handleChange}
                  type={'text'}
                  maxLength={'30'}
                  value={this.state.title}
                />
              <Form.Text className={'inputLabel'}>Characters remaining: {30 - this.state.title.length}</Form.Text>
              </Form.Group>

              <Form.Group as={Col} controlId={'h1'}>
                <Form.Label className={'inputLabel'}>
                  Heading 1
                </Form.Label>
                <Form.Control
                  onChange={this.handleChange}
                  type={'text'}
                  maxLength={'30'}
                  value={this.state.h1}
                />
              <Form.Text className={'inputLabel'}>Characters remaining: {30 - this.state.h1.length}</Form.Text>
              </Form.Group>

              <Form.Group as={Col} controlId={'h2'}>
                <Form.Label className={'inputLabel'}>
                  Heading 2
                </Form.Label>
                <Form.Control
                  onChange={this.handleChange}
                  type={'text'}
                  maxLength={'30'}
                  value={this.state.h2}
                />
              <Form.Text className={'inputLabel'}>Characters remaining: {30 - this.state.h2.length}</Form.Text>
              </Form.Group>
            </Form.Row>

            <Form.Group controlId={'details11'}>
              <Form.Label className={'inputLabel'}>
                Details 1 Line 1
              </Form.Label>
              <Form.Control
                onChange={this.handleChange}
                type={'text'}
                maxLength={'36'}
                value={this.state.details11}
              />
            <Form.Text className={'inputLabel'}>Leave blank for empty line. Characters remaining: {36 - this.state.details11.length}</Form.Text>
            </Form.Group>
            <Form.Group controlId={'details12'}>
              <Form.Label className={'inputLabel'}>
                Details 1 Line 2
              </Form.Label>
              <Form.Control
                onChange={this.handleChange}
                type={'text'}
                maxLength={'36'}
                value={this.state.details12}
              />
            <Form.Text className={'inputLabel'}>Leave blank for empty line. Characters remaining: {36 - this.state.details12.length}</Form.Text>
            </Form.Group>
            <Form.Group controlId={'details13'}>
              <Form.Label className={'inputLabel'}>
                Details 1 Line 3
              </Form.Label>
              <Form.Control
                onChange={this.handleChange}
                type={'text'}
                maxLength={'36'}
                value={this.state.details13}
              />
            <Form.Text className={'inputLabel'}>Leave blank for empty line. Characters remaining: {36 - this.state.details13.length}</Form.Text>
            </Form.Group>

            <Form.Group controlId={'details21'}>
              <Form.Label className={'inputLabel'}>
                Details 2 Line 1
              </Form.Label>
              <Form.Control
                onChange={this.handleChange}
                type={'text'}
                maxLength={'36'}
                value={this.state.details21}
              />
            <Form.Text className={'inputLabel'}>Leave blank for empty line. Characters remaining: {36 - this.state.details21.length}</Form.Text>
            </Form.Group>
            <Form.Group controlId={'details22'}>
              <Form.Label className={'inputLabel'}>
                Details 2 Line 2
              </Form.Label>
              <Form.Control
                onChange={this.handleChange}
                type={'text'}
                maxLength={'36'}
                value={this.state.details22}
              />
            <Form.Text className={'inputLabel'}>Leave blank for empty line. Characters remaining: {36 - this.state.details22.length}</Form.Text>
            </Form.Group>
            <Form.Group controlId={'details23'}>
              <Form.Label className={'inputLabel'}>
                Details 2 Line 3
              </Form.Label>
              <Form.Control
                onChange={this.handleChange}
                type={'text'}
                maxLength={'36'}
                value={this.state.details23}
              />
            <Form.Text className={'inputLabel'}>Leave blank for empty line. Characters remaining: {36 - this.state.details23.length}</Form.Text>
            </Form.Group>
          </Fragment>,
        barcode:
          <Fragment>
            <Form.Row>
              <Form.Group as={Col} controlId={'barcode'}>
                <Form.Label className={'inputLabel'}>
                  Barcode
                </Form.Label>
                <Form.Control as={'select'} ref={ obj => this.barcode = obj }>
                  <option>Select A File ...</option>
                  {
                    this.props.createState.mainObject.barcodes.map((code, i) => {
                      return(
                        <option>{code}</option>
                      )
                    })
                  }
                </Form.Control>
              </Form.Group>

              <Form.Group as={Col} controlId={'h'}>
                <Form.Label className={'inputLabel'}>
                  Heading
                </Form.Label>
                <Form.Control
                  onChange={this.handleChange}
                  type={'text'}
                  maxLength={'30'}
                  value={this.state.h}
                />
              <Form.Text className={'inputLabel'}>Characters remaining: {30 - this.state.h.length}</Form.Text>
              </Form.Group>
            </Form.Row>

            <Form.Group controlId={'detailsL1'}>
              <Form.Label className={'inputLabel'}>
                Details Line 1
              </Form.Label>
              <Form.Control
                onChange={this.handleChange}
                type={'text'}
                maxLength={'36'}
                value={this.state.detailsL1}
              />
            <Form.Text className={'inputLabel'}>Leave blank for empty line. Characters remaining: {36 - this.state.detailsL1.length}</Form.Text>
            </Form.Group>
            <Form.Group controlId={'detailsL2'}>
              <Form.Label className={'inputLabel'}>
                Details Line 2
              </Form.Label>
              <Form.Control
                onChange={this.handleChange}
                type={'text'}
                maxLength={'36'}
                value={this.state.detailsL2}
              />
            <Form.Text className={'inputLabel'}>Leave blank for empty line. Characters remaining: {36 - this.state.detailsL2.length}</Form.Text>
            </Form.Group>
            <Form.Group controlId={'detailsL3'}>
              <Form.Label className={'inputLabel'}>
                Details Line 3
              </Form.Label>
              <Form.Control
                onChange={this.handleChange}
                type={'text'}
                maxLength={'36'}
                value={this.state.detailsL3}
              />
            <Form.Text className={'inputLabel'}>Leave blank for empty line. Characters remaining: {36 - this.state.detailsL3.length}</Form.Text>
            </Form.Group>
          </Fragment>
      }
    }

    var weeksallowed = [];
    for (let i = 0; i < this.state.weeksout; i++) {
      let num = i + 1;
      weeksallowed.push(<option>{num}</option>);
    }

    return (
      <div style={fill}>
        <h3 className={'previewTxt inputHeader'}>
          {'*Previews are close to result in app but not exact*'}
        </h3>
        <h3 className={'previewTxt inputHeader'}>
          {'For most accurate preview, resize browser window so that the front preview fills the black box'}
        </h3>
        <h3 className={'previewTxt inputHeader'}>
          {'After submission, you will still be able to edit the fields for Heading and Details'}
        </h3>
        <br/>
        <h4 className={'helpTxt inputVideo'} onClick={() => this.props.playVideo('details')}>Input Tutorial</h4>

        <div className={'inputContainer'}>
          <div className={'inputForm'}>
            <Form onSubmit={e => this.handleSubmit(e)}>
              <Form.Row>
                {
                  this.props.createState.mainObject.which === 'event' ?
                  <Fragment>
                    <Form.Group as={Col} controlId={'name'}>
                      <Form.Label className={'inputLabel'}>
                        Business Name
                      </Form.Label>
                      <Form.Control
                        onChange={this.handleChange}
                        type={'text'}
                        maxLength={'30'}
                        value={this.state.name}
                      />
                    <Form.Text className={'inputLabel'}>Characters remaining: {30 - this.state.name.length}</Form.Text>
                    </Form.Group>
                    <Col></Col>
                  </Fragment> :
                  <Fragment>
                    <Form.Group as={Col} controlId={'name1'}>
                      <Form.Label className={'inputLabel'}>
                        Business Name Line 1
                      </Form.Label>
                      <Form.Control
                        onChange={this.handleChange}
                        type={'text'}
                        maxLength={'17'}
                        value={this.state.name1}
                      />
                    <Form.Text className={'inputLabel'}>Characters remaining: {17 - this.state.name1.length}</Form.Text>
                    </Form.Group>
                    <Form.Group as={Col} controlId={'name2'}>
                      <Form.Label className={'inputLabel'}>
                        Business Name Line 2 (optional)
                      </Form.Label>
                      <Form.Control
                        onChange={this.handleChange}
                        type={'text'}
                        maxLength={'17'}
                        value={this.state.name2}
                      />
                    <Form.Text className={'inputLabel'}>Characters remaining: {17 - this.state.name2.length}</Form.Text>
                    </Form.Group>
                  </Fragment>
                }
              </Form.Row>

              <Form.Row>
                <Form.Group as={Col} controlId={'date'}>
                  <Form.Label className={'inputLabel inputExtra'}>
                    Date
                  </Form.Label>
                  <DatePicker
                    selected={this.state.date}
                    onChange={this.handleDate}
                    placeholderText={'Select A Date'}
                  />
                </Form.Group>

                <Form.Group as={Col} controlId={'start'}>
                  <Form.Label className={'inputLabel inputExtra'}>
                    Start Time
                  </Form.Label>
                  <DatePicker
                    selected={this.state.startTime}
                    onChange={this.handleStartTime}
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={15}
                    dateFormat={'h:mm aa'}
                    timeCaption={'Time'}
                    placeholderText={'Select A Time'}
                  />
                </Form.Group>

                <Form.Group as={Col} controlId={'end'}>
                  <Form.Label className={'inputLabel inputExtra'}>
                    End Time
                  </Form.Label>
                  <DatePicker
                    selected={this.state.endTime}
                    onChange={this.handleEndTime}
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={15}
                    dateFormat={'h:mm aa'}
                    timeCaption={'Time'}
                    placeholderText={'Select A Time'}
                  />
                </Form.Group>
              </Form.Row>

              <Form.Row>
                <Form.Group as={Col} controlId={'recurring'}>
                  <Form.Label className={'inputLabel'}>
                    Recurring?
                  </Form.Label>
                  <Form.Control as={'select'} ref={ obj => this.recur = obj }>
                    <option>Once A Week</option>
                    <option>Mon - Fri</option>
                    <option>Mon - Thurs</option>
                    <option>Fri & Sat</option>
                    <option>Sat & Sun</option>
                    <option>Every Day</option>
                  </Form.Control>
                </Form.Group>

                <Form.Group as={Col} controlId={'weeks'}>
                  <Form.Label className={'inputLabel'}>
                    For # Of Weeks
                  </Form.Label>
                  <Form.Control as={'select'} ref={ obj => this.weeks = obj }>
                    {weeksallowed}
                  </Form.Control>
                  {
                    this.props.mainState.user.subLength === 'free' &&
                    <Form.Text className={'inputLabel'}>Subscribe to input past Aug 31, 2019</Form.Text>
                  }
                </Form.Group>

                <Form.Group as={Col} controlId={'type'} className={'inputLabel'}>
                  <Form.Label className={'inputExtra'}>
                    Category
                  </Form.Label>
                  <div>
                    {
                      CATEGORIES[this.props.createState.mainObject.which].map(type => {
                        return(
                          <Form.Check inline label={type[1]} type={'checkbox'} ref={ref => this[type[0]] = ref} id={type[0]} className={'inputExtra'}/>
                        )
                      })
                    }
                  </div>
                  <Form.Text className={'inputLabel'}>Select all that apply</Form.Text>
                </Form.Group>
              </Form.Row>

              {_layouts[this.props.createState.mainObject.which][this.props.createState.mainObject.layout]}

              <Form.Row>
                <Col></Col>
                <Col>
                  <Button
                    className={'inputSubmit'}
                    variant='outline-primary'
                    bssize="large"
                    type="submit"
                  >
                    Finish
                  </Button>
                </Col>
                <Col></Col>
              </Form.Row>
            </Form>
          </div>

          <div className={'preview'}>

            <h2 className={'previewTxt'}>{'Front'}</h2>

            <div className={'previewImg'}>
              <div className={'previewBackground'}>
                <img
                  className={'img-fluid'}
                  src={IMGS[this.props.createState.mainObject.which][this.props.createState.mainObject.back]}
                  alt={'Preview Front'}
                />
              </div>

              <div className={'previewForeground'}>
                {this.props.createState.mainObject.layout === 'single' && <SingleFront which={this.props.createState.mainObject.which} info={this.state} />}
                {this.props.createState.mainObject.layout === 'double' && <DoubleFront which={this.props.createState.mainObject.which} info={this.state} />}
                {this.props.createState.mainObject.layout === 'barcode' && <BarcodeFront which={this.props.createState.mainObject.which} info={this.state} />}
              </div>
            </div>

            <h2 className={'previewTxt'}>{'Back'}</h2>

            <div className={'previewImg'}>
              <div className={'previewBackground'}>
                <img
                  className={'img-fluid'}
                  src={IMGS[this.props.createState.mainObject.which][this.props.createState.mainObject.back]}
                  alt={'Preview Back'}
                />
              </div>

              <div className={'previewForeground'}>
                {this.props.createState.mainObject.layout === 'single' && <SingleBack which={this.props.createState.mainObject.which} info={this.state} />}
                {this.props.createState.mainObject.layout === 'double' && <DoubleBack which={this.props.createState.mainObject.which} info={this.state} />}
                {this.props.createState.mainObject.layout === 'barcode' && <BarcodeBack which={this.props.createState.mainObject.which} info={this.state} />}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  validateForm = async () => {
    var _valid = true;
    var check = false;

    for (var i = 0; i < CATEGORIES[this.props.createState.mainObject.which].length; i++) {
      var selected = ReactDOM.findDOMNode(this[CATEGORIES[this.props.createState.mainObject.which][i][0]]).checked;
      //console.log(selected);
      if (selected) {
        check = true;
      }
    }
    _valid = check;

    if (this.state.date === '') { _valid = false; }
    if (this.state.startTime === '') { _valid = false; }
    if (this.state.endTime === '') { _valid = false; }

    if (this.props.createState.mainObject.layout === 'barcode') {
      if (ReactDOM.findDOMNode(this.barcode).value === 'Select A File ...') { _valid = false; }
    }

    this.setState({
      valid: _valid
    });
  }

  handleDate = async (date) => {
    var _weeks = differenceInWeeks(this.state.enddate, date) + 1;
    //console.log(_weeks);
    var _month = (date.getMonth() + 1).toString();
    await this.setState({
      weeksout: _weeks,
      date: date,
      day: date.getDate().toString(),
      month: _month,
      year: date.getFullYear().toString()
    });
    this.validateForm();
  }

  handleStartTime = async (time) => {
    await this.setState({
      startTime: time,
      start: {
        hour: time.getHours(),
        min: time.getMinutes()
      }
    });
    this.buildHours();
    this.validateForm();
  }

  handleEndTime = async (time) => {
    await this.setState({
      endTime: time,
      end: {
        hour: time.getHours(),
        min: time.getMinutes()
      }
    });
    this.buildHours();
    this.validateForm();
  }

  buildHours = () => {
    var aa = this.state.start.hour > 11 ? 'p.m.' : 'a.m.';
    var bb = this.state.end.hour > 11 ? 'p.m.' : 'a.m.';

    var _start;
    var _end;
    var startHours = 0;
    var startMin = 0;
    var endHours = 0;
    var endMin = 0;

    if (this.state.start.hour === 0) {
      startHours = 12;
    } else if (this.state.start.hour > 12) {
      startHours = this.state.start.hour - 12;
    } else {
      startHours = this.state.start.hour;
    }
    startMin = ("00" + this.state.start.min).slice(-2);

    if (this.state.end.hour === 0) {
      endHours = 12;
    } else if (this.state.end.hour > 12) {
      endHours = this.state.end.hour - 12;
    } else {
      endHours = this.state.end.hour;
    }
    endMin = ("00" + this.state.end.min).slice(-2);

    if (startMin === '00') {
      _start = startHours;
    } else {
      _start = startHours + ':' + startMin;
    }

    if (endMin === '00') {
      _end = endHours;
    } else {
      _end = endHours + ':' + endMin;
    }

    var _hours = _start + ' ' + aa + ' - ' + _end + ' ' + bb;
    this.setState({
      hours: _hours
    });
  }

  handleChange = (event) => {
    this.setState({
      [event.target.id]: event.target.value,
      valid: this.validateForm()
    });
  }

  handleSubmit = async (event) => {
    event.preventDefault();
    if (this.validateForm()) {
      // Check for confirmation
      var conf = window.confirm('Please double check that all inputs\nare correct and select OK');
      if (conf) {
        // First, set the attributes independent of layout
        await this.props.createState.setAttribute('day', this.state.day);
        await this.props.createState.setAttribute('month', this.state.month);
        await this.props.createState.setAttribute('year', this.state.year);
        await this.props.createState.setAttribute('hours', this.state.hours);
        await this.props.createState.setAttribute('start', this.state.start);
        await this.props.createState.setAttribute('end', this.state.end);
        this.state.link === '' ?
        await this.props.createState.setAttribute('link', '0') :
        await this.props.createState.setAttribute('link', this.state.link);
        // Next, set attributes that depend on deal/event
        if (this.props.createState.mainObject.which === 'deal') {
          var _placeName = this.state.name1 + '\n' + this.state.name2;
          await this.props.createState.setAttribute('placeName', _placeName);
          await this.props.createState.setAttribute('title', this.state.title);
        } else {
          var _title = this.state.title1 + '\n' + this.state.title2;
          await this.props.createState.setAttribute('title', _title);
          await this.props.createState.setAttribute('placeName', this.state.name);
        }
        // Next, set attributes that depend on layout
        switch (this.props.createState.mainObject.layout) {
          case 'single': {
            var _details = this.state.detailsL1 + '\n' + this.state.detailsL2 + '\n' + this.state.detailsL3 + '\n' + this.state.detailsL4 + '\n' + this.state.detailsL5 + '\n' + this.state.detailsL6 + '\n' + this.state.detailsL7;
            await this.props.createState.setAttribute('h', this.state.h);
            await this.props.createState.setAttribute('details', _details);
            break;
          }
          case 'double': {
            var _details1 = this.state.details11 + '\n' + this.state.details12 + '\n' + this.state.details13;
            var _details2 = this.state.details21 + '\n' + this.state.details22 + '\n' + this.state.details23;
            await this.props.createState.setAttribute('h1', this.state.h1);
            await this.props.createState.setAttribute('h2', this.state.h2);
            await this.props.createState.setAttribute('details1', _details1);
            await this.props.createState.setAttribute('details2', _details2);
            break;
          }
          case 'barcode': {
            var _details = this.state.detailsL1 + '\n' + this.state.detailsL2 + '\n' + this.state.detailsL3;
            await this.props.createState.setAttribute('h', this.state.h);
            await this.props.createState.setAttribute('details', _details);
            await this.props.createState.setAttribute('url', this.state.url);
            var code = ReactDOM.findDOMNode(this.barcode).value;
            await this.props.createState.setAttribute('barcode', code);
            break;
          }
        }
        // Next, create and set the array of types
        var _type = [];
        for (let i = 0; i < CATEGORIES[this.props.createState.mainObject.which].length; i++) {

          var selected = ReactDOM.findDOMNode(this[CATEGORIES[this.props.createState.mainObject.which][i][0]]).checked;
          if (selected) {
            _type.push(CATEGORIES[this.props.createState.mainObject.which][i][0]);
          }
        }
        await this.props.createState.setAttribute('type', _type);
        // Lastly, set the value for recurring (setRecurring function will send the created item to backend)
        await this.props.createState.setAttribute('recurring', ReactDOM.findDOMNode(this.weeks).value);
        await this.props.createState.setAttribute('recur', ReactDOM.findDOMNode(this.recur).value);
        await this.props.createState.setRecurring('create');
        await this.props.mainState.resetSchedule();
        /// NEW FEATURE to account for multiple areas
        this.props.mainState.selectPage('home');
      } else {
        // Do nothing
      }
    } else {
      alert('Invalid Input. Please double check all fields.');
    }

  }
}

InputInfo.propTypes = {
  mainState: PropTypes.any.isRequired, // Mobx main global state
  createState: PropTypes.any.isRequired // Mobx create Item state
}

export default InputInfo;
