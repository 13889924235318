// Functions to validate input fields

// Patterns
const NAMES = /[^a-zA-Z]/;
const EMAIL = /[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+[.]+[a-zA-Z]{2,}/;
const UPPER = /[A-Z]/;
const LOWER = /[a-z]/;
const NUMBER = /[0-9]/;
const PHONE = /[^0-9]/;


// Error messages
const INVALID_NAME = 'Name can only include letters';
const INVALID_EMAIL = 'Please enter a valid email address';
const NO_UPPER = 'Password must contain one uppercase letter';
const NO_LOWER = 'Password must contain one lowercase letter';
const NO_NUMBER = 'Password must contain one number 0-9';
const NO_LENGTH = 'Password must be 8 characters long';
const NO_MATCH = 'Passwords must match';
const PHONE_CHARS = 'Phone number can only contain digits 0-9';
const PHONE_LEN = 'Phone number must be 10 digits long';
const ZIP_CHARS = 'Zip code can only contain digits 0-9';
const ZIP_LEN = 'Zip code must be 5 digits long';
const EMPTY = 'Please fill out all form fields';

export const validateName = (input) => {
  if (input === '') {
    return EMPTY;
  } else {
    if (input.match(NAMES)) {
      return INVALID_NAME;
    } else {
      return 1;
    }
  }
}

export const validateEmail = (email) => {
  if (email === '') {
    return EMPTY;
  } else {
    if (EMAIL.test(email)) {
      return 1;
    } else {
      return INVALID_EMAIL;
    }
  }
}

export const validatePassword = (password, confirm) => {
  if (password === '') {
    return EMPTY;
  } else {
    if (password !== confirm) {
      return NO_MATCH;
    } else {
      if (password.match(UPPER)) {
        if (password.match(LOWER)) {
          if (password.match(NUMBER)) {
            if (password.length >= 8) {
              return 1;
            } else {
              return NO_LENGTH;
            }
          } else {
            return NO_NUMBER;
          }
        } else {
          return NO_LOWER;
        }
      } else {
        return NO_UPPER;
      }
    }
  }
}

export const validatePhone = (phone) => {
  if (phone === '') {
    return EMPTY;
  } else {
    if (phone.match(PHONE)) {
      return PHONE_CHARS;
    } else {
      if (phone.length !== 10) {
        return PHONE_LEN;
      } else {
        return 1;
      }
    }
  }
}

export const validateZip = (zip) => {
  if (zip === '') {
    return EMPTY;
  } else {
    if (zip.match(PHONE)) {
      return ZIP_CHARS;
    } else {
      if (zip.length !== 5) {
        return ZIP_LEN;
      } else {
        return 1;
      }
    }
  }
}
