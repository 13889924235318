import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

import { fill } from '../../assets/globalStyles';

import './Create.css';

import SelectLayout from './SelectLayout/SelectLayout';
import SelectBack from './SelectBack/SelectBack';
import InputInfo from './InputInfo/InputInfo';
import EditInfo from './EditInfo/EditInfo';

// Constants

class Create extends Component {
  constructor(props) {
    super();
    this.state = {

    }
  }

  render() {

    const SCREENS = {
      layout: <SelectLayout createState={this.props.createState} playVideo={this.props.playVideo}/>,
      back: <SelectBack createState={this.props.createState}/>,
      info: <InputInfo createState={this.props.createState} mainState={this.props.mainState} loading={this.props.loading} playVideo={this.props.playVideo}/>,
      edit: <EditInfo createState={this.props.createState} mainState={this.props.mainState} loading={this.props.loading}/>
    }

    return (
      <div style={fill}>
        {SCREENS[this.props.createState.navScreen]}
      </div>
    );
  }

  displayInvalid = () => {
    toast.error('Invalid Access Code', {
      autoClose: 2000,
      className: 'invalid'
    });
  }
}

Create.propTypes = {
  mainState: PropTypes.any.isRequired, // Mobx main global state
  which: PropTypes.oneOf(['event', 'deal']).isRequired, // Which is being created
  location: PropTypes.any.isRequired, // Which user's location deal is for (number in array or 'all')
  loading: PropTypes.any.isRequired // Function to set state as loading
}

export default Create;
