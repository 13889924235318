import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';

import { fill } from '../../../assets/globalStyles';

import './EditInfo.css';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-datepicker/dist/react-datepicker-cssmodules.css';

import { DIMGS, EIMGS } from '../SelectBack/images';
import SingleFront from '../../../assets/components/SingleFront/SingleFront';
import SingleBack from '../../../assets/components/SingleBack/SingleBack';
import DoubleFront from '../../../assets/components/DoubleFront/DoubleFront';
import DoubleBack from '../../../assets/components/DoubleBack/DoubleBack';
import BarcodeFront from '../../../assets/components/BarcodeFront/BarcodeFront';
import BarcodeBack from '../../../assets/components/BarcodeBack/BarcodeBack';

const IMGS = {
  deal: DIMGS,
  event: EIMGS
}

// Constants

class InputInfo extends Component {
  constructor(props) {
    super(props);
    if (this.props.createState.mainObject.layout === 'double') {
      var tempDeets1 = this.props.createState.mainObject.details1.split("\n");
      var tempDeets2 = this.props.createState.mainObject.details2.split("\n");
      var extra1 = 3 - tempDeets1.length;
      var extra2 = 3 - tempDeets2.length;
      for (let i = 0; i < extra1; i++) {
        tempDeets1.push(' ');
      }
      for (let i = 0; i < extra2; i++) {
        tempDeets2.push(' ');
      }
      //console.log(tempDeets1);
      //console.log(tempDeets2);
    } else {
      var tempDeets = this.props.createState.mainObject.details.split("\n");
      if (this.props.createState.mainObject.layout === 'barcode') {
        var extra = 3 - tempDeets.length;
        for (let i = 0; i < extra; i++) {
          tempDeets.push(' ');
        }
      } else {
        var extra = 7 - tempDeets.length;
        for (let i = 0; i < extra; i++) {
          tempDeets.push(' ');
        }
      }
    }

    if (this.props.createState.mainObject.which === 'deal') {
      var tempName = this.props.createState.mainObject.placeName.split("\n");
    } else {
      var tempTitle = this.props.createState.mainObject.title.split("\n");
    }

    this.state = {
      name: this.props.createState.mainObject.which === 'deal' ? '' : this.props.createState.mainObject.placeName,
      name1: this.props.createState.mainObject.which === 'deal' ? tempName[0] : '',
      name2: this.props.createState.mainObject.which === 'deal' ? tempName[1] : '',
      hours: this.props.createState.mainObject.hours,
      title: this.props.createState.mainObject.which === 'deal' ? this.props.createState.mainObject.title : '',
      title1: this.props.createState.mainObject.which === 'deal' ? '' : tempTitle[0],
      title2: this.props.createState.mainObject.which === 'deal' ? '' : tempTitle[1],
      h: this.props.createState.mainObject.layout === 'double' ? '' : this.props.createState.mainObject.h,
      h1: this.props.createState.mainObject.layout === 'double' ? this.props.createState.mainObject.h1 : '',
      h2: this.props.createState.mainObject.layout === 'double' ? this.props.createState.mainObject.h2 : '',
      details: this.props.createState.mainObject.details,
      detailsL1: this.props.createState.mainObject.layout === 'double' ? '' : tempDeets[0],
      detailsL2: this.props.createState.mainObject.layout === 'double' ? '' : tempDeets[1],
      detailsL3: this.props.createState.mainObject.layout === 'double' ? '' : tempDeets[2],
      detailsL4:  this.props.createState.mainObject.layout === 'double' ? '' : this.props.createState.mainObject.layout === 'barcode' ? '' : tempDeets[3],
      detailsL5: this.props.createState.mainObject.layout === 'double' ? '' : this.props.createState.mainObject.layout === 'barcode' ? '' : tempDeets[4],
      detailsL6: this.props.createState.mainObject.layout === 'double' ? '' : this.props.createState.mainObject.layout === 'barcode' ? '' : tempDeets[5],
      detailsL7: this.props.createState.mainObject.layout === 'double' ? '' : this.props.createState.mainObject.layout === 'barcode' ? '' : tempDeets[6],
      details1: this.props.createState.mainObject.details1,
      details11: this.props.createState.mainObject.layout === 'double' ? tempDeets1[0] : '',
      details12: this.props.createState.mainObject.layout === 'double' ? tempDeets1[1] : '',
      details13: this.props.createState.mainObject.layout === 'double' ? tempDeets1[2] : '',
      details2: this.props.createState.mainObject.details2,
      details21: this.props.createState.mainObject.layout === 'double' ? tempDeets2[0] : '',
      details22: this.props.createState.mainObject.layout === 'double' ? tempDeets2[1] : '',
      details23: this.props.createState.mainObject.layout === 'double' ? tempDeets2[2] : '',
      link: this.props.createState.mainObject.link,
      changes: []
    }
  }

  render() {

    var _layouts = {
      event: {
        single:
          <Fragment>
            <Form.Group as={Col} controlId={'h'}>
              <Form.Label className={'signupLabel'}>
                Heading
              </Form.Label>
              <Form.Control
                onChange={this.handleChange}
                type={'text'}
                maxLength={'30'}
                value={this.state.h}
              />
            <Form.Text className={'signupLabel'}>Characters remaining: {30 - this.state.h.length}</Form.Text>
            </Form.Group>

            <Form.Group controlId={'detailsL1'}>
              <Form.Label className={'signupLabel'}>
                Details Line 1
              </Form.Label>
              <Form.Control
                onChange={this.handleChange}
                type={'text'}
                maxLength={'36'}
                value={this.state.detailsL1}
              />
            <Form.Text className={'signupLabel'}>Leave blank for empty line. Characters remaining: {36 - this.state.detailsL1.length}</Form.Text>
            </Form.Group>
            <Form.Group controlId={'detailsL2'}>
              <Form.Label className={'signupLabel'}>
                Details Line 2
              </Form.Label>
              <Form.Control
                onChange={this.handleChange}
                type={'text'}
                maxLength={'36'}
                value={this.state.detailsL2}
              />
            <Form.Text className={'signupLabel'}>Leave blank for empty line. Characters remaining: {36 - this.state.detailsL2.length}</Form.Text>
            </Form.Group>
            <Form.Group controlId={'detailsL3'}>
              <Form.Label className={'signupLabel'}>
                Details Line 3
              </Form.Label>
              <Form.Control
                onChange={this.handleChange}
                type={'text'}
                maxLength={'36'}
                value={this.state.detailsL3}
              />
            <Form.Text className={'signupLabel'}>Leave blank for empty line. Characters remaining: {36 - this.state.detailsL3.length}</Form.Text>
            </Form.Group>
            <Form.Group controlId={'detailsL4'}>
              <Form.Label className={'signupLabel'}>
                Details Line 4
              </Form.Label>
              <Form.Control
                onChange={this.handleChange}
                type={'text'}
                maxLength={'36'}
                value={this.state.detailsL4}
              />
            <Form.Text className={'signupLabel'}>Leave blank for empty line. Characters remaining: {36 - this.state.detailsL4.length}</Form.Text>
            </Form.Group>
            <Form.Group controlId={'detailsL5'}>
              <Form.Label className={'signupLabel'}>
                Details Line 5
              </Form.Label>
              <Form.Control
                onChange={this.handleChange}
                type={'text'}
                maxLength={'36'}
                value={this.state.detailsL5}
              />
            <Form.Text className={'signupLabel'}>Leave blank for empty line. Characters remaining: {36 - this.state.detailsL5.length}</Form.Text>
            </Form.Group>
            <Form.Group controlId={'detailsL6'}>
              <Form.Label className={'signupLabel'}>
                Details Line 6
              </Form.Label>
              <Form.Control
                onChange={this.handleChange}
                type={'text'}
                maxLength={'36'}
                value={this.state.detailsL6}
              />
            <Form.Text className={'signupLabel'}>Leave blank for empty line. Characters remaining: {36 - this.state.detailsL6.length}</Form.Text>
            </Form.Group>
            <Form.Group controlId={'detailsL7'}>
              <Form.Label className={'signupLabel'}>
                Details Line 7
              </Form.Label>
              <Form.Control
                onChange={this.handleChange}
                type={'text'}
                maxLength={'36'}
                value={this.state.detailsL7}
              />
            <Form.Text className={'signupLabel'}>Leave blank for empty line. Characters remaining: {36 - this.state.detailsL7.length}</Form.Text>
            </Form.Group>

            <Form.Group controlId={'link'}>
              <Form.Label className={'signupLabel'}>
                Link (optional)
              </Form.Label>
              <Form.Control
                onChange={this.handleChange}
                type={'text'}
              />
            <Form.Text className={'signupLabel'}>Please use full web address including 'http://' or 'https://'</Form.Text>
            </Form.Group>
          </Fragment>,
        barcode:
          <Fragment>
            <Form.Group as={Col} controlId={'h'}>
              <Form.Label className={'signupLabel'}>
                Heading
              </Form.Label>
              <Form.Control
                onChange={this.handleChange}
                type={'text'}
                maxLength={'30'}
                value={this.state.h}
              />
            <Form.Text className={'signupLabel'}>Characters remaining: {30 - this.state.h.length}</Form.Text>
            </Form.Group>

            <Form.Group controlId={'detailsL1'}>
              <Form.Label className={'signupLabel'}>
                Details Line 1
              </Form.Label>
              <Form.Control
                onChange={this.handleChange}
                type={'text'}
                maxLength={'36'}
                value={this.state.detailsL1}
              />
            <Form.Text className={'signupLabel'}>Leave blank for empty line. Characters remaining: {36 - this.state.detailsL1.length}</Form.Text>
            </Form.Group>
            <Form.Group controlId={'detailsL2'}>
              <Form.Label className={'signupLabel'}>
                Details Line 2
              </Form.Label>
              <Form.Control
                onChange={this.handleChange}
                type={'text'}
                maxLength={'36'}
                value={this.state.detailsL2}
              />
            <Form.Text className={'signupLabel'}>Leave blank for empty line. Characters remaining: {36 - this.state.detailsL2.length}</Form.Text>
            </Form.Group>
            <Form.Group controlId={'detailsL3'}>
              <Form.Label className={'signupLabel'}>
                Details Line 3
              </Form.Label>
              <Form.Control
                onChange={this.handleChange}
                type={'text'}
                maxLength={'36'}
                value={this.state.detailsL3}
              />
            <Form.Text className={'signupLabel'}>Leave blank for empty line. Characters remaining: {36 - this.state.detailsL3.length}</Form.Text>
            </Form.Group>

            <Form.Group controlId={'link'}>
              <Form.Label className={'signupLabel'}>
                Link (optional)
              </Form.Label>
              <Form.Control
                onChange={this.handleChange}
                type={'text'}
                value={this.state.link}
              />
            <Form.Text className={'signupLabel'}>Please use full web address including 'http://' or 'https://'</Form.Text>
            </Form.Group>
          </Fragment>
      },
      deal: {
        single:
          <Fragment>
            <Form.Group as={Col} controlId={'h'}>
              <Form.Label className={'signupLabel'}>
                Heading
              </Form.Label>
              <Form.Control
                onChange={this.handleChange}
                type={'text'}
                maxLength={'30'}
                value={this.state.h}
              />
            <Form.Text className={'signupLabel'}>Characters remaining: {30 - this.state.h.length}</Form.Text>
            </Form.Group>

            <Form.Group controlId={'detailsL1'}>
              <Form.Label className={'signupLabel'}>
                Details Line 1
              </Form.Label>
              <Form.Control
                onChange={this.handleChange}
                type={'text'}
                maxLength={'36'}
                value={this.state.detailsL1}
              />
            <Form.Text className={'signupLabel'}>Leave blank for empty line. Characters remaining: {36 - this.state.detailsL1.length}</Form.Text>
            </Form.Group>
            <Form.Group controlId={'detailsL2'}>
              <Form.Label className={'signupLabel'}>
                Details Line 2
              </Form.Label>
              <Form.Control
                onChange={this.handleChange}
                type={'text'}
                maxLength={'36'}
                value={this.state.detailsL2}
              />
            <Form.Text className={'signupLabel'}>Leave blank for empty line. Characters remaining: {36 - this.state.detailsL2.length}</Form.Text>
            </Form.Group>
            <Form.Group controlId={'detailsL3'}>
              <Form.Label className={'signupLabel'}>
                Details Line 3
              </Form.Label>
              <Form.Control
                onChange={this.handleChange}
                type={'text'}
                maxLength={'36'}
                value={this.state.detailsL3}
              />
            <Form.Text className={'signupLabel'}>Leave blank for empty line. Characters remaining: {36 - this.state.detailsL3.length}</Form.Text>
            </Form.Group>
            <Form.Group controlId={'detailsL4'}>
              <Form.Label className={'signupLabel'}>
                Details Line 4
              </Form.Label>
              <Form.Control
                onChange={this.handleChange}
                type={'text'}
                maxLength={'36'}
                value={this.state.detailsL4}
              />
            <Form.Text className={'signupLabel'}>Leave blank for empty line. Characters remaining: {36 - this.state.detailsL4.length}</Form.Text>
            </Form.Group>
            <Form.Group controlId={'detailsL5'}>
              <Form.Label className={'signupLabel'}>
                Details Line 5
              </Form.Label>
              <Form.Control
                onChange={this.handleChange}
                type={'text'}
                maxLength={'36'}
                value={this.state.detailsL5}
              />
            <Form.Text className={'signupLabel'}>Leave blank for empty line. Characters remaining: {36 - this.state.detailsL5.length}</Form.Text>
            </Form.Group>
            <Form.Group controlId={'detailsL6'}>
              <Form.Label className={'signupLabel'}>
                Details Line 6
              </Form.Label>
              <Form.Control
                onChange={this.handleChange}
                type={'text'}
                maxLength={'36'}
                value={this.state.detailsL6}
              />
            <Form.Text className={'signupLabel'}>Leave blank for empty line. Characters remaining: {36 - this.state.detailsL6.length}</Form.Text>
            </Form.Group>
            <Form.Group controlId={'detailsL7'}>
              <Form.Label className={'signupLabel'}>
                Details Line 7
              </Form.Label>
              <Form.Control
                onChange={this.handleChange}
                type={'text'}
                maxLength={'36'}
                value={this.state.detailsL7}
              />
            <Form.Text className={'signupLabel'}>Leave blank for empty line. Characters remaining: {36 - this.state.detailsL7.length}</Form.Text>
            </Form.Group>
          </Fragment>,
        double:
          <Fragment>
            <Form.Row>
              <Form.Group as={Col} controlId={'h1'}>
                <Form.Label className={'signupLabel'}>
                  Heading 1
                </Form.Label>
                <Form.Control
                  onChange={this.handleChange}
                  type={'text'}
                  maxLength={'30'}
                  value={this.state.h1}
                />
              <Form.Text className={'signupLabel'}>Characters remaining: {30 - this.state.h1.length}</Form.Text>
              </Form.Group>

              <Form.Group as={Col} controlId={'h2'}>
                <Form.Label className={'signupLabel'}>
                  Heading 2
                </Form.Label>
                <Form.Control
                  onChange={this.handleChange}
                  type={'text'}
                  maxLength={'30'}
                  value={this.state.h2}
                />
              <Form.Text className={'signupLabel'}>Characters remaining: {30 - this.state.h2.length}</Form.Text>
              </Form.Group>
            </Form.Row>

            <Form.Group controlId={'details11'}>
              <Form.Label className={'signupLabel'}>
                Details 1 Line 1
              </Form.Label>
              <Form.Control
                onChange={this.handleChange}
                type={'text'}
                maxLength={'36'}
                value={this.state.details11}
              />
            <Form.Text className={'signupLabel'}>Leave blank for empty line. Characters remaining: {36 - this.state.details11.length}</Form.Text>
            </Form.Group>
            <Form.Group controlId={'details12'}>
              <Form.Label className={'signupLabel'}>
                Details 1 Line 2
              </Form.Label>
              <Form.Control
                onChange={this.handleChange}
                type={'text'}
                maxLength={'36'}
                value={this.state.details12}
              />
            <Form.Text className={'signupLabel'}>Leave blank for empty line. Characters remaining: {36 - this.state.details12.length}</Form.Text>
            </Form.Group>
            <Form.Group controlId={'details13'}>
              <Form.Label className={'signupLabel'}>
                Details 1 Line 3
              </Form.Label>
              <Form.Control
                onChange={this.handleChange}
                type={'text'}
                maxLength={'36'}
                value={this.state.details13}
              />
            <Form.Text className={'signupLabel'}>Leave blank for empty line. Characters remaining: {36 - this.state.details13.length}</Form.Text>
            </Form.Group>

            <Form.Group controlId={'details21'}>
              <Form.Label className={'signupLabel'}>
                Details 2 Line 1
              </Form.Label>
              <Form.Control
                onChange={this.handleChange}
                type={'text'}
                maxLength={'36'}
                value={this.state.details21}
              />
            <Form.Text className={'signupLabel'}>Leave blank for empty line. Characters remaining: {36 - this.state.details21.length}</Form.Text>
            </Form.Group>
            <Form.Group controlId={'details22'}>
              <Form.Label className={'signupLabel'}>
                Details 2 Line 2
              </Form.Label>
              <Form.Control
                onChange={this.handleChange}
                type={'text'}
                maxLength={'36'}
                value={this.state.details22}
              />
            <Form.Text className={'signupLabel'}>Leave blank for empty line. Characters remaining: {36 - this.state.details22.length}</Form.Text>
            </Form.Group>
            <Form.Group controlId={'details23'}>
              <Form.Label className={'signupLabel'}>
                Details 2 Line 3
              </Form.Label>
              <Form.Control
                onChange={this.handleChange}
                type={'text'}
                maxLength={'36'}
                value={this.state.details23}
              />
            <Form.Text className={'signupLabel'}>Leave blank for empty line. Characters remaining: {36 - this.state.details23.length}</Form.Text>
            </Form.Group>
          </Fragment>,
        barcode:
          <Fragment>
            <Form.Group as={Col} controlId={'h'}>
              <Form.Label className={'signupLabel'}>
                Heading
              </Form.Label>
              <Form.Control
                onChange={this.handleChange}
                type={'text'}
                maxLength={'30'}
                value={this.state.h}
              />
            <Form.Text className={'signupLabel'}>Characters remaining: {30 - this.state.h.length}</Form.Text>
            </Form.Group>

            <Form.Group controlId={'detailsL1'}>
              <Form.Label className={'signupLabel'}>
                Details Line 1
              </Form.Label>
              <Form.Control
                onChange={this.handleChange}
                type={'text'}
                maxLength={'36'}
                value={this.state.detailsL1}
              />
            <Form.Text className={'signupLabel'}>Leave blank for empty line. Characters remaining: {36 - this.state.detailsL1.length}</Form.Text>
            </Form.Group>
            <Form.Group controlId={'detailsL2'}>
              <Form.Label className={'signupLabel'}>
                Details Line 2
              </Form.Label>
              <Form.Control
                onChange={this.handleChange}
                type={'text'}
                maxLength={'36'}
                value={this.state.detailsL2}
              />
            <Form.Text className={'signupLabel'}>Leave blank for empty line. Characters remaining: {36 - this.state.detailsL2.length}</Form.Text>
            </Form.Group>
            <Form.Group controlId={'detailsL3'}>
              <Form.Label className={'signupLabel'}>
                Details Line 3
              </Form.Label>
              <Form.Control
                onChange={this.handleChange}
                type={'text'}
                maxLength={'36'}
                value={this.state.detailsL3}
              />
            <Form.Text className={'signupLabel'}>Leave blank for empty line. Characters remaining: {36 - this.state.detailsL3.length}</Form.Text>
            </Form.Group>
          </Fragment>
      }
    }

    return (
      <div style={fill}>
        <h3 className={'previewTxt inputHeader'}>
          {'*Previews are close but not exact. For most accurate preview, resize browser window so that the front preview fills the white box*'}
        </h3>
        <h3 className={'previewTxt inputHeader'}>
          {'Not all fields are available to edit. If you need to edit something that is not shown below, the ' + this.props.createState.mainObject.which + ' will need to be deleted and re-created.'}
        </h3>
        <br/>

        <div className={'inputContainer'}>
          <div className={'inputForm'}>
            <Form onSubmit={e => this.handleSubmit(e)}>
              <Form.Row>
                {
                  this.props.createState.mainObject.which === 'event' ?
                  <Fragment>
                    <Form.Group as={Col} controlId={'name'}>
                      <Form.Label className={'signupLabel'}>
                        Business Name
                      </Form.Label>
                      <Form.Control
                        onChange={this.handleChange}
                        type={'text'}
                        maxLength={'30'}
                        value={this.state.name}
                      />
                    <Form.Text className={'signupLabel'}>Characters remaining: {30 - this.state.name.length}</Form.Text>
                    </Form.Group>
                    <Col></Col>
                  </Fragment> :
                  <Fragment>
                    <Form.Group as={Col} controlId={'name1'}>
                      <Form.Label className={'signupLabel'}>
                        Business Name Line 1
                      </Form.Label>
                      <Form.Control
                        onChange={this.handleChange}
                        type={'text'}
                        maxLength={'17'}
                        value={this.state.name1}
                      />
                    <Form.Text className={'signupLabel'}>Characters remaining: {17 - this.state.name1.length}</Form.Text>
                    </Form.Group>
                    <Form.Group as={Col} controlId={'name2'}>
                      <Form.Label className={'signupLabel'}>
                        Business Name Line 2 (optional)
                      </Form.Label>
                      <Form.Control
                        onChange={this.handleChange}
                        type={'text'}
                        maxLength={'17'}
                        value={this.state.name2}
                      />
                    <Form.Text className={'signupLabel'}>Characters remaining: {17 - this.state.name2.length}</Form.Text>
                    </Form.Group>
                  </Fragment>
                }
              </Form.Row>

              {_layouts[this.props.createState.mainObject.which][this.props.createState.mainObject.layout]}

              <Form.Row>
                <Col></Col>
                <Col>
                  <Button
                    className={'inputSubmit'}
                    variant='outline-primary'
                    bssize="large"
                    type="submit"
                  >
                    Finish
                  </Button>
                </Col>
                <Col></Col>
              </Form.Row>
            </Form>
          </div>

          <div className={'preview'}>

            <h2 className={'previewTxt'}>{'Front'}</h2>

            <div className={'previewImg'}>
              <div className={'previewBackground'}>
                <img
                  className={'img-fluid'}
                  src={IMGS[this.props.createState.mainObject.which][this.props.createState.mainObject.back]}
                  alt={'Preview Front'}
                />
              </div>

              <div className={'previewForeground'}>
                {this.props.createState.mainObject.layout === 'single' && <SingleFront which={this.props.createState.mainObject.which} info={this.state} />}
                {this.props.createState.mainObject.layout === 'double' && <DoubleFront which={this.props.createState.mainObject.which} info={this.state} />}
                {this.props.createState.mainObject.layout === 'barcode' && <BarcodeFront which={this.props.createState.mainObject.which} info={this.state} />}
              </div>
            </div>

            <h2 className={'previewTxt'}>{'Back'}</h2>

            <div className={'previewImg'}>
              <div className={'previewBackground'}>
                <img
                  className={'img-fluid'}
                  src={IMGS[this.props.createState.mainObject.which][this.props.createState.mainObject.back]}
                  alt={'Preview Back'}
                />
              </div>

              <div className={'previewForeground'}>
                {this.props.createState.mainObject.layout === 'single' && <SingleBack which={this.props.createState.mainObject.which} info={this.state} />}
                {this.props.createState.mainObject.layout === 'double' && <DoubleBack which={this.props.createState.mainObject.which} info={this.state} />}
                {this.props.createState.mainObject.layout === 'barcode' && <BarcodeBack which={this.props.createState.mainObject.which} info={this.state} />}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  handleChange = (event) => {
    var change = '';
    switch(event.target.id) {
      case 'detailsL1': {
        change = 'details';
        break;
      }
      case 'detailsL2': {
        change = 'details';
        break;
      }
      case 'detailsL3': {
        change = 'details';
        break;
      }
      case 'detailsL4': {
        change = 'details';
        break;
      }
      case 'detailsL5': {
        change = 'details';
        break;
      }
      case 'detailsL6': {
        change = 'details';
        break;
      }
      case 'detailsL7': {
        change = 'details';
        break;
      }
      case 'details11': {
        change = 'details1';
        break;
      }
      case 'details12': {
        change = 'details1';
        break;
      }
      case 'details13': {
        change = 'details1';
        break;
      }
      case 'details21': {
        change = 'details2';
        break;
      }
      case 'details22': {
        change = 'details2';
        break;
      }
      case 'details23': {
        change = 'details2';
        break;
      }
      case 'name': {
        change = 'placeName';
        break;
      }
      case 'name1': {
        change = 'placeName';
        break;
      }
      case 'name2': {
        change = 'placeName';
        break;
      }
      default: {
        change = event.target.id;
      }
    }
    var changed = false;
    var _changes = this.state.changes;
    for (var i = 0; i < this.state.changes.length; i++) {
      if (this.state.changes[i] === change) {
        changed = true;
      }
    }
    if (!changed) {
      _changes.push(change);
    }
    //console.log(_changes);

    this.setState({
      [event.target.id]: event.target.value,
      changes: _changes
    });
  }

  handleSubmit = async (event) => {
    event.preventDefault();
    // Check for confirmation
    if (this.state.changes.length > 0) {
      var conf = window.confirm('Please double check that all inputs\nare correct and select OK');
      if (conf) {
        var edits = [];
        for (let i = 0; i < this.state.changes.length; i++) {
          switch(this.state.changes[i]) {
            case 'details': {
              var _details = this.state.detailsL1 + '\n' + this.state.detailsL2 + '\n' + this.state.detailsL3 + '\n' + this.state.detailsL4 + '\n' + this.state.detailsL5 + '\n' + this.state.detailsL6 + '\n' + this.state.detailsL7;
              var temp = {
                item: this.state.changes[i],
                value: _details
              }
              break;
            }
            case 'details1': {
              var _details1 = this.state.details11 + '\n' + this.state.details12 + '\n' + this.state.details13;
              var temp = {
                item: this.state.changes[i],
                value: _details1
              }
              break;
            }
            case 'details2': {
              var _details2 = this.state.details21 + '\n' + this.state.details22 + '\n' + this.state.details23;
              var temp = {
                item: this.state.changes[i],
                value: _details2
              }
              break;
            }
            case 'placeName': {
              var _name = '';
              if (this.props.createState.mainObject.which === 'deal') {
                _name = this.state.name1 + '\n' + this.state.name2;
              } else {
                _name = this.state.name;
              }
              var temp = {
                item: this.state.changes[i],
                value: _name
              }
              break;
            }
            default: {
              var temp = {
                item: this.state.changes[i],
                value: this.state[this.state.changes[i]]
              }
            }
          }
          edits.push(temp);
        }
        //console.log(edits);
        this.props.createState.setEdits(edits);

        if (this.props.createState._edit === 'one') {
          await this.props.createState.editItem(0);
          await this.props.mainState.resetSchedule();
          this.props.mainState.selectPage('home');
        } else {
          await this.props.createState.setRecurring('edit');
          await this.props.mainState.resetSchedule();
          this.props.mainState.selectPage('home');
        }
      } else {
        // Do nothing if user cnacels confirmation
      }
    } else {
      // If user has not changed anything
      alert('You have not made any changes.');
    }
  }
}

InputInfo.propTypes = {
  mainState: PropTypes.any.isRequired, // Mobx main global state
  createState: PropTypes.any.isRequired, // Mobx create Item state
  which: PropTypes.oneOf(['event', 'deal']).isRequired, // Which is being created
  location: PropTypes.any.isRequired // Which user's location deal is for (number in array or 'all')
}

export default InputInfo;
